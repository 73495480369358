import { createApp } from 'vue';
import router from './config/router.js';
import store from './config/store.js';
import i18n from './config/i18n';
// Ensure you import VueQriously if needed
import VueQriously from 'vue-qriously';

// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css'; // Import CSS
import App from './App.vue';
import Api from './config/api';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import './assets/js/util.js';
import tool from './utils/equipment';

// Replacing vue-resource with axios for HTTP requests
import axiosAdapted from './axios-adapted';
// Replacing Event Bus with mitt
import mitt from 'mitt';

// Replace ViewUI with Element Plus or another Vue 3 compatible UI library
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/scss/app.scss'; // Custom styles

// Vant for mobile components
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';

import resize from './assets/js/resize'; // Resize directive
import ViewUIPlus from "view-ui-plus";
import 'view-ui-plus/dist/styles/viewuiplus.css'
import microApp from '@micro-zoe/micro-app'; // Micro front-end
microApp.start();

const app = createApp(App);


// Use mitt as an Event Bus replacement
const emitter = mitt();
app.config.globalProperties.$bus = emitter;
// Add plugins
app.use(router);
app.use(i18n);
app.use(store);
app.use(ElementPlus);
app.use(Vant);
app.use(VueQriously);
app.use(Lazyload, {
  lazyComponent: true,
});

// Global directives
app.directive('resize', resize);

// Global properties
app.config.globalProperties.rootHost = process.env.VUE_APP_ROOT_URL;
app.config.globalProperties.host = Api.host;
app.config.globalProperties.api = Api;
app.config.globalProperties.$host = process.env.VUE_APP_BASE_URL;
// Use axios instead of vue-resource
app.config.globalProperties.$http = axiosAdapted;
app.config.globalProperties.$http.host = process.env.VUE_APP_BASE_URL;
app.config.globalProperties.$http.api = Api;

// Global Geetest function
app.config.globalProperties.$initGeetest4 = () => {
  return new Promise((res, rej) => {
    let captchaW = document.querySelector('.captchaW');
    captchaW.style.display = 'flex';
    initGeetest4(
      {
        captchaId: 'e61730fa5d35dee4459f56a625c17c48',
      },
      function (captchaObj) {
        let captchaDom = document.getElementById('captchaDom');
        captchaDom.innerHTML = '';
        captchaObj.appendTo(captchaDom);
        captchaObj.onSuccess(function () {
          let result = captchaObj.getValidate();
          res(result);
          setTimeout(() => {
            captchaW.style.display = 'none';
          }, 500);
        });
      },
    );
  });
};

// Locale and timezone utilities
app.config.globalProperties.getTimezone4K = function () {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

app.config.globalProperties.getLang4K = function () {
  var curlang = this.$store.getters.lang;
  if (curlang == 'en_US') {
    return 'en';
  }
  // Add more language mappings as needed
  return curlang;
};

// Moment.js filters
app.config.globalProperties.timeFormat = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('HH:mm:ss');
};

app.config.globalProperties.dateFormat = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('YYYY-MM-DD HH:mm:ss');
};

// More moment-based filters or utilities as needed

// Rem-based responsive layout
function setRem() {
  const html = document.documentElement;
  const width = html.clientWidth;
  html.style.fontSize = width / 10 + 'px';
}

// Call setRem on page load and resize events
setRem();
window.addEventListener('resize', setRem);

app.use(ViewUIPlus);

// Vue.use(ViewUI);
// Mount the Vue app
app.mount('#app_W');
