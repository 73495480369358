const map = new WeakMap();

const ob = new ResizeObserver((entires) => {
  for (const entire of entires) {
    const handler = map.get(entire.target);

    handler && handler(entire);
  }
});

const resize = {
  bind(el, binding) {
    ob.observe(el);
    map.set(el, binding.value);
  },
  unbind(el) {
    ob.unobserve(el);
  },
};

export default resize;
