// axiosWrapper.js
import axios from 'axios';

export function jsonToFormData(jsonObject) {
    const formBody = [];

    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(jsonObject[key]);
            formBody.push(`${encodedKey}=${encodedValue}`);
        }
    }

    return formBody.join('&');
}

const axiosInstance = axios.create({
    // You can configure default settings here if needed, like baseURL, headers, etc.
});

axiosInstance.interceptors.request.use(req => {
    // Check if request data exists and it's JSON, then convert it to form-data
    if (req.data && typeof req.data === 'object' && req.headers['Content-Type'] === 'application/json') {
        req.data = jsonToFormData(req.data); // Convert JSON data to x-www-form-urlencoded
    }

    // Set headers to indicate the correct content type
    if  (localStorage.getItem('TOKEN')) req.headers['Access-Auth-Token'] =  localStorage.getItem('TOKEN');
    req.headers = {
        ...req.headers,
        'Content-Type': 'application/x-www-form-urlencoded',
        'uzx-device': 'Chrome',
        'uzx-device-version': 'GnnQMQv7hgJzmSXD',
        'uzx-os': 'MacOS',
        'uzx-os-version': '10.15.7'
    };

    return req; // Ensure the request is returned
});

axiosInstance.interceptors.response.use(
    response => {
        // Modify the response to include 'body' wrapping the data
        console.log(response.data);
        const customResponse = {
            ...response,
            body: response.data,
        };
        return customResponse; // Now response.body.data will give you the data
    },
    error => {
        return Promise.reject(error); // Forward the error as is
    }
);

export default axiosInstance;
