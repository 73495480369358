import { createI18n } from 'vue-i18n';
import { enUS, zhCN, zhTW, jaJP, koKR, deDE, frFR, itIT, esES, ruRU, hiIN } from 'view-ui-plus/dist/styles/viewuiplus.css';

import en from '../assets/lang/en.js';
import cn from '../assets/lang/cn.js';
import hk from '../assets/lang/hk.js';
import jp from '../assets/lang/jp.js';
import ko from '../assets/lang/ko.js';
import de from '../assets/lang/de.js';
import fr from '../assets/lang/fr.js';
import it from '../assets/lang/it.js';
import es from '../assets/lang/es.js';
import ru from '../assets/lang/ru.js';
import hi from '../assets/lang/hi.js';

const i18n = createI18n({
  locale: 'en_US', // set default locale
  messages: {
    en_US: { ...enUS, ...en },
    zh_CN: { ...zhCN, ...cn },
    zh_HK: { ...zhTW, ...hk },
    ja_JP: { ...jaJP, ...jp },
    ko_KR: { ...koKR, ...ko },
    de_DE: { ...deDE, ...de },
    fr_FR: { ...frFR, ...fr },
    it_IT: { ...itIT, ...it },
    es_ES: { ...esES, ...es },
    ru_RU: { ...ruRU, ...ru },
    hi_IN: { ...hiIN, ...hi },
  },
  silentTranslationWarn: true,
});

export default i18n;
