module.exports = {
  common: {
    sloganthree: 'NTL में स्वागत है!',
    slogantwo: 'Digital currency exchange',
    currencySelection: 'टेबल',
    chart: 'चार्ट',
    details: 'विवरण',
    entrust: 'प्रतिनिधि',
    login: 'लॉग इन करें',
    register: 'रजिस्टर करें',
    loginregister: 'लॉग इन रजिस्टर',
    logout: 'साइन आउट',
    tip: 'टिप्स',
    indexplaceholde: 'ईमेल',
    signupnow: 'रेजिस्टर',
    logintip: 'पहले प्रवेश करें',
    expect: 'बने रहें!',
    delete: 'हटाने की पुष्टि करें?',
    nodata: 'कोई रिकॉर्ड नहीं',
    set: 'सेट अप',
    update: 'संशोधन',
    slogan: 'दुनिया के शीर्ष 50 डिजिटल मुद्रा एक्सचेंज',
    subslogan: 'राज्य संस्थानों द्वारा गारंटीकृत संपत्ति की सुरक्षा',
    more: 'अधिक',
    please: 'कृपया पहले',
    pleaseselect: 'कृपया चुनें',
    searchplaceholder: 'खोज करने के लिए मुद्रा का नाम दर्ज करें',
    searchplaceholderswap: 'खोज करने के लिए अनुबंध का नाम दर्ज करें',
    loading: 'लोड हो रहा है...',
    close: 'बंद',
    ok: 'ज़रूर',
    fillComplete: 'कृपया पूरी जानकारी भरें',
    inputpleaseselect: 'कृपया प्रविष्ट करें',
    showDetail: 'विवरण देखें',
  },
  header: {
    index: 'मुखपृष्ठ',
    exchange: 'सिक्का मुद्रा लेनदेन',
    trade: 'सव्यापार',
    otc: 'फिएट करेंसी ओटीसी',
    kefu: 'सहायता केंद्र',
    asset: 'वित्तीय केन्द्र',
    ucenter: 'खाता केंद्र',
    service: 'मुनादी करना',
    downloadapp: 'डाउनलोड और इंस्टॉल करने के लिए कोड को स्कैन करें',
    appdownlaod: 'एपीपी डाउनलोड',
    usercenter: 'व्यक्तिगत केंद्र',
    helpcenter: 'सहायता केंद्र',
    aboutus: 'के बारे में',
    partner: 'साथी',
    bzb: 'बीजेडबी',
    assetmanage: 'निधि प्रबंधन',
    trademanage: 'लेन - देन प्रबंधन',
    activity: 'नवीनतम घटनाओं',
    lab: 'इनोवेशन लैब',
    ctc: 'फिएट मुद्रा C2C',
    labdetail: 'EPBTC लैब नवाचार उपलब्धियों को साझा करना',
    invite: 'प्रमोशन पार्टनर',
    whitepaper: 'सफेद कागज',
    scandownload: 'एपीपी डाउनलोड करने के लिए स्कैन करें',
    innovationmanage: 'नवाचार प्रबंधन',
    swap1: 'निरंतर अनुबंध',
    swap2: 'सदा अनुबंध (मिलान)',
    swap3: 'अंतर के लिए अनुबंध',
    swap4: 'दूसरा अनुबंध',
    cexchange: 'त्वरित विनिमय',
    option: 'विकल्प अनुबंध',
    second: 'दूसरा अनुबंध',
    news: 'समाचार',
    swapExchange: 'अनुबंध लेनदेन',
    finance: 'अनुबंध लेनदेन',
    fiatCurrency: 'क्रिप्टो खरीदें',
  },
  sectionPage: {
    showMore: 'और देखें',
    brandDesc6:
      'NTL के पास वास्तविक समय ब्लॉकचेन आसेट पहुँच फंक्शन है, बहुत मुद्रा पता साझेदारी तकनीकी, बहुत सुरक्षा परीक्षा सुरक्षा और कम ट्रांसेक्शन',
    brandDesc5:
      'NTL का तकनीक समाधान गूगल ब्लूड और अमेजान एवास पर आधारित है, जो डीडोस हल्कों को प्रभावित रूप से रोक रहा है और डायनिमिक और स्थिर डाटा के  यह हॉट डाटा कैशिंग, रिमोट विपत्ति प्राप्त करने के लिए समर्थन करता है. दुनिया के प्रधान तकनिक आर्किटेक्टेक्टेक्टेक्',
    brandDesc4:
      'NTL में वर्तमान में एक-स्टॉप्ट क्रिप्टो आसेट सेवा है जैसे मुद्रा, सदैव कंट्रांट, स्पॉट, विकल्प, आर्थिक विद्यार्थी प्राप्तिक संस्',
    brandDesc3:
      'NTL एक सुरक्षित, स्थिर, सुरक्षित, सुरक्षित, सामान्य और प्रतिष्ठित ब्लॉकचेन्स मालिकों को प्रयोक्ता के विश्वास के योग्य अन्तराष्ट्रीय परिवर',
    brandTitle2: '------',
    brandTitle1: 'अंतर्राष्ट्रीय डिजिटल आसेट डेरिवेटिव्स बदलें',
    ptaqTitle: 'प्लेटफ़ॉर्म सुरक्षा',
    ptaqContent: 'हैश एल्गोरिथम एन्क्रिप्शन का उपयोग करना, दुनिया का अग्रणी सुरक्षा प्रमाणन',
    ptslTitle: 'मंच की ताकत',
    ptslContent: 'दुनिया का सबसे बड़ा बिटकॉइन ट्रेडिंग प्लेटफॉर्म',
    newsTitle: 'मल्टी-टर्मिनल एपीपी',
    brandTitle: 'ईपीबीटीसी के बारे में',
    brandDetail: 'ईमानदारी | निष्पक्षता | उत्साह | खुलापन',
    brandDesc1:
      'WWW.EPBITCOIN.COM की स्थापना शुरुआती बिटकॉइन प्रतिभागियों और गीक्स के एक समूह द्वारा की गई थी। टीम के मुख्य सदस्य Google, Microsoft, अलीबाबा, Tencent, आदि जैसी प्रसिद्ध कंपनियों से आते हैं, जिनके पास गहन आरएंडडी ताकत और समृद्ध अनुभव है। इंटरनेट उत्पाद संचालन में।',
    brandDesc2:
      'WWW.EPBITCOIN.COM एक बुनियादी ब्लॉकचेन सेवा प्रदाता के रूप में तैनात है, जो वैश्विक उपयोगकर्ताओं के लिए उच्च गुणवत्ता वाले एन्क्रिप्टेड एसेट ट्रेडिंग प्लेटफॉर्म प्रदान करने के लिए प्रतिबद्ध है, `कोई बुराई नही` के मूल सिद्धांत का पालन करता है, और ग्राहकों को ईमानदारी, निष्पक्ष और उत्साह से सेवा देने पर जोर देता है। , खुलेपन के साथ रवैया उन सभी भागीदारों / परियोजनाओं का स्वागत करता है जो उपयोगकर्ताओं के मौलिक हितों के लिए फायदेमंद हैं।',
    gettingstart: 'आरंभ करना | जल्दी से सिक्के खरीदें',
    officialstart: 'EPBTC आधिकारिक नौसिखिया प्रवेश सहायक चैनल',
    oneminutebuy: 'मुद्रा लेनदेन',
    oneminutebuytips: 'एक डिजिटल संपत्ति का उपयोग सीधे दूसरे के लिए विनिमय करने के लिए करें',
    baseknow: 'अनुबंध लेनदेन',
    baseknowtips: 'T+0 टू-वे ट्रेडिंग वर्चुअल कमोडिटी को कोलेटरल वस्तु विनिमय अनुबंध के रूप में',
    baseexchange: 'विकल्प अनुबंध',
    baseexchangetips: 'यह एक डिजिटल संपत्ति व्युत्पन्न है',
    usersocial: 'त्वरित विनिमय',
    usersocialtips: 'डिजिटल संपत्तियों का त्वरित आदान-प्रदान',
    mainboard: 'मुख्य बोर्ड व्यापार क्षेत्र',
    preview: 'पिछला पृष्ठ',
    nextpage: 'अगला पृष्ठ',
    downloadwhite:
      'श्वेत पत्र डाउनलोड करें (या श्वेत पत्र पर राइट क्लिक करें -> "इस रूप में सहेजें ...")',
  },
  nav: {
    shouye: 'मुखपृष्ठ',
    jiaoyi: 'फिएट मुद्रा लेनदेन',
    dingdan: 'आदेश का प्रबंधन',
    zijin: 'वित्तीय केन्द्र',
    yonghu: 'उपयोगकर्ता केंद्र',
    bibi: 'सिक्का मुद्रा लेनदेन',
    fabu: 'एक विज्ञापन रखें',
    ptjy: 'प्लेटफ़ॉर्म लेनदेन',
    ptaq: 'प्लेटफ़ॉर्म सुरक्षा',
    ptsl: 'मंच की ताकत',
    xwzx: 'मल्टी-टर्मिनल एपीपी',
  },
  progress: {
    already: 'खनन',
    total: 'खनन की कुल राशि 6 ​​अरब बीएचबी है',
  },
  service: {
    USDT: 'यूएसडीटी',
    BTC: 'बीटीसी',
    ETH: 'ईटीएच',
    CUSTOM: 'वैकल्पिक',
    COIN: 'मुद्रा',
    favor: 'इकट्ठा',
    symbol: 'ट्रेडिंग जोड़ी',
    NewPrice: 'नवीनतम कीमत',
    ExchangeNum: 'लेनदेन की संख्या',
    FeePercent: 'अनुदान दर',
    Change: '24 घंटे ऊपर और नीचे',
    OpenPrice: 'शुरुआती कीमत',
    PriceTrend: 'मूल्य प्रवृत्ति',
    Operate: 'संचालन',
    Exchange: 'व्यापार करें',
    trading: 'व्यापार अब',
    high: '24 घंटे की उच्चतम कीमत',
    low: '24 घंटे सबसे कम कीमत',
  },
  exchange: {
    loginBuy: 'लॉगइन (बीटीसी) के बाद खरीद करें',
    loginOut: 'लॉगइन (बीटीसी) के बाद बेचें',
    coin: 'मुद्रा',
    symbol: 'ट्रेडिंग जोड़ी',
    lastprice: 'नवीनतम कीमत',
    daychange: '24 घंटे ऊपर और नीचे',
    market: 'मंडी',
    favorite: 'इकट्ठा',
    do_favorite: 'जुटाया हुआ',
    cancel_favorite: 'पसंदीदा',
    type: 'प्रकार',
    dealamount: 'टर्नओवर',
    high: 'उच्च',
    low: 'कम',
    vol: '24H राशि',
    buy: 'खरीदना',
    sell: 'बेचना',
    limited_price: 'लिमिट प्राइस',
    market_price: 'बाजार मूल्य',
    fees_rate: 'भाव',
    balance: 'संपत्तियां',
    or: 'या',
    starttrade: 'व्यापार शुरू करें',
    canuse: 'उपलब्ध',
    recharge: 'रिचार्ज',
    buyprice: 'खरीद मूल्य',
    buynum: 'वॉल्यूम खरीदें',
    amount: 'लेन - देन की राशि',
    buyin: 'खरीदना',
    disablebuyin: 'बाजार मूल्य पर खरीदारी पर रोक',
    buytip: 'बाजार में सबसे अच्छी कीमत पर खरीदें',
    sellprice: 'विक्रय मूल्य',
    sellnum: 'बिक्री की गई राशि',
    sellout: 'बेचना',
    disablesellout: 'बाजार मूल्य पर बेचना प्रतिबंधित है',
    selltip: 'बाजार में सबसे अच्छी कीमत पर बेचें',
    curdelegation: 'वर्तमान आयोग',
    hisdelegation: 'प्रतिनिधिमंडल इतिहास',
    realtransaction: 'वास्तविक समय लेनदेन',
    num: 'मात्रा',
    price: 'कीमत',
    direction: 'दिशा',
    time: 'समय',
    stall: 'स्टॉल',
    total: 'कुल योग',
    traded: 'सौदा पूर्ण',
    action: 'संचालन',
    undo: 'आदेश रद्द',
    delegationnum: 'सौंपा राशि',
    done: 'सौदा पूर्ण',
    status: 'राज्य',
    finished: 'पूरा किया हुआ',
    canceled: 'रद्द',
    parted: 'आंशिक लेनदेन',
    tip: 'संकेत',
    buyamounttip: 'कृपया खरीद राशि दर्ज करें',
    buyamounttipwarning: 'खरीदारी की संख्या इससे अधिक नहीं हो सकती',
    success: 'सफलतापूर्वक जमा',
    pricetip: 'कृपया लेनदेन राशि दर्ज करें',
    pricetipwarning: 'लेन-देन की राशि इससे अधिक नहीं हो सकती',
    sellamounttip: 'कृपया बेची गई राशि दर्ज करें',
    sellamounttipwarning: 'बेची गई मात्रा इससे अधिक नहीं हो सकती',
    sellpricetip: 'कृपया बिक्री मूल्य दर्ज करें',
    sellpricetipwarning: 'विक्रय मूल्य इससे अधिक नहीं हो सकता',
    undotip: 'क्या आप निश्चित रूप से आदेश को रद्द करना चाहते हैं? ',
    cancelsuccess: 'आदेश सफलतापूर्वक रद्द किया गया!',
    marketprice: 'बाजार मूल्य',
    expand: {
      time: 'समय',
      price: 'कीमत',
      amount: 'मात्रा',
      fee: 'संचालन शुल्क',
    },
    realtime: 'टाइमशेयर',
    kline: 'के लाइन चार्ट',
    depth: 'गहराई नक्शा',
    publishstate0: 'घटना शुरू होने वाली है, कृपया प्रतीक्षा करें...',
    publishstate1: 'घटना समाप्त होने वाली है, कृपया जल्द से जल्द व्यापार करें...',
    publishstate2: 'सक्रिय आदेश समेकित हो रहा है, कृपया प्रतीक्षा करें...',
    publishstate3: 'समेकन समाप्त हो गया है, कृपया स्वतंत्र रूप से व्यापार करें ...',
    publishdetail: 'गतिविधि नियम विवरण',
    publishprice: 'कीमत जारी करें',
    publishamount: 'कुल',
    publishcounttxt0: 'घटना शुरू होने से पहले अभी भी बाकी है',
    publishcounttxt1: 'घटना के अंत तक अभी बाकी है',
    publishcounttxt2: 'अभी भी परिसमापन के अंत तक बाकी है',
    publishcounttxt3: 'घटना समाप्त हो गई है',
    dateTimeDay: 'आकाश',
    dateTimeHour: 'समय',
    dateTimeMinutes: 'मिनट',
    dateTimeSeconds: 'दूसरा',
    moredetail: 'अधिक जानकारी',
    coinSymbol: 'कारोबारी मुद्रा',
    cycle: 'चक्र',
    closeprice: 'करीबी कीमत',
    openprice: 'उद्घाटन मूल्य',
    win: 'सफलता',
    lose: 'विफल',
    tied: 'खींचना',
    earn: 'फायदा',
    orderType: 'क्षतिपूर्ति',
    orderTypeNo: 'नहीं',
    orderTypeYes: 'हां',
  },
  swap: {
    contract: 'अनुबंध',
    swapindex: 'टिकाऊ',
    coin: 'मुद्रा',
    symbol: 'ट्रेडिंग जोड़ी',
    volume: 'आयतन',
    lastprice: 'नवीनतम कीमत',
    daychange: '24H उतार-चढ़ाव',
    market: 'मंडी',
    favorite: 'इकट्ठा',
    do_favorite: 'जुटाया हुआ',
    cancel_favorite: 'पसंदीदा',
    type: 'प्रकार',
    dealamount: 'टर्नओवर',
    high: 'उच्च',
    low: 'कम',
    vol: '24H राशि',
    buy: 'खरीदना',
    sell: 'बेचना',
    limited_price: 'सीमा आदेश',
    market_price: 'बाजार मूल्य आयोग',
    spot_price: 'टेक प्रॉफिट स्टॉप लॉस',
    fees_rate: 'भाव',
    balance: 'संपत्तियां',
    or: 'या',
    starttrade: 'व्यापार शुरू करें',
    canuse: 'उपलब्ध',
    recharge: 'रिचार्ज',
    buyprice: 'खरीद मूल्य',
    buynum: 'वॉल्यूम खरीदें',
    amount: 'लेन - देन की राशि',
    buyin: 'खरीदना',
    disablebuyin: 'बाजार मूल्य पर खरीदारी पर रोक',
    buytip: 'बाजार में सबसे अच्छी कीमत पर खरीदें',
    sellprice: 'विक्रय मूल्य',
    sellnum: 'बिक्री की गई राशि',
    sellout: 'बेचना',
    disablesellout: 'बाजार मूल्य पर बेचना प्रतिबंधित है',
    selltip: 'बाजार में सबसे अच्छी कीमत पर बेचें',
    curdelegation: 'वर्तमान आयोग',
    hisdelegation: 'इतिहास आयोग',
    realtransaction: 'वास्तविक समय लेनदेन',
    num: 'मात्रा',
    price: 'कीमत',
    direction: 'दिशा',
    time: 'समय',
    stall: 'स्टॉल',
    total: 'कुल योग',
    traded: 'सौदा पूर्ण',
    action: 'संचालन',
    undo: 'आदेश रद्द',
    delegationnum: 'सौंपा राशि',
    done: 'सौदा पूर्ण',
    status: 'राज्य',
    finished: 'पूरा किया हुआ',
    canceled: 'रद्द',
    parted: 'आंशिक लेनदेन',
    tip: 'संकेत',
    buyamounttip: 'कृपया खरीद राशि दर्ज करें',
    buyamounttipwarning: 'खरीदारी की संख्या इससे अधिक नहीं हो सकती',
    success: 'सफलतापूर्वक जमा',
    pricetip: 'कृपया लेनदेन राशि दर्ज करें',
    pricetipwarning: 'लेन-देन की राशि इससे अधिक नहीं हो सकती',
    sellamounttip: 'कृपया बेची गई राशि दर्ज करें',
    sellamounttipwarning: 'बेची गई मात्रा इससे अधिक नहीं हो सकती',
    sellpricetip: 'कृपया बिक्री मूल्य दर्ज करें',
    sellpricetipwarning: 'विक्रय मूल्य इससे अधिक नहीं हो सकता',
    undotip: 'क्या आप निश्चित रूप से आदेश को रद्द करना चाहते हैं? ',
    cancelsuccess: 'आदेश सफलतापूर्वक रद्द किया गया!',
    marketprice: 'बाजार मूल्य',
    expand: {
      time: 'समय',
      price: 'कीमत',
      amount: 'मात्रा',
      fee: 'संचालन शुल्क',
    },
    realtime: 'टाइमशेयर',
    kline: 'के लाइन चार्ट',
    depth: 'गहराई नक्शा',
    open: 'खुले स्थान',
    close: 'करीबी स्थिति',
    latestdeal: 'नवीनतम लेनदेन',
    myswapaccount: 'मेरा अनुबंध खाता',
    accountmode: 'खाता मोड',
    accountmargin: 'अनुबंध एकाधिक',
    accountquanyi: 'खाता कुल इक्विटी',
    profitandloss: 'अप्राप्त लाभ और हानि',
    principalAmount: 'उपलब्ध गुंजाइश',
    positionAmount: 'स्थिति मार्जिन',
    frozenAmount: 'फ्रीज मार्जिन',
    principalRate: 'फंड उपयोग दर',
    zijinhuazhuan: 'पैसा भेजना',
    up: ' बहुत',
    down: ' शून्य',
    canup: 'और खोल सकते हैं',
    candown: 'खाली खोल सकते हैं',
    cancloseup: 'छोटी स्थिति को बंद किया जा सकता है',
    canclosedown: 'कई पदों को बंद किया जा सकता है',
    openup: 'खुले लंबे समय तक खरीदें',
    opendown: 'छोटा खोलने के लिए बेचें',
    closeup: 'शॉर्ट शॉर्ट खरीदें',
    closedown: 'फ्लैट और अधिक बेचें',
    currentposition: 'वर्तमान पद',
    assetsdetail: 'फंड विवरण',
    marginMode1: 'गोदाम द्वारा चेसिस',
    marginMode2: 'पूर्ण गोदाम',
    modifyMarginModeTitle: 'खाता मोड संशोधित करें',
    modifyLeverage: 'अनुबंध गुणकों को समायोजित करें',
    moredetail: 'अधिक जानकारी',
    shareNumber: 'खोलना',
    entrustprice: 'कमीशन मूल्य',
    triggleprice: 'ट्रिगर मूल्य',
    openvolume: 'खोले गए पदों की संख्या',
    closevolume: 'बंद पदों की मात्रा',
    triggleplaceholder: 'डिफ़ॉल्ट कमीशन मूल्य बाजार मूल्य है',
    marketpriceplaceholder: 'सर्वोत्तम बाजार मूल्य पर बेचें',
    levmaxtip: 'अनुबंध गुणक उच्चतम गुणक से अधिक नहीं हो सकता',
    levmintip: 'अनुबंध गुणक न्यूनतम गुणक से कम नहीं हो सकता',
    marginModeSuccessTip: 'अनुबंध खाता मॉडल सफलतापूर्वक संशोधित',
    noenoughbalance: 'अपर्याप्त खाता मार्जिन',
    pleaseinputopenvolume: 'खोले गए पदों की संख्या 0 से अधिक होनी चाहिए',
    pleaseinputcorrectopenvolume: 'कृपया सही खुली स्थिति मात्रा दर्ज करें',
    pleaseinputrightentrustprice: 'कृपया सही ऑर्डर मूल्य दर्ज करें',
    noenoughposition: 'अपर्याप्त परिसमापन योग्य स्थिति',
    pleaseinputclosevolume: 'समापन मात्रा 0 से अधिक होनी चाहिए',
    pleaseinputcorrectclosevolume: 'कृपया सही समापन मात्रा दर्ज करें',
    pos_Pl: 'आय',
    pos_Ratio: 'प्रतिफल दर',
    pos_pos: 'पद',
    pos_canClose: 'फ्लैट होने योग्य राशि',
    pos_openPrice: 'औसत शुरुआती कीमत',
    pos_leverage: 'अनुबंध एकाधिक',
    pos_margin: 'वर्तमान मार्जिन',
    pos_mRatio: 'मार्जिन दर',
    pos_cmRatio: 'मार्जिन दर बनाए रखें',
    pos_long: 'लंबा',
    pos_short: 'छोटा',
    ent_entrustType: 'खुली स्थिति / बंद स्थिति',
    ent_direction: 'दिशा',
    ent_type: 'प्रतिनिधिमंडल प्रकार',
    ent_trigglePrice: 'ट्रिगर मूल्य',
    ent_entrustPrice: 'कमीशन मूल्य',
    ent_tradedPrice: 'अंतिम कीमत',
    ent_margin: 'मार्जिन',
    ent_volume: 'आदेश की मात्रा',
    ent_status: 'प्रतिनिधिमंडल की स्थिति',
    ent_status1: 'कमीशनिंग',
    ent_status2: 'निरस्त',
    ent_status3: 'प्रतिनिधिमंडल विफल',
    ent_status4: 'प्रतिनिधिमंडल सफल',
    ent_statusblast: 'फैलना',
    ent_pl: 'निपटान लाभ और हानि',
    ent_fee: 'संचालन शुल्क',
    triggerpriceshouldbigger: 'ट्रिगर मूल्य 0 से अधिक होना चाहिए',
  },
  second: {
    balance: 'संतुलन',
    profitability: 'लाभप्रदता',
    opentime: 'खुलने का समय',
    transactionMode: 'ट्रेडिंग मोड',
    currentPosition: 'वर्तमान पद',
  },
  ctc: {
    title: 'एक-क्लिक ट्रेडिंग प्लेटफ़ॉर्म होस्टिंग सुरक्षित और सुरक्षित',
    desc: 'डिजिटल संपत्ति को उपयोगकर्ता संपत्ति की सुरक्षा सुनिश्चित करने के लिए ईपीबीटीसी प्लेटफॉर्म द्वारा होस्ट किया जाता है',
    buyin: 'खरीदना',
    sell: 'बेचना',
    buyprice: 'खरीद मूल्य',
    sellprice: 'विक्रय मूल्य',
    buynum: 'राशि खरीदें',
    sellnum: 'बिक्री की गई राशि',
    payamount: 'निपटान कुल',
    getamount: 'निपटान कुल',
    avabalance: 'उपलब्ध मात्रा',
    time: 'लेनदेन घंटे',
    tip: 'संकेत',
    notice: 'टिप्स',
    notice1:
      '1. फिएट मुद्रा व्यापार क्षेत्र उपयोगकर्ताओं और स्वीकारकर्ताओं के बीच परिसंपत्ति लेनदेन के लिए है। फंड को प्लेटफॉर्म पर स्थानांतरित नहीं किया जाता है, और प्लेटफॉर्म आरएमबी रिचार्ज / प्रेषण स्वीकार नहीं करता है;',
    notice2:
      '२. फिएट मुद्रा व्यापार स्वीकारकर्ताओं ने वास्तविक नाम प्रमाणीकरण पारित किया है, लेनदेन मार्जिन प्रदान करते हैं, और टोकन मंच द्वारा होस्ट किए जाते हैं, आप विनिमय करने के लिए आश्वस्त रह सकते हैं;',
    notice3:
      '3. स्वीकर्ता का सेवा समय हर दिन 09: 00-21: 00 है, स्वीकृति आदेश प्राप्त करने के 30 मिनट के भीतर पूरी हो जाएगी, और 2 घंटे के भीतर पूरा नहीं किया गया लेनदेन शून्य हो जाएगा;',
    notice4:
      '4. कानूनी मुद्रा व्यापार क्षेत्र बैंक कार्ड का समर्थन करता है, और आपको हस्तांतरण के लिए अपने वास्तविक नाम सत्यापित खाते का उपयोग करना चाहिए;',
    notice5:
      '5. लेनदेन निधि की सुरक्षा सुनिश्चित करने के लिए, फिएट मुद्रा लेनदेन के लिए उपयोगकर्ताओं को वास्तविक नाम प्रमाणीकरण पास करने की आवश्यकता होती है। कृपया वास्तविक नाम प्रमाणीकरण पारित करने के बाद समय पर फंड पासवर्ड और प्राप्त खाता सेट करें, अन्यथा लेनदेन संभव नहीं होगा ;',
    payType: 'भुगतान विधि',
    receiveType: 'भुगतान विधि',
    moneyTips:
      'उपरोक्त राशि केवल संदर्भ के लिए है, कृपया निम्नलिखित आदेश के बाद वास्तविक निपटान राशि देखें',
    verifyset: 'वास्तविक नाम प्रमाणीकरण/फंड पासवर्ड',
    payset: 'भुगतान/रसीद सेटिंग',
    trade: 'व्यापार',
    input50tips: 'कृपया ५० और ५०००० के बीच की कोई संख्या दर्ज करें',
    bank: 'बैंक कार्ड',
    alipay: 'अलीपे',
    wechatpay: 'वीचैट',
    payaccountset: 'भुगतान खाता सेटिंग्स',
    useselfaccount:
      'कृपया एक संग्रह/भुगतान खाते का उपयोग करना सुनिश्चित करें जो आपके अपने वास्तविक नाम प्रमाणीकरण से मेल खाता हो',
    orderSn: 'क्रम संख्या',
    tradetime: 'आदेश का समय',
    direction: 'सौदे का प्रकार',
    amount: 'मात्रा (यूएसडीटी)',
    money: 'कुल (सीएनवाई)',
    price: 'इकाई मूल्य (सीएनवाई)',
    status: 'राज्य',
    operate: 'संचालन',
    moredetail: 'अधिक ट्रेडिंग निर्देश',
  },
  cexchange: {
    use: 'भरें',
    consume: 'बदलें',
    title: 'बत्वरित कन्वर्ट',
    desc: 'एक-क्लिक एक्सचेंज,लाभों का आनंद लें',
    quantity: 'विनिमय मात्रा',
    inputtips1: 'एक कस्टम मात्रा दर्ज करें',
    choosecurrency: 'विनिमय मुद्रा चुनें',
    maxtip: 'अधिकतम मोचन',
    mintip: 'न्यूनतम विनिमय',
    all: 'सब',
    totalpay: 'कुल विनिमय राशि',
    restricted: 'आज का सीमित संस्करण',
    currentprice: 'वर्तमान विनिमय मूल्य',
    nextprice: 'अगला विनिमय मूल्य',
    confirmpay: 'एक्सचेंज की पुष्टि करें',
    assets: 'उपलब्ध:',
    loginFirst: 'पहले प्रवेश करें',
    success: 'बधाई हो! सफल मोचन!',
    tip: 'संकेत',
    confirmmsg: 'पुष्टि करना',
  },
  option: {
    title: 'विकल्प अनुबंध',
    buyupreward: 'कुल राशि खरीदें',
    buydownreward: 'कुल खरीद और हानि',
    quantity: 'खुली स्थिति राशि',
    myamount: 'मेरी खुली स्थिति',
    buyup: 'बुलिश',
    buydown: 'मंदी',
    history: 'पिछले परिणाम',
    seriers: 'एनएस',
    period: 'अपेक्षा करना',
    currentoption: 'वर्तमान अवधि की भविष्यवाणी अवधि',
    openprice: 'उद्घाटन मूल्य',
    currentprice: 'मौजूदा कीमत',
    openprice1: 'उद्घाटन मूल्य',
    closeprice: 'समापन भाव',
    opentime: 'खुलने का समय',
    closetime: 'बंद होने का समय',
    progress: 'पूर्वानुमान प्रगति',
    loginFirst: 'पहले प्रवेश करें!',
    selectAmount: 'कृपया दांवों की संख्या चुनें',
    balancenotenough: 'पर्याप्त उपलब्ध शेषराशि नहीं!',
    betsuccess: 'बधाई हो! बाजी सफल रही!',
    noopening: 'कोई विकल्प अनुबंध लंबित नहीं है',
    col_createTime: 'समय बनाया',
    col_direction: 'पूर्वानुमान दिशा',
    col_result: 'पूर्वानुमान परिणाम',
    col_optionNo: 'अनुबंधनकाल',
    col_betAmount: 'खुली स्थिति राशि',
    col_rewardAmount: 'बोनस मात्रा',
    col_fee: 'ओपन पोजिशन फीस',
    col_winfee: 'पंपिंग',
    win: 'सफलता',
    lose: 'विफल',
    wait: 'चालू',
    avaliablebalance: 'उपलब्ध शेष राशि',
    login: 'पहले प्रवेश करें',
    kline: 'वास्तविक समय उद्धरण',
  },

  otc: {
    Payment_detail: 'भुगतान विधि विवरण',
    bind_payment: 'भुगतान विधि को बाइंड करें',
    payment_placeholder: 'कृपया एक भुगतान विधि चुनें',
    payment: 'भुगतान विधि',
    ad: 'विज्ञापन केंद्र',
    buyin: 'खरीदना',
    sellout: 'बेचना',
    merchant: 'व्यापार',
    applymerchant: 'प्रमाणित व्यापारी के रूप में आवेदन करें',
    volume: 'लेनदेन की संख्या',
    paymethod: 'भुगतान विधि',
    amount: 'मात्रा',
    price_coin: 'कीमत/सिक्का',
    operate: 'संचालन',
    validate: 'कृपया पहले वास्तविक नाम प्रमाणीकरण करें',
    sell: 'बेचना',
    buy: 'खरीदना',
    transaction: 'खरीदें और बेचें लेनदेन',
    buttontxt: 'एक व्यापारी बनें',
    title: 'फिएट मुद्रा लेनदेन',
    desc: 'डिजिटल मुद्रा का सुविधाजनक, सुरक्षित और तेज़ व्यापार',
    title1: 'गतिशील समायोजन',
    desc1: 'बाजार मूल्य के अनुसार वास्तविक समय में उतार-चढ़ाव होता है',
    title2: 'निःशुल्क',
    desc2:
      'उपयोगकर्ता जो देखता है वही आपको मिलता है, खरीदने और बेचने के मूल्य के अलावा किसी प्लेटफ़ॉर्म शुल्क की आवश्यकता नहीं होती है',
    title3: 'वास्तविक समय लेनदेन',
    desc3:
      'व्यापारियों की सेवा के लिए मंच का परिचय, बुद्धिमान मिलान और ऑर्डर लेनदेन, मिलान के लिए प्रतीक्षा करने की आवश्यकता नहीं है',
    title4: 'प्लेटफ़ॉर्म गारंटी',
    desc4:
      'प्लेटफ़ॉर्म प्रमाणित व्यापारी, सुरक्षित और सुरक्षित, 24 घंटे ग्राहक सेवा एस्कॉर्ट्स लेनदेन',
    myad: {
      title: 'मेरे विज्ञापन',
      post: 'एक विज्ञापन रखें',
      alert:
        '[अनुस्मारक]: जब विज्ञापन की न्यूनतम लेनदेन राशि के लिए खरीद मात्रा और हैंडलिंग शुल्क विज्ञापन की शेष मात्रा से अधिक है, तो विज्ञापन स्वचालित रूप से अलमारियों से हटा दिया जाएगा',
      no: 'विज्ञापन संख्या',
      type: 'विज्ञापन प्रकार',
      sell: 'ऑनलाइन बिक्री',
      buy: 'ऑनलाइन खरीदो',
      limit: 'आदेश सीमा',
      remain: 'शेष राशि',
      coin: 'मुद्रा',
      created: 'समय बनाया',
      operate: 'संचालन',
      errmsg: 'विज्ञापन को हटाए जाने के बाद ही संपादित किया जा सकता है',
      update: 'संशोधन',
      shelf: 'उपलब्ध',
      dropoff: 'ऑफ शेल्फ',
      delete: 'हटाएं',
    },
    myorder: 'मेरे आदेश',
    chatline: {
      status_1: 'खरीदार ने भुगतान नहीं किया है, खरीदार के भुगतान की प्रतीक्षा कर रहा है! ',
      status_2: 'खरीदार ने भुगतान कर दिया है और विक्रेता के रिहा होने की प्रतीक्षा कर रहा है! ',
      status_3: 'आदेश पूरा हो गया है! ',
      status_4: 'आदेश की अपील की जा रही है! ',
      status_5: 'आदेश रद्द कर दिया गया है! ',
      loadmore: 'और लोड करें',
      warning:
        'धोखाधड़ी रोकथाम युक्तियाँ: हाल ही में, धोखेबाजों ने धोखाधड़ी करने के लिए बैंक हस्तांतरण प्रेषण जानकारी और नकली प्रेषण प्रमाण-पत्रों का बार-बार उपयोग किया है। सत्यापित करने के लिए कृपया अपने प्राप्त खाते में लॉग इन करें। प्रेषित निधियों की सुरक्षा सुनिश्चित करें और बैंक कार्डों के जमने के जोखिम से बचें! ',
      contenttip: 'कृपया चैट सामग्री दर्ज करें और भेजने के लिए एंटर दबाएं',
      contentmsg: 'संदेश खाली नहीं हो सकता',
    },
    chat: {
      seller: 'विक्रेता',
      buyer: 'खरीदार',
      exchangeamount: 'लेन - देन की राशि',
      operatetip: 'ऑपरेशन टिप्स',
      operatetip_1:
        'कृपया निर्दिष्ट समय सीमा के भीतर दूसरे पक्ष द्वारा दिए गए खाते के अनुसार भुगतान पूरा करें, और इस पृष्ठ पर क्लिक करें',
      finishpayment: 'भुगतान पूरा हुआ',
      operatetip_1_1: 'फंड ट्रांसफर करते समय कृपया मैसेज में ट्रांजेक्शन नंबर नोट करें',
      operatetip_1_2:
        'भुगतान प्राप्त करने के बाद, विक्रेता वेबसाइट पर भुगतान की पुष्टि करेगा, और सिस्टम स्वचालित रूप से आपके द्वारा खरीदी गई डिजिटल संपत्ति को आपके खाते में जारी कर देगा। कृपया जांच और प्राप्त करने पर ध्यान दें',
      note: 'सूचना',
      notetip:
        'कृपया दूसरे पक्ष के साथ संवाद करने के लिए अन्य चैट सॉफ़्टवेयर का उपयोग न करें, दूसरे पक्ष द्वारा आपको भेजी गई किसी भी फाइल, ईमेल संलग्नक आदि को स्वीकार करने की तो बात ही छोड़ दें। सभी संचार लिंक इस पृष्ठ पर चैट विंडो में पूर्ण होने चाहिए',
      operatetip_2_1:
        'आपके द्वारा बेची गई डिजिटल संपत्ति को मंच को हिरासत और फ्रीजिंग के लिए सौंप दिया गया है। कृपया क्लिक करें, दूसरे पक्ष से भुगतान की प्राप्ति की पुष्टि के बाद',
      operatetip_2_2:
        'कृपया सिक्कों को जारी करने का आग्रह करने के किसी भी कारण पर विश्वास न करें, और नुकसान से बचने के लिए धन की प्राप्ति की पुष्टि के बाद डिजिटल संपत्ति जारी करें! ',
      operatetip_2_3:
        'एसएमएस प्राप्त करने के बाद, कृपया यह पुष्टि करने के लिए ऑनलाइन बैंकिंग या मोबाइल बैंकिंग में लॉग इन करें कि क्या खाते में धनराशि जमा की गई है, ताकि धोखाधड़ी वाले एसएमएस प्राप्त होने के कारण डिजिटल संपत्ति की गलत रिलीज से बचा जा सके! ',
      confirmrelease: 'रिलीज़ की पुष्टि करें',
      paydigital: 'डिजिटल संपत्तियों के लिए भुगतान करें',
      orderstatus: 'आदेश की स्थिति',
      orderstatus_1: 'भुगतान पूरा हुआ',
      orderstatus_2: 'आदेश अपील',
      orderstatus_3: 'रिलीज़ की पुष्टि करें',
      orderstatus_4: 'सौदा रद्द',
      orderstatus_5: 'आदेश अपील',
      order: 'आदेश',
      and: 'तथा',
      transition: 'सौदा',
      transprice: 'ट्रेडिंग प्राइस',
      transnum: 'लेनदेन की संख्या',
      transmoney: 'लेन - देन की राशि',
      tip1: 'उपयोगकर्ता ने फिलहाल बैंक कार्ड नंबर नहीं जोड़ा है',
      tip2: 'उपयोगकर्ता ने अस्थायी रूप से Alipay खाता नहीं जोड़ा है',
      tip3: 'उपयोगकर्ता ने अस्थायी रूप से एक WeChat खाता नहीं जोड़ा है',
      zfb: 'अलीपे',
      wx: 'वीचैट',
      qrcode: 'क्यूआर कोड',
      msg1: 'क्या आप सुनिश्चित हैं कि भुगतान पूरा हो गया है',
      msg2: 'भुगतान वापसी योग्य नहीं है! क्या आप वाकई ऑर्डर रद्द करना चाहते हैं? ',
      msg3: '[महत्वपूर्ण]: भुगतान की गई वस्तु वापसी योग्य नहीं है! क्या आप वाकई ऑर्डर रद्द करना चाहते हैं? ',
      msg4: 'भुगतान किया गया है, सिक्के प्राप्त नहीं हुए हैं',
      msg5: 'सिक्का का भुगतान किया गया है, लेकिन भुगतान प्राप्त नहीं हुआ है',
      tip: 'संकेत',
      comptype: 'शिकायत का प्रकार',
      compremark: 'शिकायत टिप्पणी',
      willcomp: 'मैं शिकायत करना चाहता हूं',
      msg6: 'क्या आप निश्चित रूप से मुद्रा जारी करना चाहते हैं? ',
      result_1: 'भुगतान की प्रतीक्षा करना',
      result_2: 'रिलीज का इंतजार',
      result_3: 'पूरा किया हुआ',
      result_4: 'आकर्षक',
      result_5: 'रद्द',
      msg7: 'फंड पासवर्ड',
      msg7tip: 'कृपया फंड पासवर्ड भरें',
    },
    checkuser: {
      emaildone: 'मेल प्रमाणित हो गया है',
      emailundo: 'मेल प्रमाणित नहीं है',
      teldone: 'मोबाइल फोन नंबर सत्यापित हो गया है',
      telundo: 'मोबाइल फ़ोन नंबर सत्यापित नहीं है',
      idcarddone: 'आईडी कार्ड प्रमाणित हो गया है',
      idcardundo: 'आईडी कार्ड प्रमाणित नहीं है',
      language: 'भाषा',
      languagetext1: 'English',
      languagetext: 'चीनी',
      registtime: 'पंजीकरण समय',
      exchangetimes: 'लेनदेन की संचित संख्या',
      exchangeinfo: 'लेन-देन की जानकारी',
      tablabel1: 'ऑनलाइन बिक्री',
      tablabel2: 'ऑनलाइन खरीदो',
      col_symbol: 'मुद्रा',
      col_paymode: 'भुगतान विधि',
      col_num: 'मात्रा',
      col_price: 'कीमत',
      col_created: 'मुक्त करने का समय',
      col_operate: 'संचालन',
      operatemsg: 'कृपया पहले वास्तविक नाम प्रमाणीकरण करें',
      buyin: 'खरीदना',
      sellout: 'बेचना',
    },
    tradecenter: {
      postad: 'एक विज्ञापन रखें',
      exchange: 'जीसीसी लेनदेन',
      buyin: 'मैं खरीदना चाहते हैं',
      sellout: 'मैं बेचना चाहता हूँ',
    },
    tradeinfo: {
      emaildone: 'मेल प्रमाणित हो गया है',
      emailundo: 'मेल प्रमाणित नहीं है',
      teldone: 'मोबाइल फोन नंबर सत्यापित हो गया है',
      telundo: 'मोबाइल फ़ोन नंबर सत्यापित नहीं है',
      idcarddone: 'आईडी कार्ड प्रमाणित हो गया है',
      idcardundo: 'आईडी कार्ड प्रमाणित नहीं है',
      exchangetimes: 'लेनदेन',
      price: 'कीमत',
      num: 'मात्रा',
      paymethod: 'भुगतान विधि',
      exchangelimitamount: 'लेन-देन की सीमा',
      location: 'स्थान',
      location_text: 'चीन',
      exchangeperiod: 'लेनदेन अवधि',
      minute: 'मिनट',
      amounttip: 'कृपया राशि दर्ज करें',
      numtip: 'कृपया मात्रा दर्ज करें',
      remarktip: 'उसे अपना अनुरोध बताओ',
      remarktitle: 'टिप्पणी जानकारी',
      exchangetitle: 'लेनदेन नोट्स',
      exchange_tip1:
        'आपके द्वारा लेन-देन अनुरोध शुरू करने के बाद, डिजिटल मुद्रा को हिरासत में बंद कर दिया जाता है और प्लेटफॉर्म द्वारा संरक्षित किया जाता है। यदि आप एक विक्रेता हैं, तो लेन-देन अनुरोध शुरू करने के बाद, आप रिचार्ज कर सकते हैं और खरीदार के भुगतान की प्रतीक्षा कर सकते हैं। खरीदार भुगतान समय सीमा के भीतर भुगतान करता है। भुगतान प्राप्त करने के बाद आपको डिजिटल मुद्रा को हिरासत में जारी करना चाहिए। ',
      exchange_tip2:
        'व्यापार करने से पहले, कृपया "प्लेटफ़ॉर्म नेटवर्क सेवा शर्तें" पढ़ें और अक्सर पूछे जाने वाले प्रश्न और ट्रेडिंग गाइड जैसे दस्तावेजों की मदद करें। ',
      exchange_tip3:
        'झूठों से सावधान! कृपया ट्रेडिंग से पहले उपयोगकर्ता द्वारा प्राप्त मूल्यांकन की जांच करें, और नए बनाए गए खाते पर अधिक ध्यान दें। ',
      exchange_tip4:
        'कृपया ध्यान दें कि राउंडिंग और मूल्य में उतार-चढ़ाव डिजिटल मुद्रा की अंतिम लेनदेन राशि को प्रभावित कर सकता है। आपके द्वारा दर्ज की गई निश्चित राशि अंतिम राशि निर्धारित करती है, और डिजिटल मुद्रा राशि की गणना उसी समय वास्तविक समय विनिमय दर से की जाएगी जब अनुरोध जारी किया जाता है। ',
      exchange_tip5:
        'हिरासत सेवा ऑनलाइन लेनदेन के खरीदारों और विक्रेताओं दोनों की सुरक्षा करती है। विवाद की स्थिति में, हम प्रदान की गई सभी जानकारी का मूल्यांकन करेंगे और डिजिटल मुद्रा को उसके कानूनी स्वामी को जारी करेंगे। ',
      warning1: '2 दशमलव स्थानों तक दर्ज करें',
      warning2: 'आदेश राशि है',
      warning3: '8 दशमलव स्थानों तक दर्ज करें',
      warning4: 'आदेश मात्रा है',
      confirmbuyin: 'खरीद की पुष्टि करें',
      confirmsellout: 'बिक्री की पुष्टि करें',
      buyin: 'खरीदना',
      sellout: 'बेचना',
      warning5: 'कृपया आवश्यकतानुसार आदेश भरें',
    },
    publishad: {
      createad: 'एक विज्ञापन लेनदेन बनाएं',
      msg1: 'यदि आप अक्सर व्यापार करते हैं, तो आप अपने स्वयं के व्यापार विज्ञापन बना सकते हैं। यदि आप कभी-कभार ही व्यापार करते हैं, तो हमारा सुझाव है कि आप सीधे खोज करें',
      msg2: 'एक लेन-देन विज्ञापन बनाएँ है',
      msg3: 'नि: शुल्क',
      msg4: 'यदि आप बनाए गए विज्ञापन को सीधे संपादित करना चाहते हैं, तो कृपया देखें',
      tradead: 'लेन-देन विज्ञापन',
      myad: 'मेरे विज्ञापन',
      iwant: 'मुझे चाहिए',
      sellonline: 'ऑनलाइन बिक्री',
      buyonline: 'ऑनलाइन खरीदो',
      exchangecoin: 'कारोबारी मुद्रा',
      country: 'राष्ट्र',
      currency: 'मुद्रा',
      openfixedprice: 'ओपन फिक्स्ड प्राइस',
      open: 'खोलना',
      close: 'बंद',
      usetip:
        'सक्रियण के बाद, बाजार के साथ आपकी मुद्रा की कीमत में उतार-चढ़ाव नहीं होगा, और कीमत अपरिवर्तित रहेगी। ',
      premiseprice: 'अधिमूल्य',
      premisepricetip: 'कृपया अपना प्रीमियम सेट करें',
      fixedprice: 'एक ही दाम',
      fixedpricetip: 'कृपया अपना लेनदेन मूल्य दर्ज करें',
      marketprice: 'बाजार संदर्भ मूल्य',
      marketpricetip: 'प्रीमियम वर्तमान बाजार मूल्य से अधिक प्रतिशत को दर्शाता है',
      exchangeprice: 'ट्रेडिंग प्राइस',
      formual: 'मूल्य निर्धारण सूत्र',
      num: 'मात्रा',
      num_text1: 'कृपया जो आप चाहते हैं उसे दर्ज करें',
      num_text2: 'मात्रा',
      exchangeperiod: 'लेनदेन अवधि',
      exchangeperiod_text1: 'कृपया अपनी लेन-देन अवधि दर्ज करें',
      minute: 'मिनट',
      tip1: 'खरीदार कितने समय में लेन-देन स्वीकार कर सकता है, कृपया एक पूर्णांक दर्ज करें',
      tip2: '[प्रॉम्प्ट] भुगतान विधि को बाइंड/जोड़ने के लिए व्यक्तिगत केंद्र पर जाएं',
      tip3: 'कृपया अपनी न्यूनतम लेनदेन राशि दर्ज करें',
      tip4: 'कृपया अपनी अधिकतम लेनदेन राशि दर्ज करें',
      tip5: 'आप टिप्पणियों में अपनी विशेष आवश्यकताओं को भर सकते हैं, उदाहरण के लिए: खरीदार की आवश्यकताएं, ऑनलाइन समय, आदि। ',
      paymode: 'भुगतान विधि',
      minlimit: 'न्यूनतम लेनदेन राशि',
      maxlimit: 'अधिकतम लेनदेन राशि',
      remark: 'टिप्पणी जानकारी',
      openautoreply: 'स्वचालित उत्तर चालू करें',
      msg5: 'सक्षम करने के बाद, जब कोई उपयोगकर्ता इस विज्ञापन के माध्यम से आपके साथ लेनदेन शुरू करता है, तो सिस्टम स्वचालित रूप से आपके द्वारा चुनी गई ऑटो-रिप्लाई भाषा दूसरे पक्ष को भेज देगा। ',
      autoreply: 'स्वचालित प्रतिक्रिया',
      autoreplytip:
        'आदेश प्राप्त करने के बाद, यह स्वचालित रूप से खरीदार की जानकारी का जवाब देगा, जैसे: भुगतान विधि, भुगतान खाता संख्या, आदि। ',
      fundpwd: 'फंड पासवर्ड',
      fundpwdtip: 'कृपया अपना फंड पासवर्ड दर्ज करें',
      submit: 'प्रस्तुत',
      warning1: 'कृपया सही संख्या दर्ज करें',
      warning2: 'अतिरिक्त मान 0-20 है',
      warning3: 'कृपया अपनी अधिकतम लेनदेन राशि दर्ज करें! ',
      warning4: 'कृपया एक पूर्णांक दर्ज करें',
      warning5: 'अधिकतम लेनदेन राशि न्यूनतम लेनदेन राशि से अधिक होनी चाहिए! ',
      warning6: 'अधिकतम लेनदेन राशि आपकी कुल बिक्री राशि से अधिक नहीं हो सकती',
      warning7: 'कृपया अपनी न्यूनतम लेनदेन राशि दर्ज करें! ',
      warning8: 'न्यूनतम लेनदेन राशि 100 से अधिक या उसके बराबर होनी चाहिए! ',
      warning9: 'न्यूनतम लेनदेन राशि अधिकतम लेनदेन राशि से कम होनी चाहिए',
      sellout: 'बेचना',
      buyin: 'खरीदना',
      inputtip1: 'कृपया मुद्रा दर्ज करें',
      inputtip2: 'कृपया सही विकल्प दर्ज करें',
      inputtip3: 'प्रीमियम मान 0-20 है और 0 नहीं हो सकता',
      inputtip4: 'कृपया सही निश्चित मूल्य दर्ज करें',
      inputtip5: 'कृपया सही संख्या दर्ज करें, और अधिकतम लेनदेन राशि 100 सिक्कों से अधिक नहीं है',
      inputtip6: 'कृपया सही लेन-देन की समय सीमा दर्ज करें',
      inputtip7: 'कृपया एक लेनदेन विधि चुनें',
      inputtip8: 'कृपया फंड पासवर्ड दर्ज करें',
      zfb: 'अलीपे',
      wx: 'वीचैट',
      unionpay: 'बैंक कार्ड',
      submit_failure: 'सबमिशन विफल!',
      submit_success: '',
      submittip1: 'अपने फंड की सुरक्षा के लिए, कृपया पहले [असली नाम प्रमाणीकरण] करें! ',
      submittip2: 'अपने फंड की सुरक्षा के लिए, कृपया पहले [मोबाइल फोन बाइंडिंग] करें! ',
      submittip3: 'अपने फंड की सुरक्षा के लिए, कृपया पहले [फंड पासवर्ड] सेट करें! ',
      submittip4: 'कृपया कम से कम एक भुगतान विधि बाध्य करें',
    },
    index: {
      title: 'उच्च गुणवत्ता वाले विज्ञापन अनुशंसा',
      exchangetimes: 'लेनदेन',
      exchangeprice: 'ट्रेडिंग प्राइस',
      exchangelimit: 'लेन-देन की सीमा',
      paymode: 'भुगतान विधि',
      buy: 'खरीदना',
      sell: 'बेचना',
      viewmore: 'और देखें',
      bot1: 'सुरक्षित और भरोसेमंद',
      bot1_tip:
        '10 वर्षों से अधिक वित्तीय जोखिम नियंत्रण अनुभव टीम पूरी तरह से  n अनुकूलन योग्य सुरक्षा रणनीति प्रणाली है',
      bot2: 'त्वरित और सुविधाजनक',
      bot2_tip:
        'प्वाइंट-टू-पॉइंट यूजर फ्री ट्रांजेक्शन मोड फंड चैनलों के कई एक्सचेंज तरीकों का समर्थन करता है',
      bot3: 'व्यापक मुद्रा',
      bot3_tip:
        'मल्टी-एसेट ट्रेडिंग अनुभव के कई पहलुओं को पूरा करने के लिए मुख्यधारा की डिजिटल एसेट ट्रेडिंग मुद्राओं का चयन करें',
      ibuy: 'मैं खरीदना चाहते हैं',
      isell: 'मैं बेचना चाहता हूँ',
    },
  },
  activity: {
    all: 'सब',
    prepare: 'शुरू होने जा रहा',
    ongoing: 'चालू',
    distributing: 'वितरण',
    completed: 'पूरा किया हुआ',
    progress: 'अनुसूची',
    totalsupply: 'कुल गतिविधि',
    starttime: 'प्रारम्भ का समय',
    endtime: 'अंतिम समय',
    viewdetail: 'विवरण जांचें',
    attend: 'अभी भाग लें',
    tokendistribute: 'टोकन वितरित किया जा रहा है',
    baseinfo: 'मूलभूत जानकारी',
    status: 'राज्य',
    activitytype: 'सदस्यता प्रकार',
    activitytype0: 'अनजान',
    activitytype1: 'पहले ऑनलाइन (खरीदने के लिए जल्दी)',
    activitytype2: 'पहली बार ऑनलाइन (यहां तक ​​कि विभाजित)',
    activitytype3: 'स्थिति शेयर',
    activitytype4: 'मुफ्त सदस्यता',
    activitytype5: 'क्लाउड माइनिंग मशीन सदस्यता',
    activitytype6: 'लॉक पोजिशन रिलीज',
    activitystatus0: 'अस्थायी',
    activitystatus1: 'तैनात नहीं',
    activitystatus2: 'तैनात',
    activitystatus3: 'निरस्त',
    activitystatus4: 'लंबित सौदा',
    activitystatus5: 'सौदा पूर्ण',
    activitystatus6: 'निरस्त',
    ruledetail: 'विवरण टाइप करें',
    publishprice: 'सदस्यता कीमत',
    activitycoin: 'गतिविधि मुद्रा',
    acceptcoin: 'स्वीकृत मुद्रा',
    holdcoin: 'पद धारण करने के लिए आवश्यक मुद्रा',
    limittimes: 'व्यक्तिगत खरीद सीमा',
    limitamount: 'व्यक्तिगत खरीद सीमा',
    unlimited: 'असीमित',
    alreadyamount: 'खरीदी गई मात्रा',
    alreadyholdamount: 'गतिविधि भागीदारी कुल स्थिति',
    myalreadyholdamount: 'मेरी भाग लेने की स्थिति',
    leftamount: 'शेष राशि',
    attention: 'सूचना',
    attentiontxt1:
      '1. [पोजीशन शेयर], [फ्री सब्सक्रिप्शन], [क्लाउड माइनिंग मशीन सब्सक्रिप्शन], [लॉक पोजीशन] गतिविधियों में भाग लेने के लिए सबमिट करने के बाद, एसेट को फ्रीज और अनफ्रीज कर दिया जाएगा या गतिविधि के अंत के बाद अनलॉक और रिलीज कर दिया जाएगा ',
    attentiontxt2:
      '२. [पोजीशन शेयर], [फ्री सब्सक्रिप्शन], [क्लाउड माइनिंग मशीन सब्सक्रिप्शन], [लॉक पोजिशन] प्रकार की गतिविधियों में भाग लेने के बाद, उपयोगकर्ता भागीदारी के लिए आवेदन को रद्द नहीं करेगा',
    attentiontxt3:
      '3. यदि परियोजना पार्टी और अन्य अप्रत्याशित घटना कारकों के कारण कार्यक्रम रद्द कर दिया जाता है, तो मूल मार्ग जमी हुई संपत्तियों को वापस कर दिया जाएगा',
    attentiontxt4:
      '* इस घटना की अंतिम व्याख्या अधिकार आधिकारिक ईपीबीटीसी (WWW.EPBITCOIN.COM) से संबंधित है',
    inputamount: 'विनिमय राशि दर्ज करें',
    inputholdamount: 'लॉक की गई स्थिति की मात्रा दर्ज करें',
    mybalance: 'माई अवेलेबल बैलेंस',
    holdtips: '[होल्डिंग शेयर] प्रकार की गतिविधियों में भाग लेना गतिविधि के अंत तक लॉक होना चाहिए',
    pleaseinputamount: 'कृपया विनिमय राशि दर्ज करें',
    pleaseinputholdamount: 'कृपया लॉक की गई स्थिति की राशि दर्ज करें',
    commitfailed: 'सबमिट भागीदारी विफल!',
    minlimitamountfailed: 'विनिमय राशि न्यूनतम विनिमय राशि से कम नहीं हो सकती!',
    maxlimitamountfailed: 'विनिमय राशि अधिकतम खरीद सीमा से अधिक नहीं हो सकती!',
    limittimesfailed:
      'जितनी बार आपने भाग लिया है, वह व्यक्तिगत खरीद सीमा की अधिकतम संख्या को पार कर गया है!',
    balancenotenough: 'अपर्याप्त शेष!',
    headertitledesc:
      'निवेशकों, प्रोजेक्ट पार्टियों, एक्सचेंजों और अन्य पार्टियों को एक साथ जीतने के लिए एक खुली प्रयोगशाला',
    currentdivided: 'वर्तमान में मेरी स्थिति को अनुबंधों में विभाजित किया जा सकता है',
    leveloneCount: 'पहले स्तर के दोस्तों की आवश्यकता है',
    submit: 'प्रस्तुत',
    tipsmobile: 'कृपया भाग लेने के लिए कंप्यूटर टर्मिनल पेज दर्ज करें (WWW.EPBITCOIN.COM)',
    tipsmobile1:
      'कृपया कंप्यूटर की तरफ (WWW.EPBITCOIN.COM) खोलें और भाग लेने के लिए नवाचार प्रयोग क्षेत्र में प्रवेश करें',
    intvalue: 'कृपया एक पूर्णांक दर्ज करें',
    inputminingamount: 'खरीदे गए खनिकों की संख्या दर्ज करें',
    moreactivity: 'अधिक अन्य गतिविधियां',
    releaseType: 'रिलीज़ प्रकार',
    releaseType0: 'समान राशि जारी',
    releaseType1: 'आनुपातिक रिलीज',
    lock: 'अवधि रिलीज',
    releaseDetail: 'रिलीज़ विवरण',
    lockday: ' दिन',
    lockweek: ' सप्ताह',
    lockmonth: ' चांद',
    lockyear: ' वर्ष',
    releasePercent: 'रिलीज़ अनुपात',
    lockFee: 'लॉकिंग दहलीज',
    releaseTimes: 'रिलीज़ मल्टीपल',
    times: 'समय',
  },
  invite: {
    inviteLoginName: 'साझेदार के रूप में लॉगइन करें',
    chengwei: "जीवन के लिए घरानी सेवा में लौटाने के लिए सबसे उच्च अवसर प्राप्त करें'",
    pthhr: 'प्लेटफ़ॉर्म साझीदार बनो',
    partnerText:
      'दोस्तों को अपने वास्तविक नामों के साथ रेजिस्टर करने और प्रमाणीकरण करने के लिए प्लेटफ़र्म डिविडेंड के साथ आ',
    myinvitelink: 'मेरा आमंत्रण लिंक',
    copy: 'प्रतिलिपि',
    copyed: 'प्रतिलिपि',
    mylevelone: 'माई फर्स्ट क्लास फ्रेंड',
    myleveltwo: 'मेरे दूसरे स्तर के दोस्त',
    mycommission: 'कमीशन आय (यूएसडीटी समकक्ष)',
    extrareward: 'अतिरिक्त बोनस',
    partnerlevel: 'साझेदारी स्तर',
    ruledetail: 'नियम विवरण',
    ruleprofile1: 'EPBTC (WWW.EPBITCOIN.COM) [प्रमोशन पार्टनर] पूरा नेटवर्क है',
    ruleprofile2: 'छूट का उच्चतम प्रतिशत',
    ruleprofile3: 'सबसे लंबी छूट अवधि (अधिकतम आजीवन छूट)',
    ruleprofile4:
      'प्रचार गतिविधियां, ताकि प्रमोटर जिन्होंने संबंधित प्रयास किए हैं, सही मायने में प्लेटफॉर्म `पार्टनर` बन सकते हैं, और ईपीबीटीसी (WWW.EPBITCOIN.COM) ट्रेडिंग प्लेटफॉर्म के साथ मिलकर बढ़ने के बोनस का आनंद ले सकते हैं! विशिष्ट नियम इस प्रकार हैं :',
    ruletext1:
      '1. डिजिटल संपत्ति की अवधारणा को बढ़ावा देने और उपयोगकर्ताओं के पैमाने का विस्तार करने के लिए, EPBTC (WWW.EPBITCOIN.COM) ने [प्रमोशन पार्टनर] प्रोग्राम लॉन्च किया है, जो लंबे समय तक प्रभावी है।',
    ruletext2:
      'दो। मित्रों को आमंत्रित करने के दो स्तर हैं। यदि A, B को आमंत्रित करता है, तो B, A का प्रथम-स्तर का मित्र है। यदि B, C को आमंत्रित करता है, तो C, B के प्रथम-स्तर के मित्र से संबंधित है, और A के दूसरे-स्तर के मित्र से भी संबंधित है। ',
    ruletext3:
      '3. प्रचारित मित्र को पंजीकृत करते समय, आपको आमंत्रणकर्ता द्वारा प्रदान किए गए लिंक का उपयोग करना होगा या आमंत्रणकर्ता का प्रथम-स्तरीय मित्र बनने के लिए मैन्युअल रूप से आमंत्रणकर्ता का आमंत्रण कोड दर्ज करना होगा।',
    ruletext4:
      'चार। आमंत्रणकर्ता के आमंत्रण कोड के साथ पंजीकृत होने और वास्तविक नाम प्रमाणीकरण पूरा करने के बाद, छूट प्रोत्साहन केवल प्रभावी हो सकते हैं।',
    ruletext5:
      'पांचवां, कमीशन छूट का समय उस समय से एन महीने है जब आमंत्रित व्यक्ति वास्तविक नाम प्रमाणीकरण पूरा करता है। स्तर के आधार पर, कमीशन छूट प्रतिशत अलग होता है।',
    ruletext6:
      'छह। प्रचार छूट संपत्ति निचले स्तर के मित्र की मुद्रा का लेनदेन शुल्क है, और छूट मुद्रा तीन बुनियादी बाजार मुद्राएं हैं, अर्थात्: यूएसडीटी, बीटीसी, ईटीएच।',
    ruletext7:
      'सात। प्रचार छूट अनुपात और छूट अवधि सकारात्मक रूप से पदोन्नति में प्रथम स्तर के दोस्तों की संख्या के साथ सहसंबद्ध हैं। प्रमोटरों की संख्या जितनी अधिक होगी, छूट का प्रतिशत उतना ही अधिक होगा। विशिष्ट पदोन्नति छूट अनुपात इस प्रकार है :',
    ruletext8:
      '8. छूट रैंकिंग पर TOP10 या TOP100 का प्रचार कभी-कभी ईपीबीटीसी (WWW.EPBITCOIN.COM) द्वारा आधिकारिक तौर पर जारी किए गए अतिरिक्त पुरस्कार प्राप्त करेगा।',
    ruletext9:
      '9. यदि अन्य गतिविधियां [प्रमोशन पार्टनर] गतिविधियों के साथ संघर्ष करती हैं, तो बातचीत के बाद यह तय किया जाएगा कि इसे अस्थायी रूप से बदलना है या नहीं। मुझे उम्मीद है कि भागीदार समझेंगे।',
    ruletext10:
      'Ten, L4 (गवर्नर), L5 (प्रीफेक्ट), L6 (Jie Du Shi) लेवल पार्टनर कुल वार्षिक कमीशन छूट का 5%, 10% और 15% का आनंद लेते हैं।',
    ruletext11: '11. इस घटना की अंतिम व्याख्या का अधिकार ईपीबीटीसी (WWW.EPBITCOIN.COM) का है।',
    ruleexampletitle: 'उदाहरण के लिए:',
    ruleexamplecontent1:
      'यदि उपयोगकर्ता Xiaoyan ने 100 प्रथम-स्तरीय मित्रों को आमंत्रित किया है, और TA के प्रथम-स्तर के मित्रों ने 5 द्वितीय-स्तरीय मित्रों को आमंत्रित किया है, तो Xiaoyan के पास 100 प्रथम-स्तरीय मित्र और 500 द्वितीय-स्तर के मित्र होंगे। यदि औसत व्यक्ति प्रति दिन A का लेन-देन करता है। ३,००० युआन, तो Xiaoyan की मासिक आय लगभग है: (१००*३०००*0.001*30% + 500*3000*0.001*10%) * 30 = 7200/माह।',
    ruleexamplecontent2:
      'यदि उपयोगकर्ता Xiaoyan ने 1,000 प्रथम-स्तरीय मित्रों को आमंत्रित किया है, और TA के प्रथम-स्तर के मित्रों ने 5 द्वितीय-स्तरीय मित्रों को आमंत्रित किया है, तो Xiaoyan के पास 1,000 प्रथम-स्तरीय मित्र और 5,000 द्वितीय-स्तर के मित्र होंगे। यदि औसत व्यक्ति प्रति दिन A का लेन-देन करता है। ३,००० युआन, तो जिओयान की मासिक आय लगभग है: (१०००*३०००*0.001*50% + 5000*3000*0.001*20%) * 30 = 135000/माह।',
    lastupdate: 'अंतिम बार अद्यतन किया गया',
    laststastic: 'सांख्यिकी समय सीमा',
    level: 'स्तर',
    lv1: 'लिझेंग',
    lv2: 'काउंटी ऑर्डर',
    lv3: 'प्रीफेक्ट',
    lv4: 'गवर्नर',
    lv5: 'प्रीफेक्ट',
    lv6: 'जियांदु शि',
    levelonecount: 'प्रथम स्तर के मित्रों की संख्या',
    levelonecommission: 'फर्स्ट-लेवल फ्रेंड्स रिबेट रेश्यो / रिबेट टाइम',
    leveltwocommission: 'द्वितीय स्तर के मित्र छूट अनुपात / छूट समय',
    partnerdivide: 'पार्टनर डिविडेंड',
    day: 'आकाश',
    alllife: 'आजीवन छूट',
    totalcommissionrank: 'कुल प्रचार छूट',
    rank: 'रैंकिंग',
    userid: 'यूज़र आईडी',
    rankday: '(日榜)',
    rankweek: '(周榜)',
    rankmonth: '(माह सूची)',
    rankall: '(समग्र सूची)',
    promotioncount: 'प्रचार की संख्या',
    commissionusdt: 'छूट (यूएसडीटी समकक्ष)',
    ranktip1:
      'आमंत्रणों की संख्या प्रथम स्तर के मित्रों की संख्या है, और यह सूची छूट की कुल राशि की रैंकिंग है',
    ranktip2:
      'आमंत्रणों की संख्या प्रथम-स्तरीय मित्रों की संख्या है, और यह सूची प्रथम-स्तरीय आमंत्रण संख्या रैंकिंग है',
    thanks: 'EPBTC (WWW.EPBITCOIN.COM) के साथ बड़े होने के लिए धन्यवाद!',
    headertip:
      'उपरोक्त कमीशन डेटा वास्तविक समय में अपडेट नहीं होता है, सिस्टम हर 24 घंटे में एक बार गिनता और अपडेट करता है।',
    ptools: 'प्रमोशन टूलबॉक्स',
    pt_title: 'डिजिटल करेंसी गिफ्ट कार्ड',
    pt_desc:
      'रिडीम करने के बाद, यह स्वचालित रूप से निचले स्तर का दोस्त बन जाएगा, दोस्तों, रिश्तेदारों, ग्राहकों और भागीदारों को भेज रहा है। प्रचार भागीदारों को बेहतर बढ़ावा देने में मदद करना।',
    pt_more: 'अधिक उपकरण, बने रहें',
    pt_tips:
      'यदि आपके पास कोई अच्छा विचार है, तो कृपया बढ़ावा देने के लिए एक ईमेल भेजें@NTLEX.COM, गोद लेने के बाद पुरस्कार मिलेगा!',
    pt_card_amount: 'कार्ड राशि',
    pt_card_deadline: 'वैध समय',
    pt_card_noend: 'अनिश्चित काल के लिए',
    pt_card_btn: 'प्रमोशन पार्टनर्स को 30 मुफ्त टिकट मिलते हैं',
    pt_card_btn1: 'प्रमोशन पार्टनर फ्री मिलते हैं',
    pt_card_btn2: '',
    pt_card_btn_login: 'लॉगिन के बाद 30 मुफ्त टिकट प्राप्त करें',
    pt_card_rule: 'नियम विवरण',
    pt_card_summary:
      'डिजिटल मुद्रा उपहार कार्ड प्रचार भागीदारों में से एक है जो प्रचार भागीदारों को ऑफ़लाइन मित्रों को बेहतर तरीके से आमंत्रित करने की अनुमति देने के लिए विकसित किया गया है। जब प्रचार लक्ष्य उपयोगकर्ता उपहार कार्ड को भुनाते हैं, तो वे स्वचालित रूप से प्रमोटर के ऑफ़लाइन मित्र बन जाएंगे।',
    pt_card_rule1:
      '1। मुफ्त उपहार कार्ड प्रति उपयोगकर्ता 30 तक सीमित हैं। यदि आपको अधिक उपहार कार्ड जारी करने की आवश्यकता है, तो आपको अनुकूलन के लिए भुगतान करना होगा, और आप कार्ड की अंकित राशि, लोगो, विवरण पाठ, आदि को अनुकूलित कर सकते हैं। कृपया एक ईमेल भेजें ',
    pt_card_rule2:
      'दो। मुफ्त उपहार कार्ड प्रति उपयोगकर्ता 1 तक सीमित हैं। ईपीबीटीसी (WWW.EPBITCOIN.COM) द्वारा आधिकारिक तौर पर जारी किया गया मुफ्त उपहार कार्ड प्रति उपयोगकर्ता एक तक सीमित है, भले ही अलग-अलग उपयोगकर्ता अलग-अलग मोचन कोड के साथ मुफ्त उपहार प्राप्त करें। कार्ड कर सकते हैं केवल 1 प्रति के बदले बदले जा सकते हैं। कस्टम-निर्मित उपहार कार्ड इस प्रतिबंध के अधीन नहीं हैं।',
    pt_card_rule3:
      '3. उपयोगकर्ता द्वारा उपहार कार्ड को भुनाने से पहले, यदि कोई `आमंत्रण` नहीं है (अर्थात, खाता पंजीकृत करते समय आमंत्रण कोड दर्ज नहीं किया गया है), तो यह स्वचालित रूप से कार्ड जारीकर्ता के प्रथम-स्तरीय मित्र के रूप में संबद्ध हो जाएगा अगर ए खाता पंजीकृत करते समय निमंत्रण कोड दर्ज नहीं करता है, तो उस समय, ए किसी के पहले स्तर या दूसरे स्तर के मित्र से संबंधित नहीं होता है, लेकिन जब ए बी द्वारा जारी उपहार कार्ड का आदान-प्रदान करता है, तो ए स्वचालित रूप से बी बन जाता है प्रथम स्तर का मित्र।',
    pt_card_rule4:
      'चार। ग्राहकों को स्वाइप होने से बचाने के लिए, उपहार कार्ड के बुक वैल्यू को वास्तविक समय में रिडीम नहीं किया जाता है। इसे प्राप्त करने के बाद आपको वास्तविक नाम प्रमाणीकरण पूरा करना होगा।',
    pt_card_rule5:
      'पांचवां, जब आप मुफ्त उपहार कार्ड प्राप्त करते हैं तो आपको वास्तविक नाम सत्यापन पूरा करने की आवश्यकता होती है, और जब आप इसे भुनाते हैं तो आप वास्तविक नाम सत्यापन को पूरा किए बिना इसे प्राप्त कर सकते हैं। यह प्रमोटरों को निचले स्तर के दोस्तों को तेजी से प्राप्त करने की सुविधा प्रदान करता है। ।',
    pt_card_rule6:
      'VI. मुफ्त उपहार कार्ड की अंतिम व्याख्या का अधिकार WWW.EPBITCOIN.COM से संबंधित है।',
    pt_card_day: 'आकाश',
    pt_card_title_tips: '2,000 युआन प्रोत्साहन सहायता कोष प्राप्त करें',
    pt_card_title_tips1: 'गले का पट्टा', // 新增
    pt_card_title_tips2: 'प्रोत्साहन अनुदान', // 新增
    pt_card_receivew_success:
      'बधाई हो! पार्टनर प्रमोशन गिफ्ट कार्ड सफलतापूर्वक प्राप्त हुआ! देखने के लिए कृपया [व्यक्तिगत केंद्र] -> [कार्ड और कूपन केंद्र] पर जाएं!',
    usepromotion: 'प्रचार सामग्री का प्रयोग करें',
    saveimage: 'पिक्चर को सेव करना',
    imagetips:
      'यदि चित्र सामान्य रूप से डाउनलोड नहीं किया जा सकता है, तो कृपया इसे सहेजने के लिए स्क्रीनशॉट टूल का उपयोग करें!',
    pt_invite_title: 'प्रचारात्मक छवि पंजीकृत करने के लिए आमंत्रण',
    pt_invite_desc:
      'अनन्य क्यूआर कोड, क्यूआर कोड पंजीकरण के माध्यम से स्वचालित रूप से निचले स्तर के मित्र बन जाते हैं, जो विभिन्न समूहों या मंचों में अग्रेषण के लिए उपयुक्त होते हैं।',
    colum_text0: 'रैंकिंग',
    colum_text1: 'सदस्य',
    colum_text2: 'प्रचार की संख्या',
    colum_text3: 'छूट (यूएसडीटी समकक्ष)',
    colum_text4: 'अतिरिक्त बोनस',
    context_title: 'प्रमोशन पार्टनर्स के लिए एक्सक्लूसिव रिडेम्पशन कोड',
    context_title1: 'अधिक जानने के लिए स्कैन करें',
    context_title2: 'मेरा निमंत्रण कोड',
    context_title3: 'आमंत्रित तस्वीर',
  },
  uc: {
    retrieveprivate: {
      findText: 'खाता की निजी कुंजी प्राप्त करें',
      inputFindtext: 'इस खाता के लिए बाइन्ड इमेल भरें',
      continue: 'जारी राखें',
    },
    verify: 'सत्यापित',
    login: {
      welcomelogintxt: 'NTL में स्वागत है!',
      welcomelogintxt2: "यदि तुम्हारे पास खाता नहीं है'",
      welcomelogintxt3: 'आप यहाँ क्लिक कर सकते हैं',
      welcomelogintxt4: 'रेजिस्टार्ड खाता',
      welcomelogintxt5: 'Compte de connexion',
      welcomelogintxt6: 'यदि तुम्हारे पास खाता है',
      inputPrivate: 'कृपया खाता की निजी कुंजी भरें',
      forgetPrivateTxt: 'कि खाता की निजी कुंजी भूल गयी?',
      phoneEmail: 'मोबाइल फोन या इमेल के द्वारा लॉगइन करें,',
      anonymousLogin: "अनामिक लॉगइन'",
      noaccount: 'खाता नहीं? ',
      register: 'रजिस्टर करें',
      login: 'लॉग इन करें',
      welcomelogin: 'लॉगिन कृपया',
      usertip: 'कृपया अपना फोन नंबर या ईमेल दर्ज करें',
      pwdtip: 'कृपया पासवर्ड दर्ज करें',
      validatecodeload: 'सत्यापन कोड लोड हो रहा है',
      validatemsg: 'कृपया पहले सत्यापन पूरा करें',
      forget: 'पासवर्ड भूल गए?',
      loginvalidate: 'कृपया उपयोगकर्ता नाम दर्ज करें',
      pwdvalidate1: 'कृपया पासवर्ड दर्ज करें',
      pwdvalidate2: 'पासवर्ड की लंबाई 6 अंकों से कम नहीं हो सकती',
      success: 'लॉग इन सफल',
      goregister: 'अभी साइनअप करें',
      getlostpwd: 'पासवर्ड भूल जाओ',
    },
    forget: {
      title: 'पासवर्ड पुनः प्राप्त करें',
      hasaccount: 'क्या आपके पास पहले से एक खाता मौजूद है? लॉग इन करने के लिए कृपया क्लिक करें',
      login: 'लॉग इन करें',
      sendcode: 'सत्यापन कोड भेजें',
      newpwd: 'कृपया एक नया पासवर्ड दर्ज करें',
      confirmpwd: 'कृपया फिर से पासवर्ड की पुष्टि करें',
      save: 'प्रस्तुत',
      pwdvalidate1: 'कृपया पुष्टिकरण पासवर्ड दर्ज करें',
      pwdvalidate2: 'दो पासवर्ड प्रविष्टियां असंगत हैं!',
      resettelpwd: 'फ़ोन पासवर्ड रीसेट करें',
      resetemailpwd: 'ईमेल पासवर्ड रीसेट करें',
      newpwdtip: 'कृपया एक नया पासवर्ड दर्ज करें',
      pwdvalidate3: 'पासवर्ड की लंबाई 6 अंकों से कम नहीं हो सकती',
      telno: 'फ़ोन नंबर',
      smscode: 'कृपया सत्यापन कोड दर्ज करें',
      teltip: 'कृपया फोन नंबर दर्ज करें',
      smscodetip: 'कृपया एसएमएस सत्यापन कोड दर्ज करें',
      email: 'मेल',
      emailcode: 'ई-मेल सत्यापन कोड',
      emailtip: 'कृपया अपना ईमेल इनपुट करें',
      emailcodetip: 'कृपया ईमेल सत्यापन कोड दर्ज करें',
      resetpwdsuccess: 'पासवर्ड सफलतापूर्वक रीसेट',
      smswarn: 'कृपया एसएमएस चेक करने पर ध्यान दें',
      bangding: 'कोड बाइंडिंग स्कैन करें',
      googleAuth: 'गूगल प्रमाणीकरण',
      pleaseGoogleAuth: 'बाइंडिंग गूगल प्रमाणीकरण',
      bindGoogleAuth: 'सीमा गूगल प्रमाणीकरण',
      jcbind: 'Unbind',
    },
    finance: {
      center: 'वित्तीय केन्द्र',
      personalassets: 'मुद्रा खाता',
      swapassets: 'सदा खाता',
      billdetail: 'एसेट फ्लो',
      tradetail: 'लेनदेन खनन',
      paydividends: 'मुद्रा लाभांश पकड़ो',
      invitingmining: 'खनन पुरस्कार आमंत्रित करें',
      charge: 'रिचार्ज',
      pickup: 'निकासी',
      money: {
        cointype: 'मुद्रा का नाम',
        balance: 'उपलब्ध संपत्ति',
        frozen: 'फ्रीज एसेट्स',
        operate: 'संचालन',
        charge: 'रिचार्ज',
        pickup: 'निकासी',
        onkeytrans: 'बारी करने के लिए एक कुंजी',
        getaddress: 'पता प्राप्त करें',
        resetsuccess: 'सफल हो जाओ',
        match: 'जोड़ा',
        matchtip1: 'युग्मित करने योग्य GCX की संख्या',
        matchtip2: 'कृपया जोड़े की संख्या दर्ज करें',
        matcherr1: 'कृपया एक मान्य मात्रा दर्ज करें!',
        matcherr2: 'जोड़ों की अधिकतम संख्या पार हो गई!',
        matchsuccess: 'जोड़ना सफल रहा!',
        needreleased: 'जारी की जाने वाली संपत्ति',
        totalassets: 'कुल संपत्ति समतुल्य:',
      },
      swap: {
        totalassets: 'खाता इक्विटी रूपांतरण:',
        swap: 'सदा खाता',
        swaptype: 'अनुबंध खाता',
        swapassets: 'खाता इक्विटी',
        avabalance: 'उपलब्ध गुंजाइश',
        usebalance: 'प्रयुक्त मार्जिन',
        frozenbalance: 'फ्रीज मार्जिन',
        profitandloss: 'अप्राप्त लाभ और हानि',
        transfor: 'पैसा भेजना',
        inputtransferamount: 'स्थानांतरण राशि',
        all: 'सभी को स्थानांतरित करें',
        currencyaccount: 'मुद्रा खाता',
        swapaccount: 'सदा अनुबंध खाता',
        clickchange: 'स्वाइप दिशा बदलने के लिए क्लिक करें',
        avaamount: 'हस्तांतरणीय राशि',
        pleaseselectwallet: 'कृपया पहले खाते में/बाहर स्थानांतरण का चयन करें',
        pleaseinputamount: 'कृपया हस्तांतरण राशि दर्ज करें',
        oktransfer: 'स्थानांतरण की पुष्टि करें',
      },
      second: {
        secondaccount: 'दूसरा अनुबंध खाता',
        swap: 'दूसरा अनुबंध खाता',
      },
      finance: {
        transfer: 'वित्तीय प्रबंधन हस्तांतरण में',
        account: 'वित्तीय प्रबंधन खाता',
        balance: 'संतुलन',
        earn: 'संचित ब्याज',
        rate: 'ब्याज दर',
        confirm: 'स्थानांतरण की पुष्टि करें',
        transferAmount: 'मात्रा में स्थानांतरण',
        all: 'सभी में स्थानांतरित करें',
        coin: 'मुद्रा',
        open: 'स्थिति रिकॉर्ड',
        close: 'बंद स्थिति रिकॉर्ड',
        fail: 'कार्रवाई विफल',
        success: 'सफल ऑपरेशन',
        updateTime: 'समय सुधारें',
        financialManagement: 'वित्तीय प्रबंधन',
        financialOrder: 'धन प्रबंधन आदेश',
      },
      record: {
        start_end: 'प्रारंभ और समाप्ति समय',
        to: 'प्रति',
        code: 'कूट बनाना',
        remark: 'कहना',
        operatetype: 'ऑपरेशन प्रकार',
        search: 'खोज',
        charge: 'रिचार्ज',
        pickup: 'निकालना',
        transaccount: 'स्थानांतरण',
        exchange: 'सिक्का मुद्रा लेनदेन',
        otcbuy: 'फिएट करेंसी बाय',
        otcsell: 'फिएट करेंसी बिकी',
        activityaward: 'गतिविधि पुरस्कार',
        promotionaward: 'पदोन्नति इनाम',
        dividend: 'लाभांश',
        vote: 'वोट',
        handrecharge: 'मैनुअल रिचार्ज',
        match: 'जोड़ा',
        activitybuy: 'गतिविधि एक्सचेंज',
        ctcbuy: 'सीटीसी खरीदें',
        ctcsell: 'सीटीसी बेचना',
        redout: 'लाल लिफाफा',
        redin: 'कॉलर लाल लिफाफा',
        chargetime: 'लेनदेन घंटे',
        type: 'प्रकार',
        fee: 'संचालन शुल्क',
        shouldfee: 'हैंडलिंग फीस देय',
        discountfee: 'हैंडलिंग शुल्क में कटौती',
        realfee: 'भुगतान शुल्क',
        symbol: 'मुद्रा',
        num: 'मात्रा',
        status: 'राज्य',
        finish: 'पूरा किया हुआ',
        activitybuy: 'गतिविधि एक्सचेंज',
        ctcbuy: 'C2C ख़रीदना',
        ctcsell: 'C2C सेल',
        quickExchange: 'फ्लैश मोचन',
        currentRate: 'वर्तमान विनिमय दर',
        from: 'से',
        inputexchangeamount: 'फ्लैश राशि दर्ज करें',
        inputexchangepasswd: 'लेनदेन पासवर्ड दर्ज करें',
        chooseTransCoinUnit: 'रूपांतरण मुद्रा चुनें',
        inputTransAmount: 'रूपांतरणों की संख्या दर्ज करें',
        predictAmount: 'अनुमानित विनिमेय मात्रा',
        qamount: 'फ्लैश राशि',
        qrate: 'विनिमय अनुपात',
        qexamount: 'विनिमय मात्रा',
        withdrawcodein: 'आहरण कोड और जमा',
        withdrawcodeout: 'निकासी कोड निकासी',
        contractfee: 'सतत अनुबंध हैंडलिंग शुल्क',
        contractprofit: 'स्थायी अनुबंध लाभ',
        contractloss: 'स्थायी अनुबंध हानि',
        optionfail: 'पूर्वानुमान अनुबंध विफलता',
        optionfee: 'पूर्वानुमान अनुबंध शुल्क',
        optionreward: 'पूर्वानुमान अनुबंध बोनस',
        secondfail: 'दूसरा अनुबंध विफल',
        secondreward: 'दूसरा अनुबंध बोनस',
        financereward: 'वित्तीय प्रबंधन हित',
        other: 'अन्य',
      },
      paydividende: {
        money_holding: 'संचयी मुद्रा लाभांश लौटा दिया गया है (ETH):',
        money_hold: 'मुद्रा लाभांश धारण करने का संचयी जो लौटाया जाना है (ETH):',
        paydividends: 'होल्ड करेंसी डिविडेंड (ETH)',
        account_date: 'आगमन समय',
        datehodld: 'मुद्रा धारण करने की तिथि',
        start_end: 'प्रारंभ और समाप्ति समय',
        to: 'प्रति',
        operatetype: 'ऑपरेशन प्रकार',
        search: 'खोज',
        charge: 'रिचार्ज',
        pickup: 'निकालना',
        transaccount: 'स्थानांतरण',
        exchange: 'सिक्का मुद्रा लेनदेन',
        otcbuy: 'फिएट करेंसी बाय',
        otcsell: 'फिएट करेंसी बिकी',
        activityaward: 'गतिविधि पुरस्कार',
        promotionaward: 'पदोन्नति इनाम',
        dividend: 'लाभांश',
        vote: 'वोट',
        handrecharge: 'मैनुअल रिचार्ज',
        match: 'जोड़ा',
        chargetime: 'लेनदेन घंटे',
        type: 'प्रकार',
        fee: 'संचालन शुल्क',
        symbol: 'मुद्रा',
        num: 'मात्रा',
        status: 'राज्य',
        finish: 'पूरा किया हुआ',
      },
      trade: {
        accumulative_return: 'संचयी खनन (बीएचबी):',
        accumulat_return: 'खनन किया जाना (बीएचबी):',
        start_end: 'प्रारंभ और समाप्ति समय',
        account_date: 'आगमन समय',
        to: 'प्रति',
        operatetype: 'ऑपरेशन प्रकार',
        search: 'खोज',
        charge: 'रिचार्ज',
        pickup: 'निकालना',
        transaccount: 'स्थानांतरण',
        exchange: 'सिक्का मुद्रा लेनदेन',
        otcbuy: 'फिएट करेंसी बाय',
        otcsell: 'फिएट करेंसी बिकी',
        activityaward: 'गतिविधि पुरस्कार',
        promotionaward: 'पदोन्नति इनाम',
        dividend: 'लाभांश',
        vote: 'वोट',
        handrecharge: 'मैनुअल रिचार्ज',
        match: 'जोड़ा',
        chargetime: 'ऑर्डर जनरेशन टाइम',
        type: 'प्रकार',
        fee: 'खनन शुल्क वापसी (बीएचबी)',
        num: 'मात्रा',
        status: 'राज्य',
        finish: 'पूरा किया हुआ',
        transactionTime: 'लेनदेन घंटे',
        symbol: 'ट्रेडिंग जोड़ी',
        direction: 'व्यापार दिशा',
        price: 'कीमत',
        entrustment: 'सौंपा राशि',
        havedeal: 'सौदा पूर्ण',
        poundageAmount: 'संचालन शुल्क',
        exchangeOrderId: 'आदेश ID',
        mineAmount: 'खनन किए जाने वाले सिक्कों की संख्या (बीएचबी)',
        buy: 'खरीदना',
        sell: 'बेचना',
        limit: 'सीमा मूल्य',
        market: 'बाजार मूल्य',
        clear: 'खाली हालत',
        turnover: 'कारोबार',
      },
      inviting: {
        accumulative_return: 'निमंत्रण खनन पुरस्कार वापस कर दिया गया है (बीएचबी):',
        accumulat_return: 'निमंत्रण खनन पुरस्कार संचयी (बीएचबी) लौटाए जाएंगे:',
        start_end: 'प्रारंभ और समाप्ति समय',
        account_date: 'आगमन समय',
        to: 'प्रति',
        operatetype: 'ऑपरेशन प्रकार',
        search: 'खोज',
        charge: 'रिचार्ज',
        pickup: 'निकालना',
        transaccount: 'स्थानांतरण',
        exchange: 'सिक्का मुद्रा लेनदेन',
        otcbuy: 'फिएट करेंसी बाय',
        otcsell: 'फिएट करेंसी बिकी',
        activityaward: 'गतिविधि पुरस्कार',
        promotionaward: 'पदोन्नति इनाम',
        dividend: 'लाभांश',
        vote: 'वोट',
        handrecharge: 'मैनुअल रिचार्ज',
        match: 'जोड़ा',
        chargetime: 'ऑर्डर जनरेशन टाइम',
        type: 'प्रकार',
        fee: 'खनन शुल्क वापसी (बीएचबी)',
        symbol: 'मुद्रा',
        num: 'मात्रा',
        status: 'राज्य',
        finish: 'पूरा किया हुआ',
        refereename: 'अनुशंसित व्यक्ति का नाम',
        referetel: 'अनुशंसित व्यक्ति का मोबाइल फोन नंबर',
        invitingawards: 'निमंत्रण खनन इनाम (बीएचबी)',
        refere: 'रेफरी',
        refereinput: 'कृपया अपना नाम/फोन नंबर दर्ज करें',
      },
      recharge: {
        recharge: 'रिचार्ज',
        symbol: 'मुद्रा',
        address: 'जमा पता',
        copy: 'प्रतिलिपि',
        qrcode: 'क्यूआर कोड',
        qrcodeaddress: 'रिचार्ज एड्रेस क्यूआर कोड',
        msg1: 'उपरोक्त पते पर किसी भी गैर-मुद्रा संपत्ति को रिचार्ज न करें, अन्यथा संपत्ति की वसूली नहीं की जाएगी। ',
        msg2: 'उपरोक्त पते पर रिचार्ज करने के बाद, आपको पूरे ब्लॉकचेन नेटवर्क नोड की पुष्टि करने की आवश्यकता है। आम तौर पर, ब्लॉकचैन मुख्य नेटवर्क को 3 नेटवर्क पुष्टिकरण के बाद क्रेडिट किया जाएगा। ',
        msg3: 'न्यूनतम रिचार्ज राशि:',
        msg3_1: ', न्यूनतम राशि से कम का रिचार्ज खाते में क्रेडिट नहीं किया जाएगा।',
        msg4: 'आपका रिचार्ज पता बार-बार नहीं बदलेगा, आप रिचार्ज कर सकते हैं; अगर कोई बदलाव होता है, तो हम वेबसाइट घोषणाओं या ईमेल के माध्यम से आपको सूचित करने की पूरी कोशिश करेंगे। ',
        msg5: 'कृपया सुनिश्चित करें कि जानकारी के साथ छेड़छाड़ या लीक होने से रोकने के लिए आपका कंप्यूटर और ब्राउज़र सुरक्षित है। ',
        record: 'रिचार्ज रिकॉर्ड',
        copysuccess: 'प्रतिलिपि सफल है! ',
        copyerr: 'प्रतिलिपि विफल! कृपया मैन्युअल रूप से कॉपी करें',
        time: 'आगमन समय',
        amount: 'रिचार्ज मात्रा',
        gettingaddress: 'रीचार्ज पता मिल रहा है, कृपया प्रतीक्षा करें...',
        getaddress: 'रीचार्ज पता प्राप्त करें',
        memotips:
          'कृपया रिचार्ज करते समय आवश्यकतानुसार इस मेमो को दर्ज करें, अन्यथा आप अपनी संपत्ति खो देंगे।',
        withdrawrecharge: 'निकासी कोड रिचार्ज',
        coderechargetip: 'कृपया निकासी कोड दर्ज करें',
        rechargesuccess: 'बधाई हो! निकासी कोड सफलतापूर्वक रिचार्ज किया गया है!',
        rechargeconfirm: 'रिचार्ज कन्फर्मेशन',
        status: 'राज्य',
        protocol: 'नेटवर्क प्रोटोकॉल',
        status_0: 'विफल',
        status_1: 'प्राप्त नहीं',
        status_2: 'आकलित',
        protocolerr: 'कृपया एक अनुबंध चुनें',
        network: 'इंटरनेट',
        notaddress:
          'आपने जमा पते के लिए आवेदन नहीं किया है，जमा पता प्राप्त करने के लिए कृपया बटन पर क्लिक करें',
        rechargeDisable: 'रिचार्ज फ़ंक्शन अस्थायी रूप से अनुपलब्ध है',
      },
      withdraw: {
        pickup: 'निकासी',
        network: 'स्थानांतरण नेटवर्क',
        withdrawDisable: 'निकासी को निलंबित करें',
        addressmanager: 'आहरण पता प्रबंधन',
        withdrawbycode: 'निकासी कोड वापस लेना',
        withdrawcode: 'निकासी कोड',
        symbol: 'मुद्रा',
        address: 'निकासी का पता',
        num: 'निकासी मात्रा',
        avabalance: 'उपलब्ध शेष राशि',
        msg1: 'निकासी राशि इससे कम है',
        msg2: 'उस समय खाते में स्वचालित रूप से पहुंचें, अन्यथा मैन्युअल समीक्षा की आवश्यकता है',
        increase: 'कोटा बढ़ाएँ',
        tip1: 'अधिकतम इनपुट',
        tip11: 'दशमलव, न्यूनतम मान है',
        tip2: 'अधिकतम मूल्य है',
        numtip1: 'निकासी की संख्या दर्ज करें',
        fee: 'संचालन शुल्क',
        range: 'दायरा',
        arriamount: 'आगमन मात्रा',
        msg3: 'न्यूनतम निकासी राशि है',
        msg4: 'मुद्रा',
        msg5: 'धन की सुरक्षा सुनिश्चित करने के लिए, जब आपकी खाता सुरक्षा नीति बदलती है, पासवर्ड बदलता है, और एक नए पते का उपयोग करके निकासी, हम मैन्युअल रूप से निकासी की समीक्षा करेंगे। कृपया धैर्य रखें और कर्मचारियों द्वारा फोन या ईमेल द्वारा आपसे संपर्क करने की प्रतीक्षा करें। ',
        msg6: 'कृपया सुनिश्चित करें कि जानकारी के साथ छेड़छाड़ या लीक होने से रोकने के लिए आपका कंप्यूटर और ब्राउज़र सुरक्षित है। ',
        record: 'निकासी रिकॉर्ड',
        symboltip: 'कृपया मुद्रा चुनें',
        addresstip: 'कृपया पता भरें',
        numtip2: 'कृपया निकासी की सही संख्या भरें, न्यूनतम है',
        numtip3: 'निकासी की संख्या हैंडलिंग शुल्क से कम नहीं होनी चाहिए',
        amounttip: 'कृपया निकासी की संख्या दर्ज करें',
        feetip1: 'न्यूनतम हैंडलिंग शुल्क है',
        feetip2: 'अधिकतम मूल्य है',
        time: 'निकासी का समय',
        status: 'राज्य',
        status_1: 'प्रसंस्करण',
        status_2: 'स्थानांतरण',
        status_3: 'विफल',
        status_4: 'सफलता',
        remark: 'टिप्पणी',
        add: 'में जोड़े',
        addresslist: 'पता सूची',
        safevalidate: 'सुरक्षा सत्यापन',
        telno: 'मोबाइल फोन नंबर',
        smscode: 'मोबाइल सत्यापन कोड',
        second: 'दूसरा',
        clickget: 'पाने के लिए क्लिक करें',
        email: 'मेल',
        emailcode: 'ई-मेल सत्यापन कोड',
        save: 'सहेजें',
        delete: 'हटाएं',
        telerr: 'मोबाइल फोन नंबर गलत है',
        emailerr: 'ईमेल गलत है',
        codeerr: 'गलत सत्यापन कोड',
        remarktip: 'कृपया एक नोट दर्ज करें',
        savemsg1: 'बचाव असफल हुआ!',
        savemsg2: 'सफलतापूर्वक बचाया!',
        operate: 'संचालन',
        fundpwdtip: 'कृपया फंड पासवर्ड दर्ज करें',
        click: 'क्लिक',
        filtrate: 'फ़िल्टर करने योग्य',
        txid: 'टीएक्सआईडी',
        generateCode: 'निकासी कोड उत्पन्न करें',
        withdrawcodelist: 'आहरण कोड सूची',
        withdrawcodestatus: 'राज्य',
        submit: 'प्रस्तुत',
        generatecodesuccess: 'निकासी कोड सफलतापूर्वक जनरेट किया गया',
        code_status0: 'अप्रयुक्त',
        code_status3: 'सफलता',
        network: 'स्थानांतरण नेटवर्क',
        protocol: 'नेटवर्क प्रोटोकॉल',
      },
    },
    member: {
      usercenter: 'व्यक्तिगत केंद्र',
      securitycenter: 'सुरक्षा केंद्र',
      securitysetting: 'सुरक्षा सेटिंग्स',
      accountsetting: 'भुगतान विधि',
      account: 'खाता प्रबंधन',
      assets: 'निधि प्रबंधन',
      exchange: 'लेन - देन प्रबंधन',
      curdelegate: 'अभी का ऑर्डर',
      hisdelegate: 'ऐतिहासिक आयोग',
      otc: 'ओटीसी प्रबंधन',
    },
    order: {
      ordercenter: 'आदेश केंद्र',
      myorder: 'मेरे आदेश',
      myad: 'मेरे विज्ञापन',
    },
    regist: {
      zhanghaosiyao: 'Clé privée du compte',

      nimingtishiTxt:
        "Lors de la connexion, vous n'avez besoin que de la clé privée du compte pour vous connecter au compte. La clé privée du compte est créée au hasard localement et doit être conservée par vous - même.",

      shuruningcheng: 'Veuillez saisir votre surnom',

      anonymousLogin: 'बेनाम रिजिस्टेशन',
      fillInvitationCode: 'आमन्त्रण कोड में भरें',
      gologin: 'मौजूदा खाता, अब लॉगइन',

      hasaccount: 'क्या आपके पास पहले से एक खाता मौजूद है? लॉग इन करने के लिए कृपया क्लिक करें',
      login: 'लॉग इन करें',
      username: 'उपयोगकर्ता नाम',
      country: 'राष्ट्र',
      smscode: 'एसएमएस सत्यापन कोड',
      sendcode: 'सत्यापन कोड भेजें',
      resendcode: 'फिर से भेजें (',
      pwd: 'लॉगिन पासवर्ड',
      repwd: 'पासवर्ड की पुष्टि कीजिये',
      confrimpwd: 'पासवर्ड की पुष्टि कीजिये',
      agreement: 'मैंने पढ़ा है और इस बात से सहमत है',
      userprotocol: 'उपयोगकर्ता का समझौता',
      regist: 'रजिस्टर करें',
      teltip: 'कृपया फोन नंबर दर्ज करें',
      telerr: 'मोबाइल फोन नंबर प्रारूप गलत है, कृपया पुनः दर्ज करें',
      emailtip: 'कृपया अपना ईमेल इनपुट करें',
      emailerr: 'ईमेल प्रारूप गलत है, कृपया पुनः दर्ज करें',
      confirmpwdtip: 'कृपया पुष्टिकरण पासवर्ड दर्ज करें',
      confirmpwderr: 'दो पासवर्ड प्रविष्टियां असंगत हैं!',
      telregist: 'अपना फोन पंजीकृत करें',
      emailregist: 'ईमेल पंजीकरण',
      usernametip: 'कृपया उपयोगकर्ता नाम दर्ज करें',
      usernamemsg: 'उपयोगकर्ता नाम की लंबाई 3 अंकों से कम और 15 अंकों से अधिक नहीं हो सकती',
      countrytip: 'कृपया एक देश चुनिए',
      smscodetip: 'कृपया एसएमएस सत्यापन कोड दर्ज करें',
      pwdtip: 'अपना पासवर्ड दर्ज करें',
      pwdmsg: 'पासवर्ड की लंबाई 6 अंकों से कम नहीं हो सकती',
      telno: 'मोबाइल फोन नंबर',
      email: 'ईमेल पता',
      agreementtip: 'कृपया सहमत होने के लिए क्लिक करें',
      reloadChoice: 'कृपया फिर रिजिस्टर करें',
      modaltitle: 'कृप्या सत्यापित करें',
      promotion: 'निमंत्रण कोड वैकल्पिक',
      china: 'चीन',
      singapore: 'सिंगापुर',
      korea: 'दक्षिण कोरिया',
      japan: 'जापान',
      thailand: 'थाईलैंड',
      russia: 'रूस',
      uk: 'यूके',
      vietnam: 'वियतनाम',
      india: 'भारत',
      italy: 'इटली',
      hk: 'हॉगकॉग',
      malaysia: 'मलेशिया',
      taiwan: 'ताइवान प्रांत',
      turkey: 'तुर्की',
      germany: 'जर्मनी',
      france: 'फ्रांस',
      spain: 'स्पेन',
      us: 'अमेरिका',
      canada: 'कनाडा',
    },
    safe: {
      oldprivateKey: 'निजी कुंजी',
      privateKeytip: 'कृपया निजी कुंजी भरें',
      safelevel_low: 'सुरक्षा स्तर: निम्न',
      safelevel_high: 'सुरक्षा स्तर: उच्च',
      safelevel_medium: 'सुरक्षा स्तर: मध्यम',
      nickname: 'निक नेम',
      bind: 'बाध्यकारी',
      binded: 'बंधा हुआ',
      binding: 'समीक्षाधीन',
      binderr: 'विफल',
      bindretry: 'पुन: प्रयास करें',
      verifypass: 'सत्यापित',
      verified: 'सत्यापित',
      notverified: 'प्रमाणित नहीं',
      verifiedtip:
        'अपने खाते की सुरक्षा सुनिश्चित करने के लिए, कृपया ट्रेडिंग संचालन से पहले वास्तविक नाम प्रमाणीकरण पूरा करें! ',
      realname: 'वास्तविक नाम',
      idcard: 'आईडी नंबर',
      upload: 'अपलोड करने के लिए क्लिक करें',
      upload_positive: 'आईडी कार्ड फ्रंट फोटो',
      upload_negative: 'आईडी कार्ड रिवर्स फोटो',
      upload_hand: 'हैंडहोल्ड आईडी कार्ड और व्यक्तिगत हस्ताक्षर फोटो',
      save: 'सहेजें',
      reset: 'रीसेट',
      email: 'मेल',
      bindemail: 'बाइंड मेलबॉक्स',
      loginpwd: 'लॉगिन पासवर्ड',
      emailcode: 'ई-मेल सत्यापन कोड',
      clickget: 'पाने के लिए क्लिक करें',
      second: 'दूसरा',
      phone: 'सेल फोन',
      bindphone: 'बाइंड फोन',
      phonecode: 'मोबाइल सत्यापन कोड',
      logintip: 'प्लेटफ़ॉर्म में लॉग इन करते समय उपयोग करें',
      edit: 'संशोधन',
      oldpwd: 'मूल लॉगिन पासवर्ड',
      newpwd: 'नया लॉगिन पासवर्ड',
      confirmnewpwd: 'नए पासवर्ड की पुष्टि करें',
      fundpwd: 'फंड पासवर्ड',
      fundtip:
        'जब खाता निधि बदली जाती है, तो निधि पासवर्ड को पहले सत्यापित करने की आवश्यकता होती है',
      set: 'सेट अप',
      confirmpwd: 'पासवर्ड की पुष्टि कीजिये',
      oldfundpwd: 'मूल निधि पासवर्ड',
      newfundpwd: 'नया फंड पासवर्ड',
      newpwdmsg1: 'कृपया कम से कम 6 अंकों का नया लॉगिन पासवर्ड दर्ज करें',
      newpwdmsg2: 'नया लॉगिन पासवर्ड असंगत है',
      pwdmsg1: 'कृपया कम से कम 6 अंकों का पासवर्ड दर्ज करें',
      pwdmsg2: 'पासवर्ड असंगत हैं',
      emailtip: 'कृपया मेलबॉक्स नंबर दर्ज करें',
      codetip: 'कृपया सत्यापन कोड दर्ज करें',
      telnotip: 'कृपया फोन नंबर दर्ज करें',
      oldpwdtip: 'कृपया मूल पासवर्ड दर्ज करें',
      realnametip: 'कृपया अपना असली नाम दर्ज करें',
      idcardtip: 'कृपया आईडी नंबर दर्ज करें',
      bindphonetip: 'कृपया पहले अपना फोन बांधें!',
      resetfundpwd: 'फंड पासवर्ड रीसेट करें',
      upload_positivetip: 'कृपया आईडी कार्ड की फ्रंट फोटो अपलोड करें',
      upload_negativetip: 'कृपया आईडी कार्ड की रिवर्स फोटो अपलोड करें',
      upload_handtip: 'कृपया एक हाथ से पकड़ी गई आईडी फोटो अपलोड करें',
      save_success: 'सफलतापूर्वक बचाया!',
      save_failure: 'बचाव असफल हुआ!',
      idcard_verifymsg1:
        '1. समर्थन जेपीजी, पीएनजी प्रारूप, फ़ाइल 8M से कम है; प्रमाणपत्र पर जानकारी स्पष्ट रूप से दिखाई दे रही है, कोई संशोधन या बाधा की अनुमति नहीं है, और प्रमाणपत्र संख्या और नाम स्पष्ट रूप से देखा जाना चाहिए।',
      idcard_verifymsg2:
        '२. फोटो को बिना किसी टोपी के होना चाहिए, किसी मेकअप की सिफारिश नहीं की जाती है, आईडी रखने वाले व्यक्ति के चेहरे की विशेषताएं स्पष्ट रूप से दिखाई देती हैं, और हाथ पूरी तरह से उजागर होता है।',
      idcard_verifymsg3:
        '3. एक ही समय में प्रमाण पत्र और हस्तलिखित प्रति (नाम + आवेदन तिथि) रखना आवश्यक है। `इस साइट पर मेरे सभी कार्यों का संचालन स्वयं द्वारा किया जाता है, और मैं संबंधित जोखिमों से अवगत हूं, और मैं इसके लिए तैयार हूं इस खाते के सभी कानूनी परिणामों को सहन करें।',
    },
    account: {
      successDelete: 'मिटाना सफल',
      pagetitle: 'बाध्यकारी वास्तविक नाम खाता',
      pagetip:
        'कृपया अपनी भुगतान विधि सेट करें, कृपया अपने वास्तविक नाम वाले खाते का उपयोग करना सुनिश्चित करें',
      backcardno: 'बैंक कार्ड खाता संख्या',
      backcardtip: 'व्यक्तिगत बैंक कार्ड की जानकारी बाध्य नहीं है',
      modify: 'संशोधन',
      bind: 'बाध्यकारी',
      name: 'नाम',
      bankaccount: 'बैंक खाता',
      bankbranch: 'खाता खोलने वाली शाखा',
      bankno: 'बैंक कार्ड नंबर',
      confirmbankno: 'कार्ड नंबर की पुष्टि करें',
      fundpwd: 'फंड पासवर्ड',
      save: 'सहेजें',
      zfbaccount: 'अलीपे खाता',
      zfbaccounttip: 'व्यक्तिगत Alipay खाता बाध्य नहीं है',
      wxaccount: 'वीचैट खाता',
      wxaccounttip: 'व्यक्तिगत वीचैट खाता बाध्य नहीं है',
      banknomsg1: 'कृपया सही बैंक कार्ड नंबर दर्ज करें',
      banknomsg2: 'दो दर्ज किए गए बैंक कार्ड नंबर असंगत हैं!',
      verifiedmsg: 'कृपया पहले वास्तविक नाम प्रमाणीकरण करें',
      bankaccountmsg: 'कृपया एक खाता बैंक चुनें',
      bankbranchmsg: 'कृपया खाता खोलने वाली शाखा दर्ज करें',
      banknomsg: 'कृपया सही बैंक कार्ड नंबर दर्ज करें',
      fundpwdmsg1: 'कृपया सही फंड पासवर्ड दर्ज करें',
      fundpwdmsg2: 'पासवर्ड 6 वर्णों से कम का नहीं होना चाहिए',
      zfbaccountmsg: 'कृपया अपना Alipay खाता दर्ज करें',
      wxaccountmsg: 'कृपया WeChat खाता दर्ज करें',
      save_success: 'सफलतापूर्वक बचाया!',
      save_failure: 'बचाव असफल हुआ!',
      imgtip: 'कृपया अपना संग्रह कोड अपलोड करें',
    },
    otcorder: {
      unpaid: 'अवैतनिक',
      paided: 'भुगतान किया गया',
      finished: 'पूरा किया हुआ',
      canceled: 'रद्द',
      appealing: 'आकर्षक',
      searchtip: 'खोज शुरू करने के लिए ऑर्डर नंबर दर्ज करें',
      orderno: 'क्रम संख्या',
      created: 'समय बनाया',
      symbol: 'कारोबारी मुद्रा',
      type: 'सौदे का प्रकार',
      type_sell: 'बेचना',
      type_buy: 'खरीदना',
      tradename: 'व्यवसाय सहयोगी',
      amount: 'मात्रा',
      money: 'राशि',
      fee: 'संचालन शुल्क',
    },
    identity: {
      certified: 'सत्यापित',
      placeholder: 'कृपया रद्द करने का कारण भरें',
      apply: 'व्यवसाय बनने के लिए आवेदन करें',
      become: 'EPBTC प्रमाणित व्यापारी बनें और अधिक व्यापारिक विशेषाधिकारों का आनंद लें',
      zhusnhu: 'व्यवसायी लेन-देन की सफलता दर बढ़ाने के लिए विशेष विज्ञापन बूथों का आनंद लेते हैं',
      tijiaoziliao: 'व्यापारी प्रमाणन जानकारी सबमिट करें',
      place: 'कृपया तैयार मर्चेंट सर्टिफिकेशन जानकारी को प्लेटफॉर्म पर अपलोड करें और सबमिट करें',
      tijiao: 'आपकी व्यवसाय सत्यापन समीक्षा सबमिट कर दी गई है',
      tijiaosuc: 'बधाई हो! आपकी व्यवसाय प्रमाणन समीक्षा बीत चुकी है',
      tijiaofail: 'क्षमा करें! आपकी व्यवसाय प्रमाणन समीक्षा विफल रही',
      zhuxiaotijiao: 'आपका व्यवसाय रद्द करने का आवेदन जमा कर दिया गया है',
      shenhefail: 'आपका व्यवसाय रद्द करने के आवेदन की समीक्षा विफल',
      shenhesuc: 'आपका व्यवसाय रद्द करने के आवेदन की समीक्षा पारित कर दी गई है',
      shangjiazhuxiao: 'व्यापार रद्द करना',
      shenheshibai: 'ऑडिट विफलता',
      shenagain: 'फिर से समीक्षा',
      sheqinggain: 'फिर से आवेदन करें',
      reason: 'कारण',
      shenqingtuibao: 'आत्मसमर्पण के लिए आवेदन करें',
      getquan: 'आपके पास निम्न अनुमतियां हैं',
      read: 'मैंने पढ़ा है और इस बात से सहमत है',
      lijishenqing: 'तुरंत आवेदन करें',
      tips: 'संकेत',
      wufachexiao:
        'आप व्यवसाय को अपंजीकृत करने की प्रक्रिया में हैं। आवेदन जमा करने की पुष्टि के बाद, इसे वापस नहीं लिया जा सकता है।',
      suredo: 'क्या आप इस ऑपरेशन को करने के लिए सुनिश्चित हैं?',
      shuzizichan: 'डिजिटल एसेट ट्रांजेक्शन सर्टिफिकेट',
      gerenzichan: 'व्यक्तिगत डिजिटल संपत्ति प्रमाण',
      second: {
        line: 'व्यापारी बनने के लिए आवेदन कैसे करें?',
        step1: 'पहला कदम: आवश्यकतानुसार व्यावसायिक अनुप्रयोग सामग्री तैयार करें',
        step1c1: 'निम्नलिखित आवेदन सामग्री तैयार करें:',
        step1c2:
          'मोबाइल फोन नंबर, वीचैट आईडी, क्यूक्यू नंबर, व्यक्तिगत डिजिटल संपत्ति प्रमाणपत्र (चित्र), डिजिटल संपत्ति लेनदेन प्रमाणपत्र (चित्र)',
        step2: 'चरण 2: आवेदन जमा करें',
        step2c:
          'व्यापारी प्रमाणन समीक्षा जानकारी को पूरा करें जिसे भरने और अपलोड करने की आवश्यकता है, और समीक्षा के लिए सबमिट करें पर क्लिक करें।',
        step3: 'चरण 3: डेटा समीक्षा',
        stepc:
          'हम 3-5 कार्य दिवसों के भीतर आपकी व्यावसायिक आवेदन सामग्री की समीक्षा करेंगे। कृपया किसी भी समय समीक्षा स्थिति पर ध्यान दें, जिसे सबमिशन पृष्ठ पर जांचा जा सकता है। समीक्षा पारित होने के बाद, आप कानूनी मुद्रा में एक विज्ञापन पोस्ट कर सकते हैं व्यापार इलाका।',
        agree: 'फ्रीज करने के लिए सहमत',
        agreec: 'एक व्यापारी जमा के रूप में',
        shenqingchngweishangjia: 'व्यापारी बनने के लिए आवेदन करें',
      },
      yuanyin: 'कारण',
      tijiaoshenqing: 'आवेदन जमा करें',
      bizhong: 'मार्जिन मुद्रा',
      shuliang: 'मार्जिन मात्रा',
      chosen: 'मुद्रा चुनें',
      seat: 'एक्सक्लूसिव बूथ',
      service: 'एक-से-एक सेवा',
      lowfee: 'कम हैंडलिंग शुल्क',
      phone: 'सेल फोन',
      balance: 'व्यक्तिगत संपत्ति की स्थिति',
      cardphoto: 'आईडी कार्ड आगे और पीछे की तस्वीरें',
      wx: 'वीचैट',
      exchange: 'क्या आपने कभी डिजिटल संपत्ति के काउंटर पर लेन-देन किया है',
      handphoto: 'यूजर होल्डिंग आईडी फोटो',
      qq: 'क्यूक्यू',
      ploy: 'क्या कोई संबंधित जोखिम नियंत्रण रणनीति है',
      agreement: 'प्रमाणित मर्चेंट एग्रीमेंट',
      applyfor: 'आवेदन की पुष्टि करें',
      sendcode: 'सत्यापन कोड भेजें',
      confirm: 'ज़रूर',
      prepare: 'सामग्री तैयार करें',
      review: 'समीक्षा जमा करें',
      result: 'परिणामों की प्रतीक्षा में',
      passed: 'परीक्षा उत्तीर्ण',
      approve: 'कृपया प्रमाणीकरण व्यापारी समझौते से सहमत हों',
      emailtip1: 'कृपया निम्नलिखित सामग्री को ईमेल करें',
      emailtip2: 'हम जल्द से जल्द आपके आवेदन की समीक्षा करेंगे। ',
    },
    extension: {
      title1: 'प्रायोजित लिंक्स',
      title2: 'दोस्तों को बढ़ावा देना',
      title3: 'मेरा कमीशन',
      linkdesc:
        'निम्नलिखित यूआरएल बाहरी दुनिया में प्रचार के लिए आपका पता है। आप इसे दोस्तों, क्यूक्यू, वीचैट, वीबो, ब्लॉग, फ़ोरम या अपनी वेबसाइट के माध्यम से प्रचारित कर सकते हैं। इस पते के माध्यम से आने वाले सभी लोग पंजीकरण के बाद आपके होंगे। उपयोगकर्ता , और जब ये उपयोगकर्ता इस साइट पर रणनीतियां सबमिट करते हैं, तो आप कमीशन कमा सकते हैं। विस्तृत प्रचार स्थिति को विज़िट रिकॉर्ड में देखा जा सकता है। ',
      linktitle: 'आपका प्रचार लिंक',
      copy: 'प्रतिलिपि',
      copy_msg1: 'प्रतिलिपि सफल है! ',
      copy_msg2: 'प्रतिलिपि विफल! कृपया मैन्युअल रूप से कॉपी करें',
      username: 'उपयोगकर्ता नाम',
      currrecharge: 'वर्तमान लेनदेन',
      userlevel: 'सिफारिश स्तर',
      createdtime: 'पंजीकरण समय',
      currcommission: 'वर्तमान आयोग',
      managerfee: 'प्रबंधन शुल्क',
      yuan: 'युआन',
      symbol: 'मुद्रा',
      amount: 'राशि',
      remark: 'टिप्पणी',
      amounttime: 'जारी करने का समय',
    },
    promotion: {
      title: 'मेरा प्रचार',
      subtitle1: 'मेरा प्रचार कार्ड',
      subtitle2: 'एक्सचेंज रिकॉर्ड',
      subtitle3: 'मेरा निमंत्रण',
      card_column0: 'रिडेम्पशन कोड',
      card_column1: 'कार्ड का नाम',
      card_column2: 'कार्ड मुद्रा प्रकार',
      card_column3: 'कार्ड राशि',
      card_column4: 'वैधता अवधि',
      card_column5: 'कुल',
      card_column6: 'मुक्ति',
      card_column7: 'समय प्राप्त करें',
      my_column0: 'उपयोगकर्ता को आमंत्रित करें',
      my_column1: 'पंजीकरण समय',
      my_column2: 'असली नाम की स्थिति',
      gopromotion: 'प्रचार सामग्री',
      inputcardno: 'कृपया मोचन कोड दर्ज करें',
      exchange: 'अब एक्सचेंज करें',
      exchangesuccess: 'एक्सचेंज सफल है! कृपया शेष राशि की जांच के लिए परिसंपत्ति प्रबंधन पर जाएं!',
      exchangewithcode: 'रिडीम कोड रिडीम',
    },
    activity: {
      navTitle: 'नवाचार प्रबंधन',
      subNavTitle1: 'मैने भाग लिया',
      subNavTitle2: 'मेरी खनन मशीन',
      subNavTitle3: 'मेरी बंद स्थिति',
      column1: 'नाम',
      column2: 'प्रकार',
      column3: 'सदस्यता मात्रा',
      column4: 'भाग लेने वाली मुद्रा',
      column5: 'सदस्यता इकाई',
      column6: 'वर्तमान स्थिति',
      column7: 'लेन - देन की राशि',
      column8: 'समय बनाया',
      mColumn1: 'खनन मशीन',
      mColumn2: 'नाम',
      mColumn3: 'मुद्रा',
      mColumn4: 'चक्र',
      mColumn5: 'दिन',
      mColumn6: 'खोदा',
      mColumn7: 'राज्य',
      mColumn8: 'मूल क्षमता',
      mColumn9: 'वर्तमान क्षमता',
      mColumn10: 'कुल उत्पादन',
      mColumn11: 'अंतिम तिथि',
      mColumn12: 'रचना तिथि',
      lColumn1: 'ताला स्थिति',
      lColumn2: 'नाम',
      lColumn3: 'मुद्रा',
      lColumn4: 'चक्र',
      lColumn5: 'दिन',
      lColumn6: 'रिलीज़',
      lColumn7: 'राज्य',
      lColumn8: 'मूल क्षमता',
      lColumn9: 'वर्तमान क्षमता',
      lColumn10: 'कुल उत्पादन',
      lColumn11: 'अंतिम तिथि',
      lColumn12: 'रचना तिथि',
    },
    mining: {
      title: 'मेरी खनन मशीन सूची',
      empty: 'कोई खनन मशीन अस्थायी रूप से नहीं',
      day: 'आकाश',
      week: 'सप्ताह',
      month: 'चांद',
      year: 'वर्ष',
      invitetip1:
        'दोस्तों को आमंत्रित करके इस खनन मशीन की उत्पादन क्षमता को बढ़ाया जा सकता है। हर बार जब किसी को खनन मशीन के बदले में आमंत्रित किया जाता है, तो उत्पादन क्षमता बढ़ जाएगी',
      invitetip2: '%, क्षमता वृद्धि की ऊपरी सीमा',
      miningUnit: 'आउटपुट मुद्रा',
      miningPeriod: 'उत्पादन चक्र',
      miningDays: 'खनन का समय',
      miningedDays: 'समय खोदा गया है',
      miningDaysProfit: 'मूल क्षमता',
      miningCurrentDaysProfit: 'वास्तविक क्षमता',
      status0: 'तैनात करने के लिए',
      status1: 'तैनात',
      status2: 'ऊपर',
    },
    locked: {
      title: 'मेरी बंद स्थिति सूची',
      empty: 'नो लॉकअप',
      day: 'आकाश',
      week: 'सप्ताह',
      month: 'चांद',
      year: 'वर्ष',
      invitetip1:
        'इस लॉक-अप की क्षमता को मित्रों को आमंत्रित करके बढ़ाया जा सकता है, और लॉक-अप में भाग लेने के लिए आमंत्रित प्रत्येक व्यक्ति की क्षमता में वृद्धि होगी',
      invitetip2: '%, क्षमता वृद्धि की ऊपरी सीमा',
      miningUnit: 'रिलीज़ करेंसी',
      miningPeriod: 'रिलीज़ साइकिल',
      miningDays: 'कुल रिलीज अवधि',
      miningedDays: 'रिलीज़ अवधि',
      miningDaysProfit: 'मूल रिलीज',
      miningCurrentDaysProfit: 'वास्तविक रिलीज',
      status0: 'जारी होने के लिए',
      status1: 'रिहाई',
      status2: 'ऊपर',
      totallocked: 'कुल लॉकअप',
    },
    api: {
      apiAdmin: 'एपीआई प्रबंधन',
      createApi: 'एक नई एपीआई कुंजी बनाएं',
      mark: 'एपीआई नोट्स',
      bindAddress: 'अधिकृत आईपी (अधिकृत आईपी पते की प्रमुख वैध अवधि 90 दिन है)',
      hint: 'संकेत',
      part1:
        'हम आपको एक शक्तिशाली एपीआई प्रदान करते हैं, जिसके माध्यम से आप बाजार पूछताछ और स्वचालित व्यापार जैसी सेवाओं का उपयोग कर सकते हैं। पास',
      apiDoc: 'एपीआई दस्तावेज़ीकरण',
      howUse: 'देखें कि इसका उपयोग कैसे करें।',
      part2: 'प्रत्येक उपयोगकर्ता 5 एपीआई कुंजी तक बना सकता है',
      part3:
        'कृपया संपत्ति के नुकसान से बचने के लिए अपनी एपीआई कुंजी का खुलासा न करें। सुरक्षा कारणों से, एपीआई कुंजी के लिए एक आईपी को बांधने की सिफारिश की जाती है। प्रत्येक एपीआई कुंजी अधिकतम 5 आईपी के लिए बाध्य होती है। सीधे एक ही पता भरें, और उपयोग करें एकाधिक IP पतों के लिए अल्पविराम अलग, जैसे: 192.168.1.1,192.168.1.2,192.168.1.3।',
      myApiKey: 'मेरी एपीआई कुंजी',
      ipAddress: 'आईपी पता',
      createTime: 'समय बनाया',
      accessKey: 'प्रवेश की चाबी',
      bindIpAddress: 'बाइंड आईपी एड्रेस',
      ioDays: 'शेष वैधता अवधि (दिन)',
      operation: 'संचालन',
      create: 'सर्जन करना',
      edit: 'संपादित करें',
      delete: 'हटाएं',
      sureDelete: 'हटाने की पुष्टि करें',
      tips: 'कुंजी केवल तब प्रदर्शित होती है जब इसे जोड़ा जाता है, कृपया इसे समय पर सहेजें',
      copy: 'प्रतिलिपि',
      access: 'चाभी',
      okText: 'पुष्टि करना',
      cancel: 'रद्द करें',
    },
  },
  cms: {
    noticecenter: 'घोषणा केंद्र',
    newshelp: 'नए हाथ मदद',
    appdownload: 'एपीपी डाउनलोड',
    onlineservice: 'ऑनलाइन सेवा',
    faq: 'आम समस्या',
    notice: 'मुनादी करना',
    servicecenter: 'ग्राहक सेवा',
    about: 'के बारे में',
    joinus: 'हमसे जुड़ें',
    aboutus: 'हमारे बारे में',
    exchangerule: 'व्यापार नियम',
    useprotocol: 'अनुबंध का उपयोग करें',
    feenote: 'चार्ज विवरण',
    merchantprocotol: 'व्यापारी समझौता',
    features: 'प्लेटफ़ॉर्म सुविधाएँ',
    contactus: 'संपर्क करें',
    noticelist: 'घोषणा सूची',
    nextnotice: 'अगला',
    prevnotice: 'पहले का',
    notexist: 'आपके द्वारा देखी गई घोषणा मौजूद नहीं है या हटा दी गई है!',
    otherhelp: 'इस श्रेणी में लेख',
    scanforshare: 'साझा करने के लिए स्कैन करें',
    download: 'अभी डाउनलोड करें',
    downloadslogan: 'ईपीबीटीसीएपीपी-ग्लोबल डिजिटल एसेट ट्रेडिंग प्लेटफॉर्म',
  },
  description: {
    title1: 'वित्तीय ग्रेड सुरक्षा',
    title2: 'तेजी से जमा और निकासी',
    title3: 'वैश्विक सेवा',
    title4: 'कड़ाई से चयनित संपत्ति',
    message1:
      'चौतरफा वित्तीय जोखिम नियंत्रण प्रणाली और चोरी-रोधी प्रणाली, गर्म और ठंडे वॉलेट, धन की सुरक्षा सुनिश्चित करने के लिए बहु-हस्ताक्षर प्रणाली',
    message2:
      'रिचार्ज और निकासी जल्द से जल्द 3 मिनट में पूरी की जा सकती है, 24H मैन्युअल ऑनलाइन समीक्षा, ग्राहकों को निवेश के सर्वोत्तम अवसरों को खोने से बचाने के लिए',
    message3:
      'वैश्विक व्यापार सेवा नेटवर्क कवरेज आपको वैश्विक एन्क्रिप्टेड संपत्तियों में निवेश करने और वैश्विक उपयोगकर्ताओं के साथ व्यापार करने में मदद करने के लिए',
    message4:
      'उच्च गुणवत्ता वाली एन्क्रिप्शन परियोजनाओं का सख्ती से चयन करें, और आपके लिए अत्यधिक उच्च जोखिम वाली 80% परियोजनाओं को फ़िल्टर करें',
    message5:
      'EPBTC (WWW.EPBITCOIN.COM) अंतरराष्ट्रीय डिजिटल एन्क्रिप्टेड एसेट ट्रेडिंग प्लेटफॉर्म मोबाइल क्लाइंट, हैंडहेल्ड ट्रेडिंग अनुभव पर ध्यान केंद्रित करते हुए',
    scanqrcode: 'क्यूआर कोड स्कैन करें और एपीपी डाउनलोड करें',
    aboutdesc1:
      'EPBTC (WWW.EPBITCOIN.COM) दुनिया का अग्रणी डिजिटल एसेट ट्रेडिंग प्लेटफॉर्म है। यह केमैन आइलैंड्स में पंजीकृत है और इसकी कोर ऑपरेशन टीम हांगकांग में स्थित है। EPBTC (WWW.EPBITCOIN.COM) के मुख्य सदस्य आते हैं। शीर्ष इंटरनेट और वित्तीय कंपनियां। कुछ सदस्य बिटकॉइन और ब्लॉकचेन में गहरे विश्वास रखते हैं। हम आश्वस्त हैं कि ब्लॉकचेन पारंपरिक एकाधिकार वाली वित्तीय प्रणाली को बदल देगा और एक अधिक लोकतांत्रिक और स्वायत्त सामाजिक संरचना स्थापित करेगा।',
    aboutdesc2:
      'EPBTC (WWW.EPBITCOIN.COM) प्लेटफॉर्म में एक पेशेवर वित्तीय-स्तरीय लेनदेन संरचना है, और एक स्व-विकसित उच्च-संगामिति मेमोरी मिलान लेनदेन इंजन है। प्लेटफॉर्म पूरी तरह से कोल्ड रिचार्ज वॉलेट सिस्टम + मल्टी-सिग्नेचर + हाई-प्रोटेक्शन का उपयोग करता है। ग्राहक परिसंपत्ति सुरक्षा सुनिश्चित करने के लिए डीडीओएस हमला प्रणाली और अन्य लेनदेन संरचनाएं।',
    aboutdesc3:
      'EPBTC (WWW.EPBITCOIN.COM) ग्राहकों को निवेश के अवसरों को बेहतर ढंग से समझने की अनुमति देने के लिए, ग्राहक सेवा विभाग आंतरिक रूप से फ्लैट प्रबंधन को अपनाता है, और एक अत्यंत तेज़ सेवा प्रतिक्रिया तंत्र स्थापित किया है। परिसंपत्ति ग्राहक सेवा प्रबंधक पूरे समय में 7*24H ऑनलाइन हैं। ग्राहकों को परिसंपत्ति पुनर्भरण और निकासी आदि प्रदान करने के लिए वर्ष। यह सुनिश्चित करने के लिए सेवा कि ग्राहक 25 मिनट के भीतर परिसंपत्ति जमा और निकासी को पूरा करें।',
    aboutdesc4:
      'EPBTC (WWW.EPBITCOIN.COM) उच्च गुणवत्ता वाली परियोजनाओं की सख्ती से जांच करता है और सुरक्षित और स्थिर संपत्ति हिरासत सेवाएं प्रदान करता है। EPBTC (WWW.EPBITCOIN.COM) `ईमानदारी, निष्पक्षता, उत्साह और खुलेपन` की अवधारणा का पालन करता है, और प्रयास करता है एक सुरक्षित, विश्वसनीय, कुशल और मैत्रीपूर्ण अंतिम विनिमय बनाने के लिए।',
    aboutdesc5:
      'हालांकि डिजिटल संपत्ति अब केवल एक छोटे से दायरे में है, हम मानते हैं कि इस तरह की संपत्ति जिसे हर कोई पूरी तरह से नियंत्रित कर सकता है, भविष्य में लोकप्रिय होगी। आइए हम कड़ी मेहनत करें और इसके लिए तत्पर हैं!',
    aboutdesc6:
      'ntlex.com (यूनिवर्सल ज़ोन एक्सचेंज) एक DAO गवर्नेंस पर आधारित अंतर्राष्ट्रीय डिजिटल एसेट डेरिवेटिव्स ट्रेडिंग प्लेटफॉर्म है, जो वैश्विक उपयोगकर्ताओं के लिए सुरक्षित, स्थिर, सुविधाजनक, निष्पक्ष और विश्वसनीय ब्लॉकचेन एसेट ट्रेडिंग सेवाएं प्रदान करने के उद्देश्य से बनाया गया है।',
    aboutdesc7:
      'NTL DAO ने ntlex.com को केमैन फाउंडेशन को अधिकृत कर दिया है, जो पारंपरिक क्रिप्टोकरेंसी एक्सचेंज से अधिक फायदेमंद है, जो ट्रेडिंग स्टेशन की निष्पक्षता और उपयोगकर्ताओं की संपत्ति की सुरक्षा को सफलतापूर्वक सुनिश्चित करता है। प्रबंधन टीम के सदस्य वैश्विक वित्त और टेक्नोलॉजी कंपनियों Coinbase, Binance, Morgan Stanley, Amazon, Google जैसे विश्वविद्यालयों से हैं, जिसके पास वैश्विक मानकों की स्ट्रेटेजिक ऑपरेशनल क्षमता और वित्तीय जोखिम नियंत्रण और प्रबंधन का विस्तृत अनुभव है।',
    aboutdesc8:
      'ntlex.com बुक-बुक, स्थायी संविदा, मौजूदा, विकल्प, एनएफटी, वित्तीय ऋण-उधार आदि एक स्टॉप क्रिप्टो एसेट सेवाएं प्रदान करता है और मध्य, अंग्रेजी, जापानी, कोरियाई आदि कई भाषा संस्करणों का समर्थन करता है। इसकी तकनीकी योजना एमेज़ॉन AWS और गूगल क्लाउड आदि पर आधारित है, जो डीडीओएस आदि हमलों से सक्षम होती है, गतिशील और स्थैतिक डेटा विभाजन, हॉटस्पॉट डेटा कैश, आवास के अलग-अलग स्थान, परालल विस्तार आदि की समर्थन करती है। वैश्विक तकनीकी संरचना उपयोगकर्ताओं की सुरक्षित ट्रेडिंग सुनिश्चित करती है, और तत्काल ब्लॉकचेन एसेट एक्सेस क्षमता, बहुमुद्रा पते साझाकृति तकनीक, बहुत से सुरक्षा सत्यापन संरक्षा के साथ होता है। साथ ही, ntlex.com के पास उच्चतम ब्लॉकचेन एसेट ट्रेडिंग शुल्क और निकास शुल्क होते हैं।',
    aboutdesc9:
      'एक विश्वसनीय डीएओ गवर्नेंस ट्रेडिंग प्लेटफॉर्म के रूप में, ntlex.com उपयोगकर्ता व्यापार सूचना और संपत्ति सुरक्षा को पूरी तरह से सुरक्षित रखने के लिए समर्पित है, उपयोगकर्ताओं को उत्कृष्ट व्यापार अनुभव प्रदान करने के लिए।',
    support: 'तकनीकी सहायता',
    report: 'सुझाव',
    service: 'ग्राहक सेवा',
    apply: 'लिस्टिंग के लिए आवेदन करें',
    coop: 'व्यापार सहयोग',
    community: 'ईपीबीटीसी समुदाय',
    wechat: 'वीचैट',
    addwechat:
      '`bmate601` को WeChat मित्र के रूप में जोड़ें और WeChat कल्याण समुदाय में प्रवेश करें',
    weibo: 'वीबो',
    twitter: 'ट्विटर',
    biyong: 'मुद्रा',
    telegram: 'टेलीग्राफ',
    medium: 'मध्यम',
    reddit: 'रेडिट',
  },
  bzb_description: {
    title1: 'लाभांश अधिकार',
    title2: 'हैंडलिंग शुल्क छूट',
    title3: 'प्राथमिकता सदस्यता',
    title4: 'मतदान अधिकार',
    title5: 'पहला अधिकार जानने का',
    title6: 'प्राथमिकता सूची',
    title7: 'आम तौर पर चर्चा करें और निर्णय लें',
    title8: 'अधिक',
    desc1: 'हैंडलिंग शुल्क लाभांश, आय लाभांश, मुद्रा लिस्टिंग लाभांश, इक्विटी लाभांश, आदि।',
    desc2:
      'लेन-देन शुल्क 80% तक की छूट है और इनाम वापस कर दिया जाता है। विवरण निर्धारित किया जाना है',
    desc3: 'प्रथम IEO और बूट प्रचार टोकन प्राथमिकता सदस्यता अधिकार',
    desc4:
      'EPBTC समुदाय मतदान गतिविधियों में भाग लें, जिसमें टोकन लिस्टिंग, पुनर्खरीद, आदि शामिल हैं, लेकिन इन्हीं तक सीमित नहीं हैं।',
    desc5:
      'खुली रुचि की एक निश्चित राशि एसवीआईपी समूह में प्रवेश करेगी और बड़े वी के साथ शून्य दूरी का संपर्क होगा',
    desc6: 'केवल सुपर नोड्स, लिस्टिंग की सिफारिश करने की योग्यता के साथ',
    desc7: 'केवल सुपर नोड्स, प्रमुख निर्णयों पर चर्चा करने का अधिकार रखते हैं',
    desc8: 'अधिक अधिकार, बने रहें',
  },
  footer: {
    wechatkefu: 'वीचैट ग्राहक सेवा',
    notice: 'मंच की घोषणा',
    changjian: 'आम समस्या',
    shiyong: 'समझौते का प्रयोग करें',
    feilv: 'दर विवरण',
    lianxi: 'ई - मेल से संपर्क करे',
    biyong: 'सिक्का उपयोग',
    gsmc: 'वैश्विक उच्च गुणवत्ता वाले डिजिटल एसेट एक्सचेंज',
    bah: 'शंघाई आईसीपी नंबर 13026899-3',
    gsjj: 'के बारे में',
    gywm: 'हमारे बारे में',
    jrwm: 'हमसे जुड़ें',
    lxwm: 'संपर्क करें',
    yqlj: 'लिंक',
    bzzx: 'सहायता केंद्र',
    xsrm: 'नौसिखिया ट्यूटोरियल',
    czzn: 'रिचार्ज गाइड',
    ptsm: 'प्लेटफ़ॉर्म विवरण',
    fysm: 'व्यापार नियम',
    fwtk: 'उपयोगकर्ता का समझौता',
    fltk: 'चार्ज विवरण',
    wechat: 'वीचैट ग्राहक सेवा',
    xszn: 'शुरुआती मार्गदर्शक',
    cjwt: 'आम समस्या',
    mztk: 'अस्वीकरण',
    ystk: 'गोपनीयता नीति',
    tkxy: 'शर्तें अनुबंध',
    gybzb: 'बीजेडबी के बारे में',
    kfyx: 'ग्राहक सेवा ईमेल',
    swhz: 'व्यापार सहयोग',
    sbsq: 'लिस्टिंग के लिए आवेदन करें',
    jyzn: 'ट्रेडिंग गाइड',
    bzzl: 'मुद्रा की जानकारी',
    tsjb: 'शिकायतें',
    apidoc: 'एपीआई इंटरफ़ेस',
    zwkf: 'अभी नहीं खुला',
  },
  financeNav: {
    wdzc: 'मेरी संपत्ति',
  },
  index: {
    circulation: 'बीएचबी सुरक्षा और विकास निधि संचलन',
    hourEth: 'आज वितरित की जाने वाली कुल आय रूपांतरित हो जाती है',
    yesterdayEth: 'कल की आय का संचयी वितरण परिवर्तित हो गया है',
    yesterday: 'कल का खनन उत्पादन',
    bhbTotal: 'बीएचबी टोटल सर्कुलेशन',
    bhbSecond: 'बीएचबी सेकेंडरी मार्केट सर्कुलेशन',
    checkMineprinciple: 'खनन सिद्धांत देखें',
    checkFlowVolum: 'तरलता विवरण की जाँच करें',
    checkLockPosition: 'ताला स्थिति देखें',
    BHBregister: 'ईपीबीटीसी खाता पंजीकरण',
    tibi: 'निकासी आगमन समय और सीमा',
    line_plane: 'बीएचबी लॉन्च प्लान, सर्कुलेशन और हैंडलिंग फीस रिटर्न की घोषणा',
    fenpeijizhi: 'ईपीबीटीसी आय वितरण तंत्र पर स्पष्टीकरण',
    jiangli_jihua: 'निमंत्रण खनन पुरस्कार कार्यक्रम',
    friend_fanhuan: 'दोस्तों को आमंत्रित करें और अतिरिक्त हैंडलिंग शुल्क वापसी अर्जित करें',
  },
  plate: {
    title: 'प्लेटफ़ॉर्म आय वितरण योजना (बीएचबी धारकों की इक्विटी)',
    content1:
      'जैसा कि श्वेत पत्र में वर्णित है, ईपीबीटीसी प्लेटफॉर्म की आय का 80% (करों और शुल्क के बाद) ईपीबीटीसी धारकों को आवंटित करेगा, और आय का 20% प्लेटफॉर्म के विकास और संचालन का समर्थन करने के लिए उपयोग किया जाएगा।',
    content2:
      'आय वितरण दिन को वितरण चक्र के रूप में लेता है, और 6 जून, 2018 पहला वितरण दिवस है। उसके बाद हर दिन, पिछले दिन जमा की जाने वाली सभी आय को बीएचबी धारकों को एक बार में वितरित किया जाएगा। अनुपात।',
    content3: '(नोट: 1. यहां बीएचबी केवल जारी/परिचालित बीएचबी को संदर्भित करता है, कृपया [` देखें)',
    content3_1: 'बीएचबी सर्कुलेशन/आय वितरण में भागीदारी के अनुपात पर स्पष्टीकरण',
    content3_2:
      '2। हर घंटे (घंटे पर) एक स्नैपशॉट लें और इसकी गणना एक बार करें, और आय वितरण दिन में एक बार निष्पादित किया जाएगा)।',
    hourFenpei: 'आज की वितरण आय के बराबर',
    hourTotal: 'आज का कुल प्लेटफॉर्म राजस्व इसके बराबर है',
    xiangqing: 'आज का आय वितरण विवरण',
    yesterdaytit: 'कल की दैनिक वितरण आय के बराबर',
    yesterdaytotal: 'कल की कुल प्लेटफ़ॉर्म आय के बराबर',
    yesterdayxiangqing: 'कल की आय वितरण विवरण',
  },
  feereturn: {
    ruletitle: 'वापसी नियम',
    rulecontent:
      'बीएचबी के वितरण अनुपात को श्वेत पत्र में विस्तार से समझाया गया है। बीएचबी का 51% धीरे-धीरे `लेनदेन खनन है `विधि के माध्यम से व्यापारिक उपयोगकर्ताओं को पुरस्कृत किया जाता है। एक बार बीएचबी के 51% को पूरी तरह से पुरस्कृत किया जाता है, `खनन` स्वचालित रूप से समाप्त हो जाएगा।',
    recordtitle: 'रिटर्न रिकॉर्ड',
    recordcontent:
      'दैनिक (यूटीसी+8, वही नीचे) पिछले दिन उपयोगकर्ताओं द्वारा किए गए लेनदेन शुल्क के 100% को बीएचबी में परिवर्तित कर देगा और उपयोगकर्ताओं को वापस कर देगा। परिवर्तित मूल्य पिछले कारोबारी दिन बीएचबी की औसत कीमत पर आधारित है ( औसत मूल्य की गणना कुल लेनदेन राशि/कुल लेनदेन मात्रा के रूप में की जाती है)। हम हर दिन सुबह 11 बजे पिछले दिन के लेनदेन शुल्क के बीएचबी-समतुल्य रिफंड जारी करना शुरू कर देंगे। ',
  },
};
