import BigNumber from 'bignumber.js';

// 科学计数法超出精度的处理，返回字符串，num 是数字但不能是 NaN
function toNonExponential(num) {
  num = num - 0; // 防止 num 是字符串形式的数字
  let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

/* 除法函数，用来得到精确的除法结果
    说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
    调用：accDiv(arg1,arg2)
    返回值：arg1除以arg2的精确结果的字符串
*/
function accDiv(arg1, arg2) {
  let x = new BigNumber(arg1);
  let y = x.dividedBy(arg2);
  if (y.toNumber() + '' === 'NaN') {
    // 拦截NaN，返回字符串0
    // console.warn('NaN 异常处理，返回 0')
    return '0';
  }
  return toNonExponential(y.toNumber());
}

// 除法，增加一个 div 方法，调用起来更加 方便。
Number.prototype.div = function (arg) {
  return accDiv(this, arg);
};
String.prototype.div = function (arg) {
  return accDiv(this, arg);
};

/* 乘法函数，用来得到精确的乘法结果
    说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
    调用：accMul(arg1,arg2)
    返回值：arg1乘以 arg2的精确结果的字符串
 */
function accMul(arg1, arg2) {
  let x = new BigNumber(arg1);
  let y = x.multipliedBy(arg2);
  if (y.toNumber() + '' === 'NaN') {
    // 拦截NaN，返回字符串0
    // console.warn('NaN 异常处理，返回 0')
    return '0';
  }
  return toNonExponential(y.toNumber());
}

// 乘法，增加一个 mul 方法，调用起来更加方便。
Number.prototype.mul = function (arg) {
  return accMul(arg, this);
};
String.prototype.mul = function (arg) {
  return accMul(arg, this);
};

/* 向下取整的方式，取 n 位小数，返回字符串
    当没有参数时，返回非科学计数法形式的字符串
 */
function toFixedFloor(num, digit) {
  // num 不能是 NaN
  if (num + '' === 'NaN') {
    // 拦截NaN，返回字符串0
    // console.warn('NaN 异常处理，返回 0')
    return '0';
  }
  if (num && digit) {
    // return ~~(number * Math.pow(10, digit)) / Math.pow(10, digit)
    num = toNonExponential(num); // num 是科学计数法时，mul 和 div 方法算出来的是错误答案
    return toNonExponential(Math.floor(num.mul(Math.pow(10, digit))).div(Math.pow(10, digit)));
  } else {
    return toNonExponential(num);
  }
}

/* 向下取 n 位小数
    返回字符串
    当没有参数时，返回 非科学计数法形式 的字符串
 */
Number.prototype.ffl = function (digit) {
  return toFixedFloor(this, digit);
};
String.prototype.ffl = function (digit) {
  return toFixedFloor(parseFloat(this), digit);
};

export function parseTime(time, cFormat, xArr, aArr) {
  if ((time + '').length === 10) {
    time = +time * 1000;
  }

  xArr = xArr && xArr.length === 2 ? xArr : ['AM', 'PM'];
  aArr =
    aArr && aArr.length === 7
      ? aArr
      : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else if (!isNaN(parseInt(time))) {
    date = new Date(parseInt(time));
  } else {
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a|x)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'x') {
      let h = formatObj['h'];
      return h <= 12 ? xArr[0] : xArr[1];
    }
    if (key === 'a') return aArr[value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return timeStr;
}

export default {
  accDiv,
  accMul,
  parseTime,
};
