module.exports = {
  common: {
    FindUs: 'Найти наши товары и услуги',

    registeredAccount: 'Зарегистрировать аккаунт в узи',
    unlock: 'Разблокируй узи! Почувствуй силу анонимности',
    invitation: 'Приглашаем друзей присоединиться к узи',
    closeTheDeal: 'Завершить первую сделку',

    sloganthree: 'Добро пожаловать в NTL!',
    slogantwo: 'Исследовать уникальную вселенную узи',
    slogantwo2: 'Механизм прозрачный и безопасный',
    currencySelection: 'Таблица',
    chart: 'диаграмма',
    details: 'подробности',
    entrust: 'поручать',
    login: 'Авторизоваться',
    register: 'регистр',
    loginregister: 'войти в систему',
    logout: 'выход',
    tip: 'Подсказки',
    indexplaceholde: 'почтовый ящик',
    logintip: 'Пожалуйста, войдите сначала',
    signupnow: 'регистрация',
    expect: 'Будьте на связи!',
    delete: 'подтвердить удаление?',
    nodata: 'Нет записей',
    set: 'настраивать',
    update: 'Исправлять',
    slogan: '50 крупнейших мировых бирж цифровых валют',
    subslogan: 'Безопасность активов, гарантированная государственными учреждениями',
    more: 'Более',
    please: 'Сначала пожалуйста',
    pleaseselect: 'пожалуйста, выбери',
    searchplaceholder: 'Введите название валюты для поиска',
    searchplaceholderswap: 'Введите название контракта для поиска',
    loading: 'Загрузка ...',
    close: 'закрытие',
    ok: 'Конечно',
    fillComplete: 'Заполните, пожалуйста, полную информацию',
    inputpleaseselect: 'Введите пожалуйста',
    showDetail: 'смотреть подробности',
  },
  header: {
    index: 'титульная страница',
    exchange: 'Транзакция в валюте монеты',
    trade: 'торговля',
    otc: 'Fiat currency OTC',
    kefu: 'Центр помощи',
    asset: 'Финансовый центр',
    ucenter: 'Расчетный центр',
    service: 'Объявление',
    downloadapp: 'Отсканируйте код, чтобы загрузить и установить',
    appdownlaod: 'APP download',
    usercenter: 'Персональный центр',
    helpcenter: 'Центр помощи',
    aboutus: 'о',
    partner: 'партнер',
    bzb: 'БЗБ',
    assetmanage: 'Управление деньгами',
    trademanage: 'Управление транзакциями',
    activity: 'последние события',
    lab: 'Лаборатория инноваций',
    ctc: 'Фиатная валюта C2C',
    labdetail: 'Лаборатория EPBTC ● Делимся инновационными достижениями',
    invite: 'Партнер по продвижению',
    whitepaper: 'Белая бумага',
    scandownload: 'Сканировать, чтобы загрузить приложение',
    innovationmanage: 'Инновационный менеджмент',
    swap1: 'Бессрочный контракт',
    swap2: 'Бессрочный контракт (согласование)',
    swap3: 'Контракт на разницу',
    swap4: 'Второй контракт',
    cexchange: 'Быстрый обмен',
    option: 'Опционный контракт',
    second: 'Второй контракт',
    news: 'Новости',
    swapExchange: 'контрактная сделка',
    finance: 'финансы',
    fiatCurrency: 'Купить крипто',
  },
  sectionPage: {
    NewExclusive: 'Только для новичков.',
    Newtip: 'Регистрация аккаунта узи, приглашение друзей, возможность получить пожизненное',
    global_leader: 'Ведущая цифровая валютная биржа по всему миру',
    Professional:
      'Профессиональные сделки, простые операции, чтобы облегчить понимание цифровой сделки',
    showMore: 'смотреть больше',
    brandDesc6:
      'NTL обладает функцией оперативного доступа к активам в рамках цепочки блоков, технологиями совместного использования нескольких валютных адресов, множественной защитой в плане безопасности, а также меньшими операционными издержками и расходами на извлечение активов в рамках цепочки блоков.',
    brandDesc5:
      'техническая программа NTL, основанная на Google Cloud и Amazon AWS, эффективно предотвращает такие нападения, как DDOS, поддерживает динамическое и статическое разделение данных; Поддержка кэша горячих точек данных, поддержка готовности к стихийным бедствиям, параллельное расширение, глобальная лидирующая техническая архитектура обеспечивает безопасность операций пользователей.',
    brandDesc4:
      'В настоящее время NTL имеет в своем распоряжении такие общие службы криптографии, как монеты, постоянные контракты, наличные средства, опционы, кредиты на цели управления и т.д., которые поддерживают многоязычные версии на китайском / английском / корейском / корейском языках.',
    brandDesc3:
      'NTL стремится к тому, чтобы стать международной биржей по ценным, надежным, стабильным, доступным, справедливым и надежным активам секторальной цепочки, которая обеспечивала бы полную защиту информации о сделках и активов пользователей.',
    brandTitle2: 'I) Служба централизованного шифрования активов',
    brandTitle1: 'международная биржа производных цифровых активов',
    ptaqTitle: 'Безопасность платформы',
    ptaqContent:
      'Использование шифрования с использованием хэш-алгоритма, ведущая в мире сертификация безопасности',
    ptslTitle: 'Сила платформы',
    ptslContent: 'Крупнейшая в мире платформа для торговли биткойнами',
    newsTitle: 'Мульти-терминальное приложение',
    brandTitle: 'О EPBTC',
    brandDetail: 'Честность | Справедливость | Энтузиазм | Открытость',
    brandDesc1:
      'WWW.EPBITCOIN.COM был основан группой первых участников Биткойн и компьютерных фанатов. Основные члены команды - представители известных компаний, таких как Google, Microsoft, Alibaba, Tencent и т. Д., С глубокими исследованиями и разработками и богатыми опыт эксплуатации интернет-продуктов. ',
    brandDesc2:
      'WWW.EPBITCOIN.COM позиционируется как базовый поставщик услуг блокчейна и стремится предоставлять высококачественные зашифрованные платформы для торговли активами для пользователей по всему миру. Открытое отношение приветствует всех партнеров / проекты, которые отвечают фундаментальным интересам пользователей. . ',
    gettingstart: 'Начало работы для новичков | Покупайте монеты быстро',
    officialstart: 'Официальный вспомогательный канал для новичков EPBTC',
    oneminutebuy: 'Валютная операция',
    oneminutebuytips: 'Используйте один цифровой актив для прямого обмена на другой',
    baseknow: 'Постоянный контракт.',
    baseknowtips:
      'У-стандартные, долговременные контракты с валютными стандартами поддерживают двусторонние держателей, которые могут увеличиться в 100 раз за счет увеличения доли заемных средств.',
    baseexchange: 'Опционный контракт',
    baseexchangetips: 'Является производным цифровым активом',
    usersocial: 'Быстрый обмен',
    usersocialtips: 'Быстрый обмен цифровых активов',
    mainboard: 'Торговая площадка Main Board',
    preview: 'Предыдущая страница',
    nextpage: 'Следующая Страница',
    downloadwhite:
      'Загрузите технический документ (или щелкните его правой кнопкой мыши -> "Сохранить как ...")',
  },
  nav: {
    shouye: 'титульная страница',
    jiaoyi: 'Фиатная валютная транзакция',
    dingdan: 'Управление заказами',
    zijin: 'Финансовый центр',
    yonghu: 'Центр пользователя',
    bibi: 'Транзакция в валюте монеты',
    fabu: 'Разместить объявление',
    ptjy: 'Платформенные транзакции',
    ptaq: 'Безопасность платформы',
    ptsl: 'Сила платформы',
    xwzx: 'Многопозиционное приложение',
  },
  progress: {
    already: 'Майн',
    total: 'Всего можно добыть 6 миллиардов BHB',
  },
  service: {
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    CUSTOM: 'По желанию',
    COIN: 'Валюта',
    favor: 'собирать',
    symbol: 'Торговая пара',
    NewPrice: 'Последняя цена',
    ExchangeNum: 'Количество транзакций',
    FeePercent: 'Ставка финансирования',
    Change: '24 часа вверх и вниз',
    OpenPrice: 'Цена открытия',
    PriceTrend: 'Ценовой тренд',
    Operate: 'работать',
    Exchange: 'Перейти к сделке',
    trading: 'Торгуй сейчас',
    high: 'Максимальная цена за 24 часа',
    low: 'Самая низкая цена за 24 часа',
  },
  exchange: {
    loginBuy: 'После регистрации (BTC)',
    loginOut: 'продажа после регистрации (BTC)',
    coin: 'Валюта',
    symbol: 'Торговая пара',
    lastprice: 'Последняя цена',
    daychange: '24 часа вверх и вниз',
    market: 'рынок',
    favorite: 'собирать',
    do_favorite: 'собрано',
    cancel_favorite: 'Удалить из избранного',
    type: 'тип',
    dealamount: 'Оборот',
    high: 'высокий',
    low: 'Низкий',
    vol: 'Объем за 24 часа',
    buy: 'Купить',
    sell: 'Распродавать',
    limited_price: 'Лимитная цена',
    market_price: 'Рыночная цена',
    fees_rate: 'Скорость зарядки',
    balance: 'Ресурсы',
    or: 'или',
    starttrade: 'Начать торговать',
    canuse: 'Доступный',
    recharge: 'Взимать монеты',
    buyprice: 'цена покупки',
    buynum: 'Buy Amount',
    amount: 'Сумма транзакции',
    buyin: 'Купить',
    disablebuyin: 'Запрет покупки по рыночной цене',
    buytip: 'Покупайте по лучшей цене на рынке',
    sellprice: 'Цена продажи',
    sellnum: 'Объем продаж',
    sellout: 'Распродавать',
    disablesellout: 'Продажа по рыночной цене запрещена',
    selltip: 'Продавать по лучшей цене на рынке',
    curdelegation: 'Текущий делегат',
    hisdelegation: 'История делегирования',
    realtransaction: 'Транзакция в реальном времени',
    num: 'количество',
    price: 'цена',
    direction: 'направление',
    time: 'время',
    stall: 'Положение передачи',
    total: 'Общая сумма',
    traded: 'Дело сделано',
    action: 'работать',
    undo: 'Отменить заказ',
    delegationnum: 'Доверенная сумма',
    done: 'Дело сделано',
    status: 'штат',
    finished: 'завершенный',
    canceled: 'Отменено',
    parted: 'Частичная транзакция',
    tip: 'намекать',
    buyamounttip: 'Пожалуйста, введите сумму покупки',
    buyamounttipwarning: 'Количество покупок не может быть больше',
    success: 'Успешно отправлено',
    pricetip: 'Пожалуйста, введите сумму транзакции',
    pricetipwarning: 'Сумма транзакции не может быть больше',
    sellamounttip: 'Пожалуйста, введите проданную сумму',
    sellamounttipwarning: 'Проданное количество не может быть больше',
    sellpricetip: 'Пожалуйста, введите продажную цену',
    sellpricetipwarning: 'Цена продажи не может быть выше',
    undotip: 'Вы уверены, что отмените заказ? ',
    cancelsuccess: 'Заказ успешно отменен!',
    marketprice: 'Рыночная цена',
    expand: {
      time: 'время',
      price: 'цена',
      amount: 'количество',
      fee: 'Плата за обработку',
    },
    realtime: 'Совместное времяпровождение',
    kline: 'К-линейный график',
    depth: 'Карта глубины',
    publishstate0: 'Мероприятие вот-вот начнется, подождите ...',
    publishstate1: 'Событие подходит к концу, торгуйте как можно скорее ...',
    publishstate2: 'Активный заказ консолидируется, подождите ...',
    publishstate3: 'Консолидация окончена, торгуйте свободно ...',
    publishdetail: 'Подробности правил действий',
    publishprice: 'Цена вопроса',
    publishamount: 'Общий',
    publishcounttxt0: 'До начала мероприятия осталось',
    publishcounttxt1: 'Осталось до конца мероприятия',
    publishcounttxt2: 'Осталось до конца ликвидации',
    publishcounttxt3: 'Мероприятие окончено',
    dateTimeDay: 'небо',
    dateTimeHour: 'Время',
    dateTimeMinutes: 'Минута',
    dateTimeSeconds: 'Второй',
    moredetail: 'Подробнее',
    coinSymbol: 'Торговая валюта',
    cycle: 'Период',
    closeprice: 'Цена закрытия',
    openprice: 'Цена открытия',
    win: 'успех',
    lose: 'неудача',
    tied: 'Рисовать',
    earn: 'Выгода',
    orderType: 'Возмещение',
    orderTypeNo: 'нет',
    orderTypeYes: 'Да',
  },
  swap: {
    contract: 'договор',
    swapindex: 'Стабильный',
    coin: 'Валюта',
    symbol: 'Торговая пара',
    volume: 'Объем',
    lastprice: 'Последняя цена',
    daychange: '24 часа вверх или вниз',
    market: 'рынок',
    favorite: 'собирать',
    do_favorite: 'собрано',
    cancel_favorite: 'Удалить из избранного',
    type: 'тип',
    dealamount: 'Оборот',
    high: 'высокий',
    low: 'Низкий',
    vol: 'Объем за 24 часа',
    buy: 'Купить',
    sell: 'Распродавать',
    limited_price: 'Ордер по предельной цене',
    market_price: 'Комиссия рыночной цены',
    spot_price: 'Stop profit stop loss',
    fees_rate: 'Скорость зарядки',
    balance: 'Ресурсы',
    or: 'или',
    starttrade: 'Начать торговать',
    canuse: 'Доступный',
    recharge: 'Взимать монеты',
    buyprice: 'цена покупки',
    buynum: 'Buy Amount',
    amount: 'Сумма транзакции',
    buyin: 'Купить',
    disablebuyin: 'Запрет покупки по рыночной цене',
    buytip: 'Покупайте по лучшей цене на рынке',
    sellprice: 'Цена продажи',
    sellnum: 'Объем продаж',
    sellout: 'Распродавать',
    disablesellout: 'Продажа по рыночной цене запрещена',
    selltip: 'Продавать по лучшей цене на рынке',
    curdelegation: 'Текущий делегат',
    hisdelegation: 'Историческая комиссия',
    realtransaction: 'Транзакция в реальном времени',
    num: 'количество',
    price: 'цена',
    direction: 'направление',
    time: 'время',
    stall: 'Положение передачи',
    total: 'Общая сумма',
    traded: 'Дело сделано',
    action: 'работать',
    undo: 'Отменить заказ',
    delegationnum: 'Доверенная сумма',
    done: 'Дело сделано',
    status: 'штат',
    finished: 'завершенный',
    canceled: 'Отменено',
    parted: 'Частичная транзакция',
    tip: 'намекать',
    buyamounttip: 'Пожалуйста, введите сумму покупки',
    buyamounttipwarning: 'Количество покупок не может быть больше',
    success: 'Успешно отправлено',
    pricetip: 'Пожалуйста, введите сумму транзакции',
    pricetipwarning: 'Сумма транзакции не может быть больше',
    sellamounttip: 'Пожалуйста, введите проданную сумму',
    sellamounttipwarning: 'Проданное количество не может быть больше',
    sellpricetip: 'Пожалуйста, введите продажную цену',
    sellpricetipwarning: 'Цена продажи не может быть выше',
    undotip: 'Вы уверены, что отмените заказ? ',
    cancelsuccess: 'Заказ успешно отменен!',
    marketprice: 'Рыночная цена',
    expand: {
      time: 'время',
      price: 'цена',
      amount: 'количество',
      fee: 'Плата за обработку',
    },
    realtime: '分时',
    kline: 'К-линейный график',
    depth: 'Карта глубины',
    open: 'Открытая позиция',
    close: 'Закрыть позицию',
    latestdeal: 'Последняя транзакция',
    myswapaccount: 'Моя учетная запись контракта',
    accountmode: 'Режим учетной записи',
    accountmargin: 'Множественный контракт',
    accountquanyi: 'Общий баланс счета',
    profitandloss: 'Нереализованная прибыль и убыток',
    principalAmount: 'Доступная маржа',
    positionAmount: 'Маржа позиции',
    frozenAmount: 'Freeze Margin',
    principalRate: 'Коэффициент использования фонда',
    zijinhuazhuan: 'Перевод средств',
    up: ' много',
    down: ' нулевой',
    canup: 'Можно открыть еще',
    candown: 'Может открываться пусто',
    cancloseup: 'Короткую позицию можно закрывать',
    canclosedown: 'Можно закрыть несколько позиций',
    openup: 'Купить открытую длинную позицию',
    opendown: 'Продать для открытия короткой позиции',
    closeup: 'Покупайте в шорт',
    closedown: 'Продать квартиру больше',
    currentposition: 'Текущая позиция',
    assetsdetail: 'Сведения о фонде',
    marginMode1: 'Ходовая часть складом',
    marginMode2: 'Полный склад',
    modifyMarginModeTitle: 'Изменить режим учетной записи',
    modifyLeverage: 'Настроить кратность контракта',
    moredetail: 'Подробнее',
    shareNumber: 'открытым',
    entrustprice: 'Консигнационная цена',
    triggleprice: 'Триггерная цена',
    openvolume: 'Количество открытых позиций',
    closevolume: 'Количество закрытых позиций',
    triggleplaceholder: 'Комиссионная цена по умолчанию - рыночная цена',
    marketpriceplaceholder: 'Продавать по лучшей цене на рынке',
    levmaxtip: 'Множитель контракта не может быть больше, чем самый высокий мультипликатор',
    levmintip: 'Множитель контракта не может быть меньше минимального мультипликатора',
    marginModeSuccessTip: 'Модель контрактного счета успешно изменена',
    noenoughbalance: 'Недостаточная маржа на счете',
    pleaseinputopenvolume: 'Количество открытых позиций должно быть больше 0',
    pleaseinputcorrectopenvolume: 'Пожалуйста, введите правильное количество открытых позиций',
    pleaseinputrightentrustprice: 'Пожалуйста, введите правильную цену заказа',
    noenoughposition: 'Недостаточно ликвидных позиций',
    pleaseinputclosevolume: 'Количество закрытия должно быть больше 0',
    pleaseinputcorrectclosevolume: 'Пожалуйста, введите правильное закрывающееся количество',
    pos_Pl: 'Выгода',
    pos_Ratio: 'норма прибыли',
    pos_pos: 'Объем позиции',
    pos_canClose: 'Доля уровня',
    pos_openPrice: 'Средняя цена открытия',
    pos_leverage: 'Множественный контракт',
    pos_margin: 'Текущая маржа',
    pos_mRatio: 'Маржа',
    pos_cmRatio: 'Поддерживаемая маржа',
    pos_long: 'Длинная голова',
    pos_short: 'Короткий',
    ent_entrustType: 'Открытая позиция / Закрытие позиции',
    ent_direction: 'направление',
    ent_type: 'Тип делегирования',
    ent_trigglePrice: 'Триггерная цена',
    ent_entrustPrice: 'Консигнационная цена',
    ent_tradedPrice: 'окончательная цена',
    ent_margin: 'Прибыль',
    ent_volume: 'Количество заказа',
    ent_status: 'Статус делегирования',
    ent_status1: 'Доверено',
    ent_status2: 'Отменено',
    ent_status3: 'Ошибка делегирования',
    ent_status4: 'Делегирование выполнено успешно',
    ent_statusblast: 'Прорыв',
    ent_pl: 'Расчетные прибыли и убытки',
    ent_fee: 'Плата за обработку',
    triggerpriceshouldbigger: 'Цена срабатывания должна быть больше 0',
  },
  second: {
    balance: 'Остаток средств',
    profitability: 'Рентабельность',
    opentime: 'Время открытия',
    transactionMode: 'Торговый режим',
    currentPosition: 'Текущая позиция',
  },
  ctc: {
    title: 'Торговля в один клик ● Хостинг платформы ● Надежно и надежно',
    desc: 'Цифровые активы размещаются на платформе EPBTC для обеспечения безопасности пользовательских активов',
    buyin: 'Купить',
    sell: 'Продавать',
    buyprice: 'цена покупки',
    sellprice: 'Цена продажи',
    buynum: 'Сумма покупки',
    sellnum: 'Проданная сумма',
    payamount: 'Итого по расчетам',
    getamount: 'Итого по расчетам',
    avabalance: 'Доступное количество',
    time: 'час транзакции',
    tip: 'намекать',
    notice: 'Подсказки',
    notice1:
      '1. Зона торговли фиатной валютой предназначена для операций с активами между пользователями и получателями. Денежные средства не переводятся на платформу, и платформа не принимает пополнение / перевод в юанях;',
    notice2:
      '2. Приемники для торговли фиатной валютой прошли аутентификацию с использованием настоящего имени и предоставляют торговую наценку, а токены размещены на платформе, вы можете с уверенностью обменивать их;',
    notice3:
      '3. Время обслуживания акцептатора - 09: 00-21: 00 каждый день, прием будет завершен в течение 30 минут после получения заказа, а транзакция, которая не была завершена в течение 2 часов, будет аннулирована;',
    notice4:
      '4. В зоне легальной торговли валютой поддерживаются банковские карты, и для перевода необходимо использовать подтвержденный аккаунт на настоящее имя;',
    notice5:
      '5. Чтобы обеспечить безопасность средств транзакции, транзакции с фиатной валютой требуют, чтобы пользователи проходили аутентификацию с использованием настоящего имени. Пожалуйста, установите пароль фонда и получающий счет вовремя после прохождения аутентификации с использованием настоящего имени, в противном случае транзакция не будет возможный;',
    payType: 'способ оплаты',
    receiveType: 'способ оплаты',
    moneyTips:
      'Указанная выше сумма предназначена только для справки, пожалуйста, обратитесь к фактической сумме расчета после следующего заказа',
    verifyset: 'Аутентификация с использованием настоящего имени / пароль фонда',
    payset: 'Настройки платежа / квитанции',
    trade: 'торговля',
    input50tips: 'Пожалуйста, введите число от 50 до 50000',
    bank: 'Банковская карта',
    alipay: 'Alipay',
    wechatpay: 'WeChat',
    payaccountset: 'Настройки платежного аккаунта',
    useselfaccount:
      'Убедитесь, что вы используете учетную запись для сбора / оплаты, которая соответствует вашей аутентификации на настоящее имя',
    orderSn: 'номер заказа',
    tradetime: 'время заказа',
    direction: 'Тип операции',
    amount: 'Количество (USDT)',
    money: 'Всего (CNY)',
    price: 'Цена за единицу (CNY)',
    status: 'штат',
    operate: 'работать',
    moredetail: 'Дополнительные торговые инструкции',
  },
  cexchange: {
    use: 'потребление',
    consume: 'обмен',
    title: 'Быстрое преобразование',
    desc: 'Обмен в один клик,Воспользуйтесь преимуществами',
    quantity: 'Обменное количество',
    inputtips1: 'Введите произвольное количество',
    choosecurrency: 'Выбрать валюту обмена',
    maxtip: 'Максимальное погашение ',
    mintip: 'минимальная замена ',
    all: 'все',
    totalpay: 'Общая сумма обмена',
    restricted: 'Сегодняшняя ограниченная серия',
    currentprice: 'Текущая биржевая цена',
    nextprice: 'Следующая цена обмена',
    confirmpay: 'Подтвердить обмен',
    assets: 'сальдо:',
    loginFirst: 'Пожалуйста, войдите сначала',
    success: 'Поздравляем! Удачного выкупа!',
    tip: 'намекать',
    confirmmsg: 'подтверждать',
  },
  option: {
    title: 'Опционный контракт',
    buyupreward: 'Общее увеличение покупной цены',
    buydownreward: 'Всего покупок и убытков',
    quantity: 'Сумма открытой позиции',
    myamount: 'Моя открытая позиция',
    buyup: 'Бычье',
    buydown: 'Медвежий',
    history: 'Прошлые результаты',
    seriers: 'NS',
    period: 'Ожидать',
    currentoption: 'Период прогноза текущего периода',
    openprice: 'Цена открытия',
    currentprice: 'Текущая цена',
    openprice1: 'Цена открытия',
    closeprice: 'Цена закрытия',
    opentime: 'Время открытия',
    closetime: 'Время закрытия',
    progress: 'Прогноз прогресса',
    loginFirst: 'Пожалуйста, войдите сначала!',
    selectAmount: 'Выберите количество ставок',
    balancenotenough: 'Недостаточно на балансе!',
    betsuccess: 'Поздравляем! Ставка успешна!',
    noopening: 'Нет опционных контрактов на рассмотрении',
    col_createTime: 'Время создания',
    col_direction: 'Направление прогноза',
    col_result: 'результат прогноза',
    col_optionNo: 'Контрактный период',
    col_betAmount: 'Сумма открытой позиции',
    col_rewardAmount: 'Бонусное количество',
    col_fee: 'Комиссия за открытую позицию',
    col_winfee: 'Прокачка',
    win: 'успех',
    lose: 'неудача',
    wait: 'в ходе выполнения',
    avaliablebalance: 'Доступные средства',
    login: 'Пожалуйста, войдите сначала',
    kline: 'Котировки в реальном времени',
  },

  otc: {
    payment_detail: 'Подробнее о способе оплаты',
    bind_payment: 'Привязать способ оплаты',
    payment_placeholder: 'Пожалуйста, выберите способ оплаты',
    payment: 'Способ оплаты',
    ad: 'Рекламный центр',
    buyin: 'Купить',
    sellout: 'Распродавать',
    merchant: 'Бизнес',
    applymerchant: 'Подать заявку в качестве сертифицированного продавца',
    volume: 'Количество транзакций',
    paymethod: 'способ оплаты',
    amount: 'количество',
    price_coin: 'Цена / монета',
    operate: 'работать',
    validate: 'Пожалуйста, сначала выполните аутентификацию с использованием настоящего имени',
    sell: 'Распродавать',
    buy: 'Купить',
    transaction: 'Сделка купли-продажи',
    buttontxt: 'Стать торговцем',
    title: 'Фиатная валютная транзакция',
    desc: 'Удобная, безопасная и быстрая торговля цифровой валютой',
    title1: 'Динамическая регулировка',
    desc1: 'По рыночной цене колеблется в режиме реального времени',
    title2: 'Комиссия за бесплатную обработку',
    desc2:
      'Пользователи видят то, что они получают, никаких комиссий за платформу, кроме цены покупки и продажи, не требуется',
    title3: 'Мгновенная транзакция',
    desc3:
      'Представьте платформу для обслуживания продавцов, интеллектуальное сопоставление, транзакции заказов, не нужно ждать сопоставления',
    title4: 'Гарантия платформы',
    desc4:
      'Продавцы, сертифицированные платформой, надежно и безопасно, круглосуточная служба поддержки сопровождает транзакции',
    myad: {
      title: 'Мои объявления',
      post: 'Разместить объявление',
      alert:
        '[Напоминание]: Когда количество покупок плюс комиссия за обработку минимальной суммы транзакции рекламы больше, чем оставшееся количество рекламы, реклама будет автоматически удалена с полок ',
      no: 'Номер объявления',
      type: 'тип рекламы',
      sell: 'Интернет-продажа',
      buy: 'Купить онлайн',
      limit: 'Лимит заказа',
      remain: 'Оставшееся количество',
      coin: 'Валюта',
      created: 'Время создания',
      operate: 'работать',
      errmsg: 'Объявления можно редактировать только после их удаления',
      update: 'Исправлять',
      shelf: 'Поставить на полку',
      dropoff: 'С полки',
      delete: 'удалять',
    },
    myorder: 'Мой заказ',
    chatline: {
      status_1: 'Покупатель не заплатил, ждет, пока покупатель заплатит! ',
      status_2: 'Покупатель заплатил и ждет освобождения продавца! ',
      status_3: 'Заказ выполнен! ',
      status_4: 'Заказ обжалуется! ',
      status_5: 'Заказ отменен! ',
      loadmore: 'Загрузи больше',
      warning:
        'Советы по предотвращению мошенничества: в последнее время мошенники неоднократно использовали информацию о банковских переводах и поддельные учетные данные для совершения мошенничества. Пожалуйста, войдите в свою принимающую учетную запись для проверки. Обеспечьте сохранность переводимых средств и избегайте риска замораживания банковских карт! ',
      contenttip: 'Пожалуйста, введите содержание чата и нажмите Enter для отправки',
      contentmsg: 'Сообщение не может быть пустым',
    },
    chat: {
      seller: 'Продавец',
      buyer: 'Покупатель',
      exchangeamount: 'Сумма транзакции',
      operatetip: 'Советы по эксплуатации',
      operatetip_1:
        'Пожалуйста, завершите платеж в соответствии с учетной записью, предоставленной другой стороной, в течение указанного срока, и нажмите на эту страницу',
      finishpayment: 'платеж завершен',
      operatetip_1_1: 'Обратите внимание на номер транзакции в сообщении при переводе средств',
      operatetip_1_2:
        'После получения платежа продавец подтвердит платеж на веб-сайте, и система автоматически зачислит приобретенные вами цифровые активы в ваш аккаунт. Обратите внимание, чтобы проверить и получить',
      note: 'Уведомление',
      notetip:
        'Пожалуйста, не используйте другое программное обеспечение чата для связи с другой стороной, не говоря уже о том, чтобы принимать любые файлы, вложения электронной почты и т. Д., Отправленные вам другой стороной. Все ссылки для связи должны быть заполнены в окне чата на этой странице',
      operatetip_2_1:
        'Цифровые активы, которые вы продаете, были переданы платформе для хранения и замораживания. Пожалуйста, нажмите после подтверждения получения платежа от другой стороны',
      operatetip_2_2:
        'Пожалуйста, не верьте никаким причинам для призыва к выпуску монет и выпускайте цифровые активы после подтверждения получения денег, чтобы избежать потерь! ',
      operatetip_2_3:
        'После получения SMS-сообщения войдите в систему онлайн-банкинга или мобильного банкинга, чтобы убедиться, что деньги зачислены на счет, чтобы избежать неправильного выпуска цифровых активов из-за получения мошеннических SMS-сообщений! ',
      confirmrelease: 'Подтвердить выпуск',
      paydigital: 'Оплата цифровых активов',
      orderstatus: 'Статус заказа',
      orderstatus_1: 'платеж завершен',
      orderstatus_2: 'Обжалование заказа',
      orderstatus_3: 'Подтвердить выпуск',
      orderstatus_4: 'отменить сделку',
      orderstatus_5: 'Обжалование заказа',
      order: 'Порядок',
      and: 'а также',
      transition: 'Перевод',
      transprice: 'Торговая цена',
      transnum: 'Количество транзакций',
      transmoney: 'Сумма транзакции',
      tip1: 'Пользователь временно не добавил номер банковской карты',
      tip2: 'Пользователь временно не добавил учетную запись Alipay',
      tip3: 'Пользователь временно не добавил учетную запись WeChat',
      zfb: 'Alipay',
      wx: 'WeChat',
      qrcode: 'QR код',
      msg1: 'Вы уверены, что оплата произведена?',
      msg2: 'Уплаченная сумма возврату не подлежит! Вы уверены, что отмените заказ? ',
      msg3: '[Важно]: Платежи не возвращаются! Вы уверены, что отмените заказ? ',
      msg4: 'Платеж произведен, монеты не получены',
      msg5: 'Монета оплачена, платеж не получен',
      tip: 'намекать',
      comptype: 'Тип жалобы',
      compremark: 'Замечания по жалобе',
      willcomp: 'Я хочу пожаловаться',
      msg6: 'Вы уверены, что выпустите монеты? ',
      result_1: 'ожидается платеж',
      result_2: 'В ожидании релиза',
      result_3: 'завершенный',
      result_4: 'Привлекательный',
      result_5: 'Отменено',
      msg7: 'Пароль фонда',
      msg7tip: 'Введите пароль фонда',
    },
    checkuser: {
      emaildone: 'Почта аутентифицирована',
      emailundo: 'Почта не аутентифицирована',
      teldone: 'Номер мобильного телефона подтвержден',
      telundo: 'Номер мобильного телефона не подтвержден',
      idcarddone: 'ID-карта проверена',
      idcardundo: 'ID-карта не аутентифицирована',
      language: 'Язык',
      languagetext1: 'English',
      languagetext: 'Китайский язык',
      registtime: 'Время регистрации',
      exchangetimes: 'Накопленное количество транзакций',
      exchangeinfo: 'Информация о транзакции',
      tablabel1: 'Интернет-продажа',
      tablabel2: 'Купить онлайн',
      col_symbol: 'Валюта',
      col_paymode: 'способ оплаты',
      col_num: 'количество',
      col_price: 'цена',
      col_created: 'время выпуска',
      col_operate: 'работать',
      operatemsg: 'Пожалуйста, сначала выполните аутентификацию с использованием настоящего имени',
      buyin: 'Купить',
      sellout: 'Распродавать',
    },
    tradecenter: {
      postad: 'Разместить объявление',
      exchange: 'Транзакция GCC',
      buyin: 'Я хочу купить',
      sellout: 'Я хочу продать',
    },
    tradeinfo: {
      emaildone: 'Почта аутентифицирована',
      emailundo: 'Почта не аутентифицирована',
      teldone: 'Номер мобильного телефона подтвержден',
      telundo: 'Номер мобильного телефона не подтвержден',
      idcarddone: 'ID-карта проверена',
      idcardundo: 'ID-карта не аутентифицирована',
      exchangetimes: 'Транзакции',
      price: 'цена',
      num: 'количество',
      paymethod: 'способ оплаты',
      exchangelimitamount: 'Лимит транзакции',
      location: 'Место нахождения',
      location_text: 'Китай',
      exchangeperiod: 'Торговый период',
      minute: 'минута',
      amounttip: 'Пожалуйста, введите сумму',
      numtip: 'Пожалуйста, введите количество',
      remarktip: 'Скажи ему свою просьбу',
      remarktitle: 'Примечания к информации',
      exchangetitle: 'Уведомление о транзакции',
      exchange_tip1:
        'После того, как вы инициируете запрос транзакции, цифровая валюта заблокирована на хранении и защищена платформой. Если вы продавец, после того, как вы инициируете запрос на транзакцию, вы можете пополнить счет и дождаться, пока покупатель заплатит. Покупатель производит оплату в установленный срок. Вы должны освободить цифровую валюту на хранение после получения платежа.',
      exchange_tip2:
        'Пожалуйста, прочтите "Условия обслуживания сети платформы" и справочные документы, такие как часто задаваемые вопросы и руководства по торговле, прежде чем торговать.',
      exchange_tip3:
        'Остерегайтесь лжецов! Пожалуйста, проверьте оценку, полученную пользователем, перед торговлей, и обратите больше внимания на вновь созданную учетную запись. ',
      exchange_tip4:
        'Обратите внимание, что округление и колебания цен могут повлиять на окончательную сумму транзакции в цифровой валюте. Введенная вами фиксированная сумма определяет окончательную сумму, а сумма в цифровой валюте будет рассчитываться по обменному курсу в реальном времени одновременно с отправкой запроса. ',
      exchange_tip5:
        'Кастодиальная служба защищает как покупателей, так и продавцов онлайн-транзакций. В случае возникновения спора мы оценим всю предоставленную информацию и передадим цифровую валюту на хранение ее законному владельцу. ',
      warning1: 'Введите до двух знаков после запятой',
      warning2: 'Сумма заказа',
      warning3: 'Введите до 8 знаков после запятой',
      warning4: 'Количество заказа',
      confirmbuyin: 'Подтвердить покупку',
      confirmsellout: 'Подтвердить продажу',
      buyin: 'Купить',
      sellout: 'Распродавать',
      warning5: 'Пожалуйста, заполните заказ по мере необходимости',
    },
    publishad: {
      createad: 'Создать рекламную транзакцию',
      msg1: 'Если вы часто торгуете, вы можете создавать свои собственные рекламные объявления. Если вы торгуете только от случая к случаю, мы предлагаем вам искать напрямую ',
      msg2: 'Создать объявление о транзакции есть',
      msg3: 'Бесплатно',
      msg4: 'Если вы хотите напрямую отредактировать созданное объявление, просмотрите',
      tradead: 'Транзакционная реклама',
      myad: 'Мои объявления',
      iwant: 'Я хочу',
      sellonline: 'Интернет-продажа',
      buyonline: 'Купить онлайн',
      exchangecoin: 'Торговая валюта',
      country: 'нация',
      currency: 'Валюта',
      openfixedprice: 'Открытая фиксированная цена',
      open: 'На',
      close: 'закрытие',
      usetip:
        'После активации цена вашей валюты не будет колебаться в зависимости от рынка, и цена останется неизменной. ',
      premiseprice: 'Премиум',
      premisepricetip: 'Установите премию',
      fixedprice: 'Фиксированная цена',
      fixedpricetip: 'Пожалуйста, введите цену транзакции',
      marketprice: 'Справочная рыночная цена',
      marketpricetip: 'Премия означает процентную ставку выше текущей рыночной цены',
      exchangeprice: 'Торговая цена',
      formual: 'Формула ценообразования',
      num: 'количество',
      num_text1: 'Пожалуйста, введите то, что вы хотите',
      num_text2: 'количество',
      exchangeperiod: 'Торговый период',
      exchangeperiod_text1: 'Пожалуйста, введите период транзакции',
      minute: 'минута',
      tip1: 'Сколько времени покупатель может торговать, введите целое число',
      tip2: '[Подсказка] Вы можете зайти в личный центр, чтобы привязать / добавить способ оплаты ',
      tip3: 'Пожалуйста, введите минимальную сумму транзакции',
      tip4: 'Пожалуйста, введите максимальную сумму транзакции',
      tip5: 'Вы можете указать свои особые требования в примечаниях, например: требования покупателя, онлайн-время и т. Д. ',
      paymode: 'способ оплаты',
      minlimit: 'Минимальная сумма транзакции',
      maxlimit: 'Максимальная сумма транзакции',
      remark: 'Примечания к информации',
      openautoreply: 'Включить автоматический ответ',
      msg5: 'После включения, когда пользователь инициирует транзакцию с вами через это объявление, система автоматически отправит другой стороне язык автоответчика, который вы выберете. ',
      autoreply: 'автоматический ответ',
      autoreplytip:
        'После получения заказа автоматически ответьте на информацию покупателя, такую ​​как: способ оплаты, номер платежного счета и т. Д. ',
      fundpwd: 'Пароль фонда',
      fundpwdtip: 'Пожалуйста, введите пароль вашего фонда',
      submit: 'Разместить',
      warning1: 'Пожалуйста, введите правильный номер',
      warning2: 'Значение переполнения 0-20',
      warning3: 'Пожалуйста, введите максимальную сумму транзакции! ',
      warning4: 'Пожалуйста, введите целое число',
      warning5: 'Максимальная сумма транзакции должна быть больше минимальной суммы транзакции! ',
      warning6: 'Максимальная сумма транзакции не может превышать вашу общую сумму продажи',
      warning7: 'Пожалуйста, введите минимальную сумму транзакции! ',
      warning8: 'Минимальная сумма транзакции должна быть больше или равна 100! ',
      warning9: 'Минимальная сумма транзакции должна быть меньше максимальной суммы транзакции',
      sellout: 'Распродавать',
      buyin: 'Купить',
      inputtip1: 'Пожалуйста, введите валюту',
      inputtip2: 'Пожалуйста, введите правильный вариант',
      inputtip3: 'Значение переполнения 0-20 и не может быть 0',
      inputtip4: 'Пожалуйста, введите правильную фиксированную цену',
      inputtip5:
        'Пожалуйста, введите правильное число, и максимальная сумма транзакции не превышает 100 монет',
      inputtip6: 'Пожалуйста, введите правильный срок транзакции',
      inputtip7: 'Пожалуйста, выберите способ транзакции',
      inputtip8: 'Пожалуйста, введите пароль фонда',
      zfb: 'Alipay',
      wx: 'WeChat',
      unionpay: 'банковская карта',
      submit_failure: 'Ошибка отправки!',
      submit_success: '',
      submittip1:
        'Для безопасности ваших средств сначала выполните [аутентификацию настоящего имени]! ',
      submittip2:
        'Для сохранности ваших средств сначала выполните [привязку мобильного телефона]! ',
      submittip3: 'Для безопасности ваших средств, пожалуйста, сначала установите [пароль фонда]! ',
      submittip4: 'Укажите хотя бы один способ оплаты',
    },
    index: {
      title: 'Качественная рекламная рекомендация',
      exchangetimes: 'Транзакции',
      exchangeprice: 'Торговая цена',
      exchangelimit: 'Лимит транзакции',
      paymode: 'способ оплаты',
      buy: 'Купить',
      sell: 'Распродавать',
      viewmore: 'узнать больше',
      bot1: 'Безопасно и надежно',
      bot1_tip:
        'Команда с более чем 10-летним опытом управления финансовыми рисками занимается  nнастраиваемой системой стратегии безопасности',
      bot2: 'Быстро и удобно',
      bot2_tip:
        'Режим одноранговой транзакции пользователя поддерживает несколько методов обмена по каналам денежных средств',
      bot3: 'Полная валюта',
      bot3_tip:
        'Выберите основные валюты для торговли цифровыми активами, чтобы соответствовать множеству аспектов торговли несколькими активами',
      ibuy: 'Я хочу купить',
      isell: 'Я хочу продать',
    },
  },
  activity: {
    all: 'все',
    prepare: 'Вот-вот начнется',
    ongoing: 'в ходе выполнения',
    distributing: 'Распространение',
    completed: 'завершенный',
    progress: 'Прогресс',
    totalsupply: 'Общая активность',
    starttime: 'Время начала',
    endtime: 'Время окончания',
    viewdetail: 'подробности уточняйте',
    attend: 'Участвуй сейчас',
    tokendistribute: 'Токен распространяется',
    baseinfo: 'Основная информация',
    status: 'штат',
    activitytype: 'Тип подписки',
    activitytype0: 'Неизвестный',
    activitytype1: 'Первый онлайн (спешите купить)',
    activitytype2: 'Впервые в сети (даже сплит)',
    activitytype3: 'Доли позиций',
    activitytype4: 'Бесплатная подписка',
    activitytype5: 'Подписка на машину для облачного майнинга',
    activitytype6: 'Разблокировка положения блокировки',
    activitystatus0: 'временный',
    activitystatus1: 'Не развернуто',
    activitystatus2: 'Развернуто',
    activitystatus3: 'Отменено',
    activitystatus4: 'Незавершенная сделка',
    activitystatus5: 'Дело сделано',
    activitystatus6: 'Отменено',
    ruledetail: 'Описание типа',
    publishprice: 'Цена подписки',
    activitycoin: 'Валюта операции',
    acceptcoin: 'Принимаемая валюта',
    holdcoin: 'Требуемая валюта для удержания позиции',
    limittimes: 'Персональный лимит покупок',
    limitamount: 'Персональный лимит покупок',
    unlimited: 'Безлимитный',
    alreadyamount: 'Закупленное количество',
    alreadyholdamount: 'Общая позиция по участию в деятельности',
    myalreadyholdamount: 'Моя позиция участника',
    leftamount: 'Оставшееся количество',
    attention: 'Уведомление',
    attentiontxt1:
      '1. После подачи заявки на участие в видах деятельности [Доля позиции], [Бесплатная подписка], [Подписка на Cloud Mining Machine], [Блокировка позиции] активы будут заморожены, а после события они будут разморожены или разблокированы и выпущен ',
    attentiontxt2:
      '2. После участия в мероприятиях типа [Держать акции], [Бесплатная подписка], [Подписка на Cloud Mining Machine], [Блокировать позиции], пользователь не может отменить заявку на участие',
    attentiontxt3:
      '3. Если мероприятие отменяется из-за стороны проекта и других форс-мажорных обстоятельств, первоначальный маршрут будет возвращен замороженным активам',
    attentiontxt4:
      '* Право окончательной интерпретации этого события принадлежит официальному EPBTC (WWW.EPBITCOIN.COM)',
    inputamount: 'Введите сумму обмена',
    inputholdamount: 'Введите сумму заблокированной позиции',
    mybalance: 'Мой доступный баланс',
    holdtips:
      'Участие в деятельности типа [доля участия] должно быть заблокировано до конца деятельности',
    pleaseinputamount: 'Введите сумму обмена',
    pleaseinputholdamount: 'Введите сумму заблокированной позиции',
    commitfailed: 'Заявка не прошла!',
    minlimitamountfailed: 'Сумма обмена не может быть меньше минимальной суммы обмена!',
    maxlimitamountfailed: 'Сумма обмена не может быть больше максимального лимита покупки!',
    limittimesfailed: 'Ваше участие превышает максимальное количество личных покупок!',
    balancenotenough: 'Недостаточный баланс!',
    headertitledesc:
      'Беспроигрышная открытая лаборатория для инвесторов, участников проекта, бирж и т. Д.',
    currentdivided: 'На данный момент мою позицию можно разделить на контракты',
    leveloneCount: 'Нужны друзья первого уровня',
    submit: 'Разместить',
    tipsmobile:
      'Пожалуйста, войдите на страницу компьютера, чтобы принять участие (WWW.EPBITCOIN.COM)',
    tipsmobile1:
      'Откройте (WWW.EPBITCOIN.COM) на компьютере и войдите в область инновационного эксперимента, чтобы принять участие',
    intvalue: 'Пожалуйста, введите целое число',
    inputminingamount: 'Введите количество купленных майнеров',
    moreactivity: 'Другие занятия',
    releaseType: 'Тип выпуска',
    releaseType0: 'Равномерное высвобождение',
    releaseType1: 'Пропорциональный выпуск',
    lock: 'Период выпуска',
    releaseDetail: 'Сведения о выпуске',
    lockday: ' день',
    lockweek: ' неделя',
    lockmonth: ' Луна',
    lockyear: ' год',
    releasePercent: 'Коэффициент выпуска',
    lockFee: 'Порог заблокированного склада',
    releaseTimes: 'Отпустить несколько',
    times: 'Times',
  },
  invite: {
    inviteLoginName: 'войти в товарищество',
    chengwei: 'максимальная возможность возвращения на родину',
    pthhr: 'стать партнёром платформы',
    partnerText:
      'приглашать друзей зарегистрироваться и подтвердить подлинное имя, наслаждаться платформой бонус',
    myinvitelink: 'Моя пригласительная ссылка',
    copy: 'копия',
    copyed: 'Скопировано',
    mylevelone: 'Мой первоклассный друг',
    myleveltwo: 'Мой друг второго уровня',
    mycommission: 'Комиссионный доход (эквивалент USDT)',
    extrareward: 'дополнительный бонус',
    partnerlevel: 'Уровень партнерства',
    ruledetail: 'Сведения о правиле',
    ruleprofile1: 'EPBTC (WWW.EPBITCOIN.COM) [Партнер по продвижению] - это вся сеть',
    ruleprofile2: 'Самый высокий процент скидок',
    ruleprofile3: 'Самый длинный период бонуса (максимальный пожизненный бонус)',
    ruleprofile4:
      'Рекламная деятельность позволяет промоутерам, которые приложили соответствующие усилия, стать" партнерами "платформы в истинном смысле этого слова и пользоваться бонусом роста вместе с торговой платформой EPBTC (WWW.EPBITCOIN.COM)! Особые правила следующие: ',
    ruletext1:
      '1. В целях продвижения концепции цифровых активов и расширения круга пользователей EPBTC (WWW.EPBITCOIN.COM) запустил программу [Promotion Partners], которая действует уже долгое время.',
    ruletext2:
      'Два. Есть два уровня приглашения друзей. Если A приглашает B, то B является другом A первого уровня. Если B снова приглашает C, то C принадлежит другу B первого уровня, а также принадлежит A второго уровня друг. ',
    ruletext3:
      '3. При регистрации продвигаемого друга вам необходимо использовать ссылку, предоставленную приглашающим, или вручную ввести код приглашения, чтобы стать другом первого уровня приглашающего.',
    ruletext4:
      'Четыре. Если приглашенный завершает регистрацию через код приглашений, то вступает в силу стимул для возвращения.',
    ruletext5:
      'Пять. Время возвращения на родину составляет N месяцев, начиная с того момента, как приглашенное лицо завершило регистрацию, и в зависимости от степени ранга, в зависимости от степени возврата оно имеет разную пропорцию.',
    ruletext6:
      'Шесть. Продвижение активов со скидкой на комиссию - это комиссия за транзакцию друзей нижнего уровня, а монеты со скидкой - это три основные рыночные валюты, а именно: USDT, BTC и ETH.',
    ruletext7:
      'Семь. Коэффициент скидки по акции и продолжительность скидки положительно коррелируют с количеством друзей первого уровня в акции. Чем больше количество промоутеров, тем выше процент скидок, которые можно получить. Конкретный коэффициент скидок по акции следующий: следует: ',
    ruletext8:
      '8. Продвижение ТОП10 или ТОП100 в рейтинге комиссионных скидок будет время от времени получать дополнительные награды, официально выдаваемые EPBTC (WWW.EPBITCOIN.COM).',
    ruletext9:
      '9. Если другие действия противоречат действиям [Партнера по продвижению], после переговоров будет принято решение о том, следует ли менять временные изменения, и я надеюсь, что партнеры поймут.',
    ruletext10:
      'Партнеры уровня Ten, L4 (губернатор), L5 (префект), L6 (Цзе Ду Ши) получают 5%, 10% и 15% от общей годовой скидки.',
    ruletext11:
      '11. Право окончательной интерпретации этого события принадлежит EPBTC (WWW.EPBITCOIN.COM).',
    ruleexampletitle: 'Например:',
    ruleexamplecontent1:
      'Если бы пользователь Сяоянь пригласил 100 друзей первого уровня, а друзья первого уровня ТА пригласили 5 друзей второго уровня, то у Сяоянь было бы 100 друзей первого уровня и 500 друзей второго уровня. Если в среднем каждый человек Если вы торгуете 3000 юаней в день, ежемесячный доход Сяо Яня составляет примерно: (100 * 3000 * 0,001 * 30% + 500 * 3000 * 0,001 * 10%) * 30 = 7200 / мес. ',
    ruleexamplecontent2:
      'Если бы пользователь Сяоянь пригласил 1000 друзей первого уровня, а друзья первого уровня TA пригласили 5 друзей второго уровня, то у Сяоянь было бы 1000 друзей первого уровня и 5000 друзей второго уровня. Если средний человек Если вы торгуете 3000 юаней в день, ежемесячный доход Сяо Яня составляет примерно: (1000 * 3000 * 0,001 * 50% + 5000 * 3000 * 0,001 * 20%) * 30 = 135000 / мес. ',
    lastupdate: 'Последнее обновление',
    laststastic: 'Срок сдачи статистики',
    level: 'Уровень',
    lv1: 'Личжэн',
    lv2: 'Орден округа',
    lv3: 'Префект',
    lv4: 'Губернатор',
    lv5: 'Префект',
    lv6: 'Цзянду Ши',
    levelonecount: 'Количество друзей первого уровня',
    levelonecommission: 'Коэффициент скидки для друзей первого уровня / время скидки',
    leveltwocommission: 'Коэффициент скидки для друзей второго уровня / время скидки',
    partnerdivide: 'Дивиденды партнера',
    day: 'день',
    alllife: 'Пожизненная скидка',
    totalcommissionrank: 'Общая скидка на рекламу',
    rank: 'Классифицировать',
    userid: 'ID пользователя',
    rankday: '(Дневные часы)',
    rankweek: '(Чжоу Банг)',
    rankmonth: '(Список месяцев)',
    rankall: '(Общий список)',
    promotioncount: 'Количество рекламных акций',
    commissionusdt: 'Скидка (эквивалент USDT)',
    ranktip1:
      'Количество приглашений - это количество друзей первого уровня, а этот список - рейтинг общей суммы скидок',
    ranktip2:
      'Количество приглашений - это количество друзей первого уровня, а этот список является рейтингом номеров приглашений первого уровня',
    thanks: 'Спасибо, что выросли с EPBTC (WWW.EPBITCOIN.COM)!',
    headertip:
      'Вышеуказанные данные комиссии не обновляются в реальном времени. Система считает и обновляет каждые 24 часа.',
    ptools: 'Набор инструментов для продвижения',
    pt_title: 'Подарочная карта цифровой валюты',
    pt_desc:
      'После погашения он автоматически станет другом более низкого уровня, отправит друзей, родственников, клиентов и партнеров. Помогите партнерам по продвижению лучше продвигаться.',
    pt_more: 'Другие инструменты, следите за обновлениями',
    pt_tips:
      'Если у вас есть хорошая идея, отправьте электронное письмо на адрес promo@NTLEX.COM, после принятия будут награды!',
    pt_card_amount: 'Сумма карты',
    pt_card_deadline: 'Срок действия',
    pt_card_noend: 'Бесконечно',
    pt_card_btn: 'Партнеры по акции получают 30 бесплатных билетов',
    pt_card_btn1: 'Партнеры по продвижению получают бесплатно',
    pt_card_btn2: '',
    pt_card_btn_login: 'Получите 30 бесплатных билетов после входа в систему',
    pt_card_rule: 'Сведения о правиле',
    pt_card_summary:
      'Подарочная карта в цифровой валюте - один из инструментов продвижения, разработанный для того, чтобы партнеры по продвижению могли лучше приглашать друзей в офлайн-режиме. Когда целевые пользователи промоакции выкупают подарочную карту, они автоматически становятся офлайн-друзьями промоутера',
    pt_card_rule1:
      '1. Каждому пользователю доступно 30 бесплатных подарочных карт. Если вам нужно выпустить больше подарочных карт, вам нужно заплатить за настройку, и вы можете настроить сумму лицевой стороны карты, ЛОГОТИП, текст описания и т. Д. Отправьте электронное письмо к ',
    pt_card_rule2:
      '2. Бесплатные подарочные карты ограничены до одной для каждого пользователя. Бесплатная подарочная карта, официально выпущенная EPBTC (WWW.EPBITCOIN.COM), ограничена одной для каждого пользователя, даже если разные пользователи получают разные коды погашения для бесплатных подарочных карт. можно обменять только на одну копию. Это ограничение не распространяется на подарочные карты, изготовленные на заказ. ',
    pt_card_rule3:
      "3. Перед тем, как пользователь погасит подарочную карту, если нет' приглашения '(то есть код приглашения не был введен при регистрации учетной записи), он будет автоматически связан как друг первого уровня карты. эмитент. Если A не вводит код приглашения при регистрации учетной записи, в настоящее время A не принадлежит чьему-либо другу первого или второго уровня, но когда A обменивает подарочную карту, выпущенную B, то A автоматически становится B's друг первого уровня. ",
    pt_card_rule4:
      'Четыре. Чтобы предотвратить попадание клиентов в руки, балансовая стоимость подарочной карты не погашается в реальном времени. После ее получения необходимо выполнить аутентификацию с использованием настоящего имени.',
    pt_card_rule5:
      'В-пятых, вам необходимо завершить проверку настоящего имени, когда вы получите бесплатную подарочную карту, и вы можете получить ее, не завершая проверку настоящего имени, когда вы ее погасите. Это должно помочь промоутерам найти друзей более низкого уровня. Быстрее.',
    pt_card_rule6:
      'VI. Право на окончательную интерпретацию бесплатной подарочной карты принадлежит WWW.EPBITCOIN.COM.',
    pt_card_day: 'небо',
    pt_card_title_tips: 'Получите 2 000 юаней из фонда помощи в продвижении',
    pt_card_title_tips1: 'воротник',
    pt_card_title_tips2: 'Рекламный грант',
    pt_card_receivew_success:
      'Поздравляем! Вы успешно получили подарочную карту партнерской акции! Перейдите в [Персональный центр] -> [Центр карт и купонов], чтобы проверить!',
    usepromotion: 'Используйте рекламные материалы',
    saveimage: 'сохранить изображение',
    imagetips:
      'Если вы не можете загрузить картинку в обычном режиме, используйте инструмент для создания скриншотов, чтобы сохранить ее!',
    pt_invite_title: 'Приглашаем зарегистрироваться для получения рекламных изображений',
    pt_invite_desc:
      'Эксклюзивный QR-код, вы можете автоматически стать подчиненным другом через регистрацию QR-кода, подходящий для пересылки в различные группы или форумы.',
    colum_text0: 'Классифицировать',
    colum_text1: 'Член',
    colum_text2: 'Количество рекламных акций',
    colum_text3: 'Скидка (эквивалент USDT)',
    colum_text4: 'дополнительный бонус',
    context_title: 'Эксклюзивный код погашения для партнеров по продвижению',
    context_title1: 'Отсканируйте, чтобы узнать больше',
    context_title2: 'Мой код приглашения',
    context_title3: 'Пригласить изображение',
  },
  uc: {
    retrieveprivate: {
      findText: 'найти номер счета',
      findText2: 'Был восстановлен частный ключ аккаунта',

      inputFindtext: 'Введите почтовый ящик, связанный с номером счета',
      continue: 'Продолжить',
    },
    verify: 'Проверено',
    login: {
      welcomelogintxt: 'Добро пожаловать в NTL!',
      welcomelogintxt2: 'если у тебя нет счета',
      welcomelogintxt3: 'Вы можете Щёлкнуть здесь',
      welcomelogintxt4: 'регистрационный номер счета',
      welcomelogintxt5: 'регистрационный номер',
      welcomelogintxt6: 'если у тебя есть номер счёта',
      welcomelogintxt7: 'Открываем новый торговый тур',
      inputPrivate: 'Введите закрытый ключ...',
      forgetPrivateTxt: 'забыть закрытый ключ счёта?',
      phoneEmail: 'телефон или почтовый ящик',
      anonymousLogin: 'анонимный доступ',
      noaccount: 'Нет аккаунта? ',
      register: 'регистр',
      login: 'Авторизоваться',
      welcomelogin: 'Пожалуйста, войдите',
      usertip: 'Пожалуйста, введите свой номер телефона или адрес электронной почты',
      pwdtip: 'Пожалуйста, введите пароль',
      validatecodeload: 'Загрузка кода подтверждения',
      validatemsg: 'Сначала завершите проверку',
      forget: 'забыли пароль?',
      loginvalidate: 'Пожалуйста, введите имя пользователя',
      pwdvalidate1: 'Пожалуйста, введите пароль',
      pwdvalidate2: 'Длина пароля не может быть меньше 6 цифр',
      success: 'Вход выполнен успешно',
      goregister: 'Иди зарегистрируйся.',
      getlostpwd: 'забыть пароль',
    },
    forget: {
      title: 'Получить пароль',
      hasaccount: 'Уже есть учетная запись? Пожалуйста, нажмите, чтобы войти ',
      login: 'Авторизоваться',
      sendcode: 'Отправьте проверочный код',
      newpwd: 'Пожалуйста, введите новый пароль',
      confirmpwd: 'Пожалуйста, подтвердите пароль еще раз',
      save: 'Разместить',
      pwdvalidate1: 'Пожалуйста, введите пароль для подтверждения',
      pwdvalidate2: 'Две записи пароля несовместимы!',
      resettelpwd: 'Сбросить пароль телефона',
      resetemailpwd: 'Сбросить пароль электронной почты',
      newpwdtip: 'Пожалуйста, введите новый пароль',
      pwdvalidate3: 'Длина пароля не может быть меньше 6 цифр',
      telno: 'номер телефона',
      smscode: 'введите проверочный код',
      teltip: 'Пожалуйста, введите номер телефона',
      smscodetip: 'Пожалуйста, введите проверочный код по SMS',
      email: 'Почта',
      password: 'код',
      NoId: 'Номера счетов пока нет.',
      emailcode: 'Код подтверждения электронной почты',
      emailtip: 'введите свой адрес электронной почты',
      emailcodetip: 'Пожалуйста, введите код подтверждения электронной почты',
      resetpwdsuccess: 'Пароль успешно сброшен',
      smswarn: 'Обратите внимание, проверьте SMS',
      bangding: 'Удалить привязку',
      googleAuth: 'Google Expert',
      PleasegoogleAuth: 'пожалуйста, свяжите Google Expert',
      bindGoogleAuth: 'Windows Google Expert',
      Jcbind: 'снять привязки',
    },
    finance: {
      center: 'Финансовый центр',
      personalassets: 'Валютный счет монет',
      swapassets: 'Бессрочный счет',
      billdetail: 'Поток активов',
      tradetail: 'Торговля майнингом',
      paydividends: 'Держать валютные дивиденды',
      invitingmining: 'Пригласить награды за майнинг',
      charge: 'Взимать монеты',
      pickup: 'Снять монеты',
      money: {
        cointype: 'Название валюты',
        balance: 'Доступные активы',
        frozen: 'Заморозить активы',
        operate: 'работать',
        charge: 'Взимать монеты',
        pickup: 'Снять монеты',
        onkeytrans: 'Один ключ к повороту',
        getaddress: 'Получить адрес',
        resetsuccess: 'Добейтесь успеха',
        match: 'Сопряжение',
        matchtip1: 'Число платных GCX',
        matchtip2: 'Пожалуйста, введите количество пар',
        matcherr1: 'Пожалуйста, введите допустимое количество!',
        matcherr2: 'Превышено максимальное количество пар!',
        matchsuccess: 'Сопряжение выполнено!',
        needreleased: 'Активы, которые будут освобождены',
        totalassets: 'Общая сумма активов, эквивалентная:',
      },
      swap: {
        totalassets: 'Конверсия капитала:',
        swap: 'Бессрочный счет',
        swaptype: 'Контрактный счет',
        swapassets: 'Средства на счете',
        avabalance: 'Доступная маржа',
        usebalance: 'Использованная маржа',
        frozenbalance: 'Freeze Margin',
        profitandloss: 'Нереализованная прибыль и убыток',
        transfor: 'Перевод средств',
        inputtransferamount: 'Количество перевода',
        all: 'Перенести все',
        currencyaccount: 'Кредитный счет',
        swapaccount: 'Счет с бессрочным контрактом',
        clickchange: 'Нажмите, чтобы изменить направление смахивания',
        avaamount: 'Переводная сумма',
        pleaseselectwallet: 'Пожалуйста, сначала выберите счет входа / выхода',
        pleaseinputamount: 'Введите сумму перевода',
        oktransfer: 'Подтвердить перевод',
      },
      second: {
        secondaccount: 'Второй контрактный счет',
        swap: 'Второй контрактный счет',
      },
      finance: {
        transfer: 'Передача финансового управления',
        account: 'Счет управления капиталом',
        balance: 'Остаток средств',
        earn: 'Накопленные проценты',
        rate: 'процентная ставка',
        confirm: 'Подтвердить перевод',
        transferAmount: 'Перевод в количестве',
        all: 'Перенести олл-ин',
        coin: 'Валюта',
        open: 'Запись позиции',
        close: 'Запись закрытой позиции',
        fail: 'операция провалена',
        success: 'Успешная операция',
        updateTime: 'Время обновления',
        financialManagement: 'Финансовый менеджмент',
        financialOrder: 'Порядок управления капиталом',
      },
      record: {
        start_end: 'Время начала и окончания',
        to: 'к',
        code: 'закодирова',
        remark: 'примечан',
        operatetype: 'Тип операции',
        search: 'поиск',
        charge: 'Пополнить',
        pickup: 'снять со счета',
        transaccount: 'передача',
        exchange: 'Транзакция в валюте монеты',
        otcbuy: 'Покупка фиатной валюты',
        otcsell: 'Жирные деньги проданы',
        activityaward: 'Премия за активность',
        promotionaward: 'Рекламное вознаграждение',
        dividend: 'Дивиденды',
        vote: 'Голосование',
        handrecharge: 'Ручная подзарядка',
        match: 'Сопряжение',
        activitybuy: 'Обмен активностями',
        ctcbuy: 'СТС Купить',
        ctcsell: 'СТС Продать',
        redout: 'Красные конверты',
        redin: 'Ошейник красный конверт',
        chargetime: 'транзакционный час',
        type: 'тип',
        fee: 'Плата за обработку',
        shouldfee: 'Плата за обслуживание к оплате',
        discountfee: 'Вычесть комиссию за обработку',
        realfee: 'Комиссия за оплату',
        symbol: 'Валюта',
        num: 'количество',
        status: 'штат',
        finish: 'завершенный',
        activitybuy: 'Обмен активностями',
        ctcbuy: 'C2C купить',
        ctcsell: 'C2C продать',
        quickExchange: 'Flash Redemption',
        currentRate: 'Текущий обменный курс',
        from: 'Из',
        inputexchangeamount: 'Введите количество вспышки',
        inputexchangepasswd: 'Введите пароль транзакции',
        chooseTransCoinUnit: 'Выберите валюту конвертации',
        inputTransAmount: 'Введите количество конверсий',
        predictAmount: 'Расчетное количество, подлежащее обмену',
        qamount: 'Количество вспышек',
        qrate: 'Обменный курс',
        qexamount: 'Обменное количество',
        withdrawcodein: 'Код вывода и депозит',
        withdrawcodeout: 'Снятие кода вывода',
        contractfee: 'Комиссия за выполнение бессрочного контракта',
        contractprofit: 'Прибыль по бессрочному контракту',
        contractloss: 'Убыток по бессрочному контракту',
        optionfail: 'Сбой контракта с прогнозом',
        optionfee: 'Прогноз комиссии за контракт',
        optionreward: 'Прогноз контракта бонуса',
        secondfail: 'Второй контракт провалился',
        secondreward: 'Бонус за второй контракт',
        financereward: 'Интерес финансового менеджмента',
        other: 'Другие',
      },
      paydividende: {
        money_holding: 'Кумулятивные дивиденды от удерживаемых монет были возвращены (ETH):',
        money_hold: 'Суммарная сумма возвращаемых дивидендов в валюте (ETH):',
        paydividends: 'Держать валютный дивиденд (ETH)',
        account_date: 'Время прибытия',
        datehodld: 'Дата удержания валюты',
        start_end: 'Время начала и окончания',
        to: 'к',
        operatetype: 'Тип операции',
        search: 'поиск',
        charge: 'Пополнить',
        pickup: 'снять со счета',
        transaccount: 'передача',
        exchange: 'Транзакция в валюте монеты',
        otcbuy: 'Покупка фиатной валюты',
        otcsell: 'Жирные деньги проданы',
        activityaward: 'Премия за активность',
        promotionaward: 'Рекламное вознаграждение',
        dividend: '分红',
        vote: 'Голосование',
        handrecharge: 'Ручная подзарядка',
        match: 'Сопряжение',
        chargetime: 'транзакционный час',
        type: 'тип',
        fee: 'Плата за обработку',
        symbol: 'Валюта',
        num: 'количество',
        status: 'штат',
        finish: 'завершенный',
      },
      trade: {
        accumulative_return: 'Накопительный майнинг (BHB):',
        accumulat_return: 'Ожидаемый майнинг (BHB):',
        start_end: 'Время начала и окончания',
        account_date: 'Время прибытия',
        to: 'к',
        operatetype: 'Тип операции',
        search: 'поиск',
        charge: 'Пополнить',
        pickup: 'снять со счета',
        transaccount: 'передача',
        exchange: 'Транзакция в валюте монеты',
        otcbuy: 'Покупка фиатной валюты',
        otcsell: 'Жирные деньги проданы',
        activityaward: 'Премия за активность',
        promotionaward: 'Рекламное вознаграждение',
        dividend: 'Дивиденды',
        vote: 'Голосование',
        handrecharge: 'Ручная подзарядка',
        match: 'Сопряжение',
        chargetime: 'Время формирования заказа',
        type: 'тип',
        fee: 'Возврат комиссии за майнинг (BHB)',
        num: 'количество',
        status: 'штат',
        finish: 'завершенный',
        transactionTime: 'транзакционный час',
        symbol: 'Торговая пара',
        direction: 'Направление торговли',
        price: 'цена',
        entrustment: 'Доверенная сумма',
        havedeal: 'Дело сделано',
        poundageAmount: 'Плата за обработку',
        exchangeOrderId: 'Номер заказа',
        mineAmount: 'Количество монет для майнинга (BHB)',
        buy: 'Купить',
        sell: 'Продавать',
        limit: 'Лимитная цена',
        market: 'Рыночная цена',
        clear: 'Очистить условия',
        turnover: 'Оборот',
      },
      inviting: {
        accumulative_return: 'Вознаграждение за майнинг по приглашениям было возвращено (BHB):',
        accumulat_return:
          'Пригласите вознаграждения за майнинг, которые будут возвращены кумулятивно (BHB):',
        start_end: 'Время начала и окончания',
        account_date: 'Время прибытия',
        to: 'к',
        operatetype: 'Тип операции',
        search: 'поиск',
        charge: 'Пополнить',
        pickup: 'снять со счета',
        transaccount: 'передача',
        exchange: 'Транзакция в валюте монеты',
        otcbuy: 'Покупка фиатной валюты',
        otcsell: 'Жирные деньги проданы',
        activityaward: 'Премия за активность',
        promotionaward: 'Рекламное вознаграждение',
        dividend: 'Дивиденды',
        vote: 'Голосование',
        handrecharge: 'Ручная подзарядка',
        match: 'Сопряжение',
        chargetime: 'Время формирования заказа',
        type: 'тип',
        fee: 'Возврат комиссии за майнинг (BHB)',
        symbol: 'Валюта',
        num: 'количество',
        status: 'штат',
        finish: 'завершенный',
        refereename: 'Имя рекомендованного человека',
        referetel: 'Номер мобильного телефона рекомендованного человека',
        invitingawards: 'Награда за майнинг приглашения (BHB)',
        refere: 'Рефери',
        refereinput: 'Пожалуйста, введите ваше имя / номер телефона',
      },
      recharge: {
        recharge: 'Взимать монеты',
        symbol: 'Валюта',
        address: 'Адрес монеты',
        copy: 'копировать',
        qrcode: 'QR код',
        qrcodeaddress: 'QR-код адреса пополнения',
        msg1: 'Не пополняйте счета за невалютные активы по указанному выше адресу, иначе активы не будут возвращены. ',
        msg2: 'После пополнения счета на указанный выше адрес вам необходимо подтвердить весь сетевой узел блокчейна. Как правило, основная сеть блокчейна будет зачислена после трех подтверждений сети. ',
        msg3: 'Минимальная сумма пополнения:',
        msg3_1: ', пополнение меньше минимальной суммы не будет зачислено на счет.',
        msg4: 'Ваш адрес для пополнения счета не будет часто меняться, вы можете пополнить счет; если есть какие-либо изменения, мы постараемся известить вас через объявление на веб-сайте или по электронной почте. ',
        msg5: 'Убедитесь, что ваш компьютер и браузер безопасны, чтобы предотвратить подделку или утечку информации. ',
        record: 'Запись пополнения',
        copysuccess: 'Копировать успешно! ',
        copyerr: 'Ошибка копирования! Скопируйте вручную ',
        time: 'Время прибытия',
        amount: 'Количество пополнения',
        gettingaddress: 'Получение адреса пополнения, подождите ...',
        getaddress: 'Получить адрес пополнения',
        memotips:
          'Пожалуйста, введите эту памятку по мере необходимости при пополнении счета, иначе вы потеряете свои активы.',
        withdrawrecharge: 'Пополнение кода вывода',
        coderechargetip: 'Пожалуйста, введите код вывода',
        rechargesuccess: 'Поздравляем! Код вывода успешно пополнен!',
        rechargeconfirm: 'Подтверждение пополнения баланса',
        status: 'штат',
        protocol: 'Сеткавы пратакол',
        status_0: 'праваліцца',
        status_1: 'не атрымаў',
        status_2: 'залічаны',
        protocolerr: 'Выберыце пагадненне',
        network: 'Інтэрнэт',
        notaddress:
          'Вы не падалі заяўку на адрас дэпазіту，Калі ласка, націсніце кнопку, каб атрымаць адрас дэпазіту',
        rechargeDisable: 'Функцыя папаўнення часова недаступная',
      },
      withdraw: {
        pickup: 'Снять монеты',
        network: 'сеть передачи',
        withdrawDisable: 'Приостановить снятие средств',
        addressmanager: 'Управление адресом вывода',
        withdrawbycode: 'Вывести код валюты Вывести валюту',
        withdrawcode: 'Код валюты вывода',
        symbol: 'Валюта',
        address: 'Адрес для вывода',
        num: 'Количество снятых монет',
        avabalance: 'Доступные средства',
        msg1: 'Сумма вывода меньше чем',
        msg2: 'Автоматически переходить к аккаунту в то время, в противном случае требуется проверка вручную',
        increase: 'Увеличить сумму',
        tip1: 'Максимальный ввод',
        tip11: 'Десятичные дроби, минимальное значение',
        tip2: 'Максимальное значение',
        numtip1: 'Введите сумму вывода',
        fee: 'Плата за обработку',
        range: 'Сфера',
        arriamount: 'Количество поступления',
        msg3: 'Минимальная сумма вывода',
        msg4: 'Монета',
        msg5: 'Чтобы обеспечить безопасность средств, при изменении политики безопасности вашего аккаунта, смене пароля и снятии средств с нового адреса мы будем вручную проверять снятие средств. Наберитесь терпения и дождитесь, пока персонал свяжется с вами по телефону или электронной почте. . ',
        msg6: 'Убедитесь, что ваш компьютер и браузер безопасны, чтобы предотвратить подделку или утечку информации. ',
        record: 'Запись о снятии средств',
        symboltip: 'Выберите валюту',
        addresstip: 'Введите адрес',
        numtip2: 'Пожалуйста, введите правильное количество выводов, минимальное значение',
        numtip3: 'Сумма снятых монет не должна быть меньше комиссии за обработку',
        amounttip: 'Пожалуйста, введите количество снятых монет',
        feetip1: 'Минимальная комиссия за обработку',
        feetip2: 'Максимальное значение',
        time: 'Время вывода',
        status: 'штат',
        status_1: 'Обработка',
        status_2: 'Перенос',
        status_3: 'неудача',
        status_4: 'успех',
        remark: 'Замечание',
        add: 'Добавить к',
        addresslist: 'Список адресов',
        safevalidate: 'проверка безопасности',
        telno: 'номер мобильного телефона',
        smscode: 'Мобильный проверочный код',
        second: 'Второй',
        clickget: 'Нажмите, чтобы получить',
        email: 'Почта',
        emailcode: 'Код подтверждения электронной почты',
        save: 'спасти',
        delete: 'удалять',
        telerr: 'Неверный номер мобильного телефона',
        emailerr: 'Электронная почта неверна',
        codeerr: 'Неверный проверочный код',
        remarktip: 'Пожалуйста, введите примечание',
        savemsg1: 'Ошибка сохранения!',
        savemsg2: 'Успешно сохранено!',
        operate: 'работать',
        fundpwdtip: 'Пожалуйста, введите пароль фонда',
        click: 'Щелкните',
        filtrate: 'Можно фильтровать',
        txid: 'TxId',
        generateCode: 'Создать код вывода',
        withdrawcodelist: 'Список кодов вывода',
        withdrawcodestatus: 'штат',
        submit: 'Разместить',
        generatecodesuccess: 'Код вывода успешно сгенерирован',
        code_status0: 'Неиспользованный',
        code_status3: 'успех',
        network: 'сеть передачи',
        protocol: 'Сетевой протокол',
      },
    },
    member: {
      usercenter: 'Персональный центр',
      securitycenter: 'Центр безопасности',
      securitysetting: 'Настройки безопасности',
      accountsetting: 'способ оплаты',
      account: 'Управление аккаунтом',
      assets: 'Управление деньгами',
      exchange: 'Управление транзакциями',
      curdelegate: 'Текущий заказ',
      hisdelegate: 'Историческая комиссия',
      otc: 'Внебиржевой менеджмент',
    },
    order: {
      ordercenter: 'Центр заказов',
      myorder: 'Мой заказ',
      myad: 'Мои объявления',
    },
    regist: {
      SaveKey: 'Пожалуйста, сохраните ключ',
      zhanghaosiyao: 'закрытый ключ',
      logintishi:
        'Доступ к аккаунту можно получить только с помощью частного ключа аккаунта, который создан случайным образом, при условии, что он находится под вашей личной защитой и что он автоматически копируется',
      nimingtishiTxt:
        'Введенный пароль может быть использован для входа в аккаунт, который был создан случайным образом, и который вы должны надежно хранить при условии, что потерянный почтовый ящик, к которому вы привязаны, не будет найден!',
      shuruningcheng: 'Введите ваш ник',
      anonymousLogin: 'анонимная регистрация',
      fillInvitationCode: 'заполнить код приглашения',
      go_login: 'Номер счета.',
      go_login2: 'Залогинись.',
      hasaccount: 'Уже есть учетная запись? Пожалуйста, нажмите, чтобы войти ',
      login: 'Авторизоваться',
      username: 'имя пользователя',
      country: 'нация',
      smscode: 'Проверочный код по SMS',
      sendcode: 'Отправьте проверочный код',
      resendcode: 'Отправить(',
      pwd: 'логин Пароль',
      repwd: 'Подтвердите пароль',
      confrimpwd: 'Подтвердите пароль',
      agreement: 'приня',
      userprotocol: 'Пользовательское Соглашение',
      privacypolicy: 'Политика конфиденциальности',

      regist: 'регистр',
      teltip: 'Пожалуйста, введите номер телефона',
      telerr: 'Неверный формат номера мобильного телефона, введите повторно',
      emailtip: 'введите свой адрес электронной почты',
      emailerr: 'Неверный формат электронной почты, введите повторно',
      confirmpwdtip: 'Пожалуйста, введите пароль для подтверждения',
      confirmpwderr: 'Две записи пароля несовместимы!',
      telregist: 'Зарегистрируйте свой телефон',
      emailregist: 'Регистрация электронной почты',
      usernametip: 'введите имя пользователя',
      usernamemsg: 'Длина имени пользователя не может быть меньше 3 цифр и больше 15 цифр',
      countrytip: 'Пожалуйста, выберите страну',
      smscodetip: 'Пожалуйста, введите проверочный код по SMS',
      pwdtip: 'Пожалуйста введите ваш пароль',
      pwdmsg: 'Длина пароля не может быть меньше 6 цифр',
      telno: 'номер мобильного телефона',
      email: 'адрес электронной почты',
      agreementtip: 'Пожалуйста, нажмите, чтобы согласиться',
      reloadChoice: 'Пожалуйста, перерегистрируйтесь.',
      modaltitle: 'Пожалуйста, подтвердите',
      promotion: 'Пригласительный код необязательно',
      china: 'Китай',
      singapore: 'Сингапур',
      korea: 'Южная Корея',
      japan: 'Япония',
      thailand: 'Таиланд',
      russia: 'Россия',
      uk: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
      vietnam: 'Вьетнам',
      india: 'Индия',
      italy: 'Италия',
      hk: 'Гонконг',
      malaysia: 'Малайзия',
      taiwan: 'Провинция Тайвань',
      turkey: 'Турция',
      germany: 'Германия',
      france: 'Франция',
      spain: 'Испания',
      us: 'Америка',
      canada: 'Канада',
    },
    safe: {
      oldprivateKey: 'собственный ключ',
      privateKeytip: 'Введите закрытый ключ',
      safelevel_low: 'Уровень безопасности: Низкий',
      safelevel_high: 'Уровень безопасности: Высокий',
      safelevel_medium: 'Уровень безопасности: Средний',
      nickname: 'Ник',
      bind: 'Привязка',
      binded: 'Ограниченный',
      binding: 'на рассмотрении',
      binderr: 'неудача',
      bindretry: 'Повторить попытку',
      verifypass: 'проверено',
      verified: 'Проверено',
      notverified: 'не сертифицировано',
      verifiedtip:
        'В целях защиты вашей учетной записи, пожалуйста, завершите аутентификацию с использованием настоящего имени перед торговыми операциями! ',
      realname: 'настоящее имя',
      idcard: 'Идентификационный номер',
      upload: 'Нажмите, чтобы загрузить',
      upload_positive: 'Фотография лицевой стороны удостоверения личности',
      upload_negative: 'Негативное фото на удостоверение личности',
      upload_hand: 'Ручное удостоверение личности и фотография с личной подписью',
      save: 'спасти',
      reset: 'Сброс настроек',
      email: 'Почта',
      bindemail: 'Привязать почтовый ящик',
      loginpwd: 'логин Пароль',
      emailcode: 'Код подтверждения электронной почты',
      clickget: 'Нажмите, чтобы получить',
      second: 'Второй',
      phone: 'сотовый телефон',
      bindphone: 'Привязать телефон',
      phonecode: 'Мобильный проверочный код',
      logintip: 'Использовать при входе на платформу',
      edit: 'Исправлять',
      oldpwd: 'Исходный пароль для входа',
      newpwd: 'Новый пароль для входа',
      confirmnewpwd: 'Подтвердите новый пароль',
      fundpwd: 'Пароль фонда',
      fundtip: 'При изменении средств на счете сначала необходимо проверить пароль фонда',
      set: 'настраивать',
      confirmpwd: 'Подтвердите пароль',
      oldfundpwd: 'Исходный пароль фонда',
      newfundpwd: 'Новый пароль фонда',
      newpwdmsg1: 'Пожалуйста, введите новый пароль для входа не менее 6 цифр',
      newpwdmsg2: 'Новый пароль для входа несовместим',
      pwdmsg1: 'Пожалуйста, введите пароль не менее 6 цифр',
      pwdmsg2: 'Пароли несовместимы',
      emailtip: 'Пожалуйста, введите номер почтового ящика',
      codetip: 'введите проверочный код',
      telnotip: 'Пожалуйста, введите номер телефона',
      oldpwdtip: 'Пожалуйста, введите исходный пароль',
      realnametip: 'введите свое настоящее имя',
      idcardtip: 'Пожалуйста, введите идентификационный номер',
      bindphonetip: 'Сначала привяжите телефон!',
      resetfundpwd: 'Сбросить пароль фонда',
      upload_positivetip: 'Пожалуйста, загрузите переднюю фотографию вашего удостоверения личности',
      upload_negativetip: 'Пожалуйста, загрузите обратную фотографию удостоверения личности',
      upload_handtip: 'Пожалуйста, загрузите ручное фото на удостоверение личности',
      save_success: 'Успешно сохранено!',
      save_failure: 'Ошибка сохранения!',
      idcard_verifymsg1:
        '1. Поддержка форматов JPG, PNG, размер файла меньше 8M; информация в сертификате четко видна, никакие изменения или препятствия не допускаются, номер и имя сертификата должны быть четко видны.',
      idcard_verifymsg2:
        '2. На фотографии не должно быть головного убора, и рекомендуется, чтобы не требовался макияж. Черты лица человека, держащего удостоверение личности, хорошо видны, а рука полностью обнажена.',
      idcard_verifymsg3:
        '3. Необходимо одновременно иметь сертификат и рукописную копию (имя + дата подачи заявки)." Все мои действия на этом сайте осуществляю я сам, и я осознаю связанные с этим риски, и я готов нести все юридические последствия этого аккаунта "',
    },
    account: {
      successDelete: 'Удалено успешно',
      pagetitle: 'Привязка учетной записи на настоящее имя',
      pagetip:
        'Пожалуйста, настройте способ оплаты, убедитесь, что вы используете свою учетную запись на настоящее имя',
      backcardno: 'Номер счета банковской карты',
      backcardtip: 'Данные личной банковской карты не привязаны',
      modify: 'Исправлять',
      bind: 'Привязка',
      name: 'Имя',
      bankaccount: 'Открытие банковского счета',
      bankbranch: 'Открыть филиал счета',
      bankno: 'Номер банковской карты',
      confirmbankno: 'Подтвердить номер карты',
      fundpwd: 'Пароль фонда',
      save: 'спасти',
      zfbaccount: 'Счет Alipay',
      zfbaccounttip: 'Личная учетная запись Alipay не привязана',
      wxaccount: 'WeChat account',
      wxaccounttip: 'Личная учетная запись WeChat не привязана',
      banknomsg1: 'Пожалуйста, введите правильный номер банковской карты',
      banknomsg2: 'Введенный дважды номер банковской карты неверен!',
      verifiedmsg: 'Пожалуйста, сначала выполните аутентификацию с использованием настоящего имени',
      bankaccountmsg: 'Выберите банк, чтобы открыть счет',
      bankbranchmsg: 'Пожалуйста, войдите в филиал для открытия счета',
      banknomsg: 'Пожалуйста, введите правильный номер банковской карты',
      fundpwdmsg1: 'Пожалуйста, введите правильный пароль фонда',
      fundpwdmsg2: 'Пароль не должен быть короче 6 символов',
      zfbaccountmsg: 'Пожалуйста, введите номер счета Alipay',
      wxaccountmsg: 'Пожалуйста, войдите в учетную запись WeChat',
      save_success: 'Успешно сохранено!',
      save_failure: 'Ошибка сохранения!',
      imgtip: 'Пожалуйста, загрузите свой платежный код',
    },
    otcorder: {
      unpaid: 'Неоплачено',
      paided: 'Оплаченный',
      finished: 'завершенный',
      canceled: 'Отменено',
      appealing: 'Привлекательный',
      searchtip: 'Введите номер заказа, чтобы начать поиск',
      orderno: 'номер заказа',
      created: 'Время создания',
      symbol: 'Торговая валюта',
      type: 'Тип операции',
      type_sell: 'Распродавать',
      type_buy: 'Купить',
      tradename: 'Торговые партнеры',
      amount: 'количество',
      money: 'Количество',
      fee: 'Плата за обработку',
    },
    identity: {
      certified: 'проверено',
      placeholder: 'Укажите причину отмены',
      apply: 'Подать заявку, чтобы стать бизнесом',
      become: 'Станьте торговцем, сертифицированным EPBTC, и получите больше торговых привилегий',
      zhusnhu:
        'Бизнесмены пользуются эксклюзивными рекламными киосками, чтобы увеличить успешность транзакций',
      tijiaoziliao: 'Отправить информацию о сертификации продавца',
      place:
        'Загрузите подготовленную информацию о сертификации продавца на платформу и отправьте ее',
      tijiao: 'Ваш бизнес-сертификационный отзыв отправлен',
      tijiaosuc: 'Поздравляем! Сертификация вашей компании прошла успешно',
      tijiaofail: 'Извините! Проверка сертификации вашей компании не прошла',
      zhuxiaotijiao: 'Заявление об отмене вашего бизнеса отправлено',
      shenhefail: 'Не удалось проверить заявку на отмену вашего бизнеса',
      shenhesuc: 'Заявка на отмену вашего бизнеса была рассмотрена',
      shangjiazhuxiao: 'Отмена бизнеса',
      shenheshibai: 'Ошибка аудита',
      shenagain: 'Повторная проверка',
      sheqinggain: 'Повторно применить',
      reason: 'причина',
      shenqingtuibao: 'Подать заявление о сдаче',
      getquan: 'У вас есть следующие разрешения',
      read: 'Прочитал и согласен',
      lijishenqing: 'применить немедленно',
      tips: 'намекать',
      wufachexiao:
        'Вы находитесь в процессе отмены регистрации продавца. После подтверждения операции подачи заявки ее нельзя отозвать.',
      suredo: 'Вы уверены, что будете выполнять эту операцию?',
      shuzizichan: 'Сертификат транзакции с цифровыми активами',
      gerenzichan: 'Подтверждение личных цифровых активов',
      second: {
        line: 'Как подать заявку, чтобы стать продавцом?',
        step1: 'Шаг 1. Подготовьте необходимые материалы для бизнес-приложений',
        step1c1: 'Подготовьте следующие заявочные материалы:',
        step1c2:
          'Номер мобильного телефона, WeChat ID, номер QQ, сертификат личного цифрового актива (изображение), сертификат транзакции цифрового актива (изображение)',
        step2: 'Шаг 2: Отправить заявку',
        step2c:
          'Заполните информацию о проверке сертификации продавца, которую необходимо заполнить и загрузить, и нажмите" Отправить на проверку ".',
        step3: 'Шаг 3. Обзор данных',
        stepc:
          'Мы рассмотрим материалы вашего бизнес-заявки в течение 3-5 рабочих дней. Обратите внимание на статус рассмотрения в любое время, который можно проверить на странице отправки. После того, как проверка будет пройдена, вы можете разместить объявление в юридической валютная торговая площадка. ',
        agree: 'Согласитесь на заморозку',
        agreec: 'В качестве коммерческого депозита',
        shenqingchngweishangjia: 'Подать заявку, чтобы стать продавцом',
      },
      yuanyin: 'причина',
      tijiaoshenqing: 'подать заявку',
      bizhong: 'Валюта маржи',
      shuliang: 'Размер маржи',
      chosen: 'Выберите валюту',
      seat: 'Эксклюзивная будка',
      service: 'Индивидуальная услуга',
      lowfee: 'Более низкая плата за обработку',
      phone: 'сотовый телефон',
      balance: 'Ситуация с личными активами',
      cardphoto: 'Фотографии на лицевой и оборотной сторонах удостоверения личности',
      wx: 'WeChat',
      exchange: 'Вы когда-нибудь участвовали в внебиржевых транзакциях с цифровыми активами?',
      handphoto: 'Пользователь держит фото на удостоверение личности',
      qq: 'QQ',
      ploy: 'Существует ли соответствующая стратегия контроля рисков',
      agreement: '"Соглашение с сертифицированным продавцом"',
      he: 'и',
      applyfor: 'Подтвердить заявку',
      sendcode: 'Отправьте проверочный код',
      confirm: 'Конечно',
      prepare: 'подготовить материалы',
      review: 'Добавить отзыв',
      result: 'Жду результатов',
      passed: 'экзамен сдан',
      approve: 'Пожалуйста, примите соглашение продавца аутентификации',
      emailtip1: 'Отправьте следующие материалы по электронной почте на адрес',
      emailtip2: 'Мы рассмотрим вашу заявку в кратчайшие сроки. ',
    },
    extension: {
      title1: 'Рекламные ссылки',
      title2: 'Продвигайте друзей',
      title3: 'Моя комиссия',
      linkdesc:
        'Следующий URL - ваш адрес для продвижения во внешний мир. Вы можете продвигать его через друзей, QQ, WeChat, Weibo, блоги, форумы или свой собственный веб-сайт. Любой, кто заходит по этому адресу, будет принадлежать вам после регистрации. Пользователи , и когда эти пользователи отправляют стратегии на этом сайте, вы можете зарабатывать комиссионные.Подробный статус продвижения можно просмотреть в записях посещений. ',
      linktitle: 'Ваша рекламная ссылка',
      copy: 'копировать',
      copy_msg1: 'Копировать успешно! ',
      copy_msg2: 'Ошибка копирования! Скопируйте вручную ',
      username: 'имя пользователя',
      currrecharge: 'Текущая транзакция',
      userlevel: 'Рекомендуемый уровень',
      createdtime: 'Время регистрации',
      currcommission: 'Текущая комиссия',
      managerfee: 'Плата за управление',
      yuan: 'Юань',
      symbol: 'Валюта',
      amount: 'Количество',
      remark: 'Замечание',
      amounttime: 'Время выдачи',
    },
    promotion: {
      title: 'Мое продвижение',
      subtitle1: 'Моя рекламная карта',
      subtitle2: 'Обменная запись',
      subtitle3: 'Мое приглашение',
      card_column0: 'Код погашения',
      card_column1: 'Название карты',
      card_column2: 'Тип валюты карты',
      card_column3: 'Сумма карты',
      card_column4: 'Срок годности',
      card_column5: 'Общий',
      card_column6: 'Уже погашено',
      card_column7: 'Время поступления',
      my_column0: 'Пригласить пользователя',
      my_column1: 'Время регистрации',
      my_column2: 'Статус настоящего имени',
      gopromotion: 'Рекламные материалы',
      inputcardno: 'Пожалуйста, введите код погашения',
      exchange: 'Обменять сейчас',
      exchangesuccess:
        'Обмен прошел успешно! Зайдите в управление активами, чтобы проверить баланс!',
      exchangewithcode: 'Погашение кода погашения',
    },
    activity: {
      navTitle: 'Инновационный менеджмент',
      subNavTitle1: 'Я участвовал',
      subNavTitle2: 'Моя горная машина',
      subNavTitle3: 'Моя заблокированная позиция',
      column1: 'Имя',
      column2: 'тип',
      column3: 'Количество подписок',
      column4: 'Валюта участия',
      column5: 'Подписка',
      column6: 'Текущий статус',
      column7: 'Сумма транзакции',
      column8: 'Время создания',
      mColumn1: 'Горная машина',
      mColumn2: 'Имя',
      mColumn3: 'Валюта',
      mColumn4: 'Период',
      mColumn5: 'Дни',
      mColumn6: 'Копал',
      mColumn7: 'штат',
      mColumn8: 'Базовая емкость',
      mColumn9: 'Текущая вместимость',
      mColumn10: 'Общий выход',
      mColumn11: 'Дата окончания',
      mColumn12: 'Дата создания',
      lColumn1: 'Заблокировать позицию',
      lColumn2: 'Имя',
      lColumn3: 'Валюта',
      lColumn4: 'Период',
      lColumn5: 'Дни',
      lColumn6: 'Выпущенный',
      lColumn7: 'штат',
      lColumn8: 'Базовая емкость',
      lColumn9: 'Текущая вместимость',
      lColumn10: 'Общий выход',
      lColumn11: 'Дата окончания',
      lColumn12: 'Дата создания',
    },
    mining: {
      title: 'Мой список майнинговых машин',
      empty: 'Временно нет майнеров',
      day: 'небо',
      week: 'неделя',
      month: 'Луна',
      year: 'год',
      invitetip1:
        'Производительность этой майнинг-машины можно увеличить, пригласив друзей. Каждый раз, когда кого-то приглашают обменять на майнинговую машину, производственная мощность будет увеличиваться',
      invitetip2: '%, верхний предел увеличения емкости',
      miningUnit: 'Валюта вывода',
      miningPeriod: 'Производственный цикл',
      miningDays: 'Горное время',
      miningedDays: 'Горное время',
      miningDaysProfit: 'Базовая емкость',
      miningCurrentDaysProfit: 'Фактическая емкость',
      status0: 'Будет развернуто',
      status1: 'Развернуто',
      status2: 'над',
    },
    locked: {
      title: 'Мой список заблокированных позиций',
      empty: 'Без блокировки',
      day: 'небо',
      week: 'неделя',
      month: 'Луна',
      year: 'год',
      invitetip1:
        'Вместимость этой камеры может быть увеличена путем приглашения друзей, и каждый человек, приглашенный для участия в ней, увеличит вместимость',
      invitetip2: '%, верхний предел увеличения емкости',
      miningUnit: 'Валюта выпуска',
      miningPeriod: 'Цикл выпуска',
      miningDays: 'Общий период выпуска',
      miningedDays: 'Срок действия',
      miningDaysProfit: 'Базовый выпуск',
      miningCurrentDaysProfit: 'Актуальный выпуск',
      status0: 'Будет выпущен',
      status1: 'Выпускать',
      status2: 'над',
      totallocked: 'Полная блокировка',
    },
    api: {
      apiAdmin: 'Управление API',
      createApi: 'Создать новый ключ API',
      mark: 'Примечания к API',
      bindAddress: 'Авторизованный IP (Срок действия ключа авторизованного IP-адреса 90 дней)',
      hint: 'намекать',
      part1:
        'Мы предоставляем вам мощный API, с помощью которого вы можете использовать такие услуги, как исследование рынка и автоматическая торговля. Пройдите',
      apiDoc: 'Документация по API',
      howUse: 'Посмотрите, как это использовать.',
      part2: 'Каждый пользователь может создать до 5 ключей API',
      part3:
        'Пожалуйста, не раскрывайте свой ключ API, чтобы избежать потери активов. В целях безопасности рекомендуется привязать IP-адрес к ключу API. Каждый ключ API привязан максимум к 5 IP-адресам. Введите один адрес напрямую, и используйте половинную ширину для нескольких IP-адресов. Разделите их запятыми, например: 192.168.1.1,192.168.1.2,192.168.1.3. ',
      myApiKey: 'Мой ключ API',
      ipAddress: 'айпи адрес',
      createTime: 'Время создания',
      accessKey: 'Ключ доступа',
      bindIpAddress: 'Привязать IP-адрес',
      ioDays: 'Оставшийся срок действия (дни)',
      operation: 'работать',
      create: 'Создавать',
      edit: 'редактировать',
      delete: 'удалять',
      sureDelete: 'Подтвердите удаление',
      tips: 'Ключ отображается только при добавлении, пожалуйста, сохраните его вовремя',
      copy: 'копия',
      access: 'Ключ',
      okText: 'подтверждать',
      cancel: 'Отмена',
    },
  },
  cms: {
    noticecenter: 'Центр объявлений',
    newshelp: 'Новые руки помогают',
    appdownload: 'APP download',
    onlineservice: 'Интернет Сервис',
    faq: 'Общая проблема',
    notice: 'Объявление',
    servicecenter: 'Обслуживание клиентов',
    about: 'о',
    joinus: 'Присоединяйтесь к нам',
    aboutus: 'о нас',
    exchangerule: 'Правила торговли',
    useprotocol: 'Соглашение об использовании',
    feenote: 'Описание начисления',
    merchantprocotol: 'Торговое соглашение',
    features: 'Возможности платформы',
    contactus: 'связаться с нами',
    noticelist: 'Список объявлений',
    nextnotice: 'Следующий',
    prevnotice: 'Предыдущий',
    notexist: 'Посещенное вами объявление не существует или было удалено!',
    otherhelp: 'Статьи в этой категории',
    scanforshare: 'Сканировать, чтобы поделиться',
    download: 'скачать сейчас',
    downloadslogan: 'EPBTCAPP - Глобальная платформа для торговли цифровыми активами',
  },
  description: {
    // message1: 'Технологии безопасности на уровне банка, такие как SSL и динамическая проверка личности, \ nобеспечение безопасности транзакций; поддержка транзакций в нескольких цифровых валютах',
    // message2: 'Строгие барьеры входа в проект для обеспечения интересов пользователей платформы; \ n100% депозит, кошельки изолированы от горячих и холодных, чтобы обеспечить безопасность средств пользователей',
    // message3: 'Платформа для торговли цифровыми активами EPBTC поддерживает 1000 транзакций в секунду, обеспечивая пользователям приятный опыт торговли \ n',
    title1: 'Безопасность финансового уровня',
    title2: '极速 充 提',
    title3: 'глобальный сервис',
    title4: 'Строго отобранные активы',
    message1:
      'Комплексная система контроля финансовых рисков и противоугонная система, горячий и холодный кошелек, система мультиподписи для обеспечения сохранности средств',
    message2:
      'Внесение и снятие средств может быть выполнено всего за 3 минуты, круглосуточная ручная онлайн-проверка, чтобы защитить клиентов от упущения лучшей инвестиционной возможности',
    message3:
      'Глобальная сеть бизнес-услуг, которая поможет вам инвестировать в глобальные криптоактивы и торговать с глобальными пользователями',
    message4:
      'Строго выбирайте высококачественные проекты шифрования и отфильтровывайте для вас 80% проектов с чрезвычайно высоким риском',
    message5:
      'EPBTC (WWW.EPBITCOIN.COM) мобильный клиент международной платформы для торговли цифровыми зашифрованными активами, ориентированный на опыт ручной торговли',
    scanqrcode: 'Отсканируйте QR-код и загрузите ПРИЛОЖЕНИЕ',
    aboutdesc1:
      'EPBTC (WWW.EPBITCOIN.COM) - ведущая в мире платформа для торговли цифровыми активами. Она зарегистрирована на Каймановых островах, а ее основная операционная группа находится в Гонконге. Основные члены EPBTC (WWW.EPBITCOIN.COM) приходят от ведущих интернет-компаний и финансовых компаний. Большинство участников глубоко верят в биткойн и блокчейн. Мы убеждены, что блокчейн изменит традиционную монополистическую финансовую систему и установит более демократическую и автономную социальную структуру. ',
    aboutdesc2:
      'Платформа EPBTC (WWW.EPBITCOIN.COM) имеет профессиональную структуру транзакций на финансовом уровне и саморазработанный механизм транзакций для согласования памяти с высоким уровнем параллелизма. Платформа принимает такую ​​структуру транзакций, как система кошелька с полностью холодным пополнением баланса + мульти -подпись + высокозащищенная система DDOS-атак. Обеспечение сохранности активов клиента. ',
    aboutdesc3:
      'EPBTC (WWW.EPBITCOIN.COM) Для того, чтобы позволить клиентам лучше понять инвестиционные возможности, отдел обслуживания клиентов применяет единое внутреннее управление и устанавливает механизм чрезвычайно быстрого реагирования на обслуживание. Менеджеры по обслуживанию клиентов активов доступны онлайн 7 * 24 часа в течение года для предоставления клиентам депозита и снятия активов. И других услуг, гарантирующих, что клиенты завершат внесение и снятие активов в течение 25 минут. ',
    aboutdesc4:
      'EPBTC (WWW.EPBITCOIN.COM) строго проверяет высококачественные проекты и предоставляет безопасные и стабильные услуги по хранению активов. EPBTC (WWW.EPBITCOIN.COM) придерживается концепции" честности, справедливости, энтузиазма и открытости "и стремится к обеспечить безопасность для пользователей, надежный, эффективный и удобный обмен. ',
    aboutdesc5:
      'Хотя цифровые активы сейчас находятся только в небольшом кругу, мы считаем, что такие активы, которыми каждый может полностью управлять, будут популярны в будущем. Давайте усердно работать и с нетерпением ждем этого!',
    aboutdesc6:
      'Компания ntlex.com (Nano Token Liquidity) является международной криптовалютной платформой, основанной на децентрализованной автономной организации (DAO), которая предоставляет пользователям по всему миру безопасные, стабильные, удобные, справедливые и надежные услуги по торговле криптовалютными активами.',
    aboutdesc7:
      'NTL DAO управляется фондом на Каймановых островах, что обеспечивает более высокий уровень защиты пользовательских активов и справедливость торговли, чем у традиционных криптовалютных бирж. Команда управления состоит из профессионалов из разных стран мира, которые имеют значительный опыт в управлении финансовыми рисками и операциями, включая ранее работавших в таких мировых компаниях, как Coinbase, Binance, Morgan Stanley, Amazon и Google.',
    aboutdesc8:
      'ntlex.com предоставляет полный набор услуг по торговле криптовалютными активами, включая обмен монет, контракты на разницу цен, физические товары, опционы, NFT и финансовые услуги по займам и депозитам. Кроме того, платформа доступна на нескольких языках, включая китайский, английский, японский и корейский. Технические решения основаны на технологиях Amazon AWS и Google Cloud, которые обеспечивают высокий уровень безопасности, в том числе защиту от DDOS-атак, динамическое и статическое разделение данных, кэширование горячих данных, восстановление после аварий и параллельное масштабирование. Также платформа обладает функциями мгновенного доступа к криптовалютным активам, технологией совместного использования адресов разных валют и множественной аутентификации для защиты пользовательских активов. При этом ntlex.com предлагает более низкие комиссии по торговле и снятию средств, чем многие другие криптовалютные биржи.',
    aboutdesc9:
      'В качестве DAO-платформы, которой можно доверять, ntlex.com стремится обеспечить полную безопасность информации и активов пользователей, чтобы предоставить превос.',
    support: 'Техподдержка',
    report: 'Предложения',
    service: 'обслуживание клиентов',
    apply: 'Подать заявку на листинг',
    coop: 'Деловое сотрудничество',
    community: 'Сообщество EPBTC',
    wechat: 'WeChat',
    addwechat:
      "Добавьте' bmate601 'в качестве друга WeChat и присоединитесь к социальному сообществу WeChat",
    weibo: 'Weibo',
    twitter: 'Твиттер',
    biyong: 'Валюта',
    telegram: 'Telegram',
    medium: 'Середина',
    reddit: 'Reddit',
  },
  bzb_description: {
    title1: 'Дивидендные права',
    title2: 'Скидка на комиссию за обработку',
    title3: 'Приоритетная подписка',
    title4: 'Право голоса',
    title5: 'Первое право знать',
    title6: 'Приоритетный листинг',
    title7: 'Обсуждаем и принимаем решения',
    title8: 'Более',
    desc1:
      'Обработка комиссионных дивидендов, дивидендов по доходу, дивидендов по листингу валюты, дивидендов по капиталу и т. Д.',
    desc2:
      'Комиссия за транзакцию составляет скидку до 80%, а вознаграждение возвращается. Подробности уточняются',
    desc3: 'Права на приоритетную подписку на токены первого IEO и продвижения загрузки',
    desc4:
      'Участвуйте в голосовании сообщества EPBTC, включая, помимо прочего, листинг, обратную покупку и т. Д.',
    desc5:
      'Определенная доля открытого интереса войдет в группу SVIP, и контакт нулевого расстояния с большой V',
    desc6: 'Только суперузлы, имеющие право рекомендовать листинг токенов',
    desc7: 'Только суперузлы имеют право обсуждать важные решения',
    desc8: 'Больше прав, следите за обновлениями',
  },
  footer: {
    wechatkefu: 'Служба поддержки WeChat',
    notice: 'Объявление платформы',
    changjian: 'Общая проблема',
    shiyong: 'Соглашение об использовании',
    feilv: 'Оценить описание',
    lianxi: 'Почта для связи',
    biyong: 'Использование монет',
    gsmc: 'Глобальный обмен высококачественными цифровыми активами',
    bah: 'Шанхай ICP № 13026899 -3',
    gsjj: 'о',
    gywm: 'о нас',
    jrwm: 'Присоединяйтесь к нам',
    lxwm: 'связаться с нами',
    yqlj: 'Ссылки',
    bzzx: 'Центр помощи',
    xsrm: 'Учебное пособие для новичков',
    czzn: 'Руководство по пополнению баланса',
    ptsm: 'Описание платформы',
    fysm: 'Правила торговли',
    fwtk: 'Пользовательское Соглашение',
    fltk: 'Описание начисления',
    wechat: 'Служба поддержки WeChat',
    xszn: 'Руководство для начинающих',
    cjwt: 'Общая проблема',
    mztk: 'Заявление об ограничении ответственности',
    ystk: 'Политика конфиденциальности',
    tkxy: 'Условия соглашения',
    gybzb: 'О БЗБ',
    kfyx: 'Электронная почта службы поддержки',
    swhz: 'Деловое сотрудничество',
    sbsq: 'Подать заявку на листинг',
    jyzn: 'Торговый гид',
    bzzl: 'Информация о валюте',
    tsjb: 'Жалобы',
    apidoc: 'Интерфейс API',
    zwkf: 'Еще не открыто',
  },
  financeNav: {
    wdzc: 'Мои активы',
  },
  index: {
    circulation: 'Объем обращения BHB Security and Development Fund',
    hourEth: 'Сегодняшний общий доход, подлежащий распределению, конвертируется',
    yesterdayEth: 'Вчерашнее кумулятивное распределение преобразованного дохода',
    yesterday: 'Вчерашний объем добычи',
    bhbTotal: 'Общий тираж BHB',
    bhbSecond: 'Обращение вторичного рынка BHB',
    checkMineprinciple: 'Посмотреть принцип майнинга',
    checkFlowVolum: 'Проверить описание ликвидности',
    checkLockPosition: 'Посмотреть положение блокировки',
    BHBregister: 'Регистрация учетной записи EPBTC',
    tibi: 'Время и лимит вывода и прибытия',
    line_plane: 'Объявление плана запуска BHB, возврат комиссии за обращение и обслуживание',
    fenpeijizhi: 'Разъяснение механизма распределения доходов EPBTC',
    jiangli_jihua: 'Пригласительная программа вознаграждения майнинга',
    friend_fanhuan: 'Пригласите друзей и получите возмещение дополнительной платы за обработку',
  },
  plate: {
    title: 'План распределения доходов платформы (собственный капитал владельцев BHB)',
    content1:
      'Как описано в официальном документе, EPBTC будет распределять 80% дохода платформы (после уплаты налогов и сборов) держателям EPBTC, а 20% дохода будет использоваться для поддержки разработки и эксплуатации платформы',
    content2:
      'День распределения доходов представляет собой цикл распределения, а 6 июня 2018 года является первым днем ​​распределения. Каждый день после этого весь доход, который будет распределен с предыдущего дня, будет распределяться между держателями BHB единовременно. пропорция. ',
    content3:
      '(Примечание: 1. BHB здесь относится только к выпущенным / распространенным BHB, см. [',
    content3_1: 'Объяснение доли обращения / участия BHB в распределении доходов',
    content3_2:
      '2. Снимки делаются каждый час (по часам) и рассчитываются один раз, а распределение доходов выполняется один раз в день).',
    hourFenpei: 'Сегодняшний эквивалент дохода от распределения',
    hourTotal: 'Эквивалент общей выручки от сегодняшней платформы',
    xiangqing: 'Сегодняшнее распределение доходов',
    yesterdaytit: 'Эквивалент вчерашнего ежедневного дохода от распределения',
    yesterdaytotal: 'Эквивалент вчерашнего общего дохода от платформы',
    yesterdayxiangqing: 'Сведения о вчерашнем распределении доходов',
    /*yesterday: {
          total: "Общая плата за платформу",
          allocated: "Распределенный доход",
        }*/
  },
  feereturn: {
    ruletitle: 'Правила возврата',
    rulecontent:
      'Коэффициент распределения BHB был подробно объяснен в официальном документе. 51% BHB постепенно вознаграждается торговым пользователям с помощью метода" транзакция - это добыча ". Как только 51% BHB полностью вознагражден," майнинг "автоматическое прекращение.',
    recordtitle: 'Вернуть записи',
    recordcontent:
      'Каждый день (UTC + 8, то же самое ниже) 100% комиссий за транзакции, сгенерированные пользователями за предыдущий день, будут конвертированы в BHB и возвращены пользователям. Конвертированная цена основана на средней цене BHB на предыдущей торговле. день (средняя цена рассчитывается как общая сумма транзакции / общий объем транзакции). Мы начнем возмещение комиссии за транзакцию предыдущего дня в эквиваленте BHB каждый день в 11:00.',
  },
};
