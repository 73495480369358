export default {
  common: {
    area: '/uc/support/country',
  },
  host: process.env.VUE_APP_BASE_URL,
  uc: {
    //会员中心接口
    login: '/uc/login',
    register: '/uc/register',
    wallet: '/uc/asset/wallet/', //币币钱包列表  ui现货账户
    walletlist: '/swap/wallet/list', //永续U合约钱包列表  ui U本币账户
    coinswaplist: '/coinswap/wallet/list', //永续合约钱包列表 ui币本币账户
    secondlist: '/second/wallet/list', //4.秒合约钱包列表    ui秒合约账户
    trans: '/uc/asset/trans', //新划转接口 23/9/13
    captcha: '/uc/start/captcha',
    identification: '/uc/approve/certified/business/status', //商家认证
    apply: '/uc/approve/certified/business/apply', //商家认证申请
    announcement: '/uc/announcement/page', //公告列表
    paydividends: '/uc/bonus/user/page', //持币分红
    mylistrecord: '/uc/mine/detail/', //交易挖矿详情
    activitylist: '/uc/activity/page-query', // 活动列表
    memberactivity: '/uc/activity/getmemberrecords', // 用户参与活动列表
    attendActivity: '/uc/activity/attend', // 参与活动
    mypromotion: '/uc/promotion/mypromotion', // 我的推广佣金
    toppromotion: '/uc/promotion/toprank', // 推广合伙人排行
    getfreecard: '/uc/promotion/promotioncard/getfreecard', // 获取免费推广卡
    exchangecard: '/uc/promotion/promotioncard/exchangecard', // 兑换推广卡
    mycardlist: '/uc/promotion/promotioncard/mycard', // 兑换推广卡
    toppromotionmonth: '/uc/promotion/monthtoprank', // 推广合伙人排行
    toppromotionweek: '/uc/promotion/weektoprank', // 推广合伙人排行
    // memberInfo: "/uc/member/my-info", // 获取用户信息(未用到此变量)
    myfirstlevels: '/uc/invite/query-first', //获取推广邀请人记录
    mysecondlevels: '/uc/invite/query-second', //获取二级推广邀请人记录
    mypromotionrecord: '/uc/promotion/record', //获取推广邀请人记录
    myInnovationOrderList: '/uc/activity/getmyorders', // 创新实验区参与订单列表
    myInnovationMinings: '/uc/miningorder/my-minings', // 获取我的矿机列表
    myInnovationLocked: '/uc/lockedorder/my-locked', // 获取我的矿机列表

    inviteList: '/uc/invite/list', // 邀请好友列表
    awardCoin: '/uc/award/coin/page', // 奖励中心 数字货币
    awardBackcard: '/uc/award/backcard/page', // 奖励中心反佣卡列表
    activityRecharge: '/uc/award/activity/recharge/info', // 查询充值活动信息(倒计时)

    helpVideo: '/uc/help/video', // 通过code获取新手视频
  },
  market: {
    //币币交易行情接口
    ws: '/market/market-ws',
    thumb: '/market/symbol-thumb', // 所有币币列表(可交易的币)
    thumbTrend: '/market/symbol-thumb-trend',
    plate: '/market/exchange-plate', //主动查询的盘口信息
    platemini: '/market/exchange-plate-mini', //获取10条数据
    platefull: '/market/exchange-plate-full', //获取所有数据
    trade: '/market/latest-trade', //主动查询的实时成交信息
    symbolInfo: '/market/symbol-info', // 交易对信息
    coinInfo: '/market/coin-info', // 币种信息
    indexData: '/market/index_info',
    symbolIntro: '/market/symbol-intro', // 币种(交易对)概括
    top_coin: '/market/top-coin', //主流币
    recommend: '/market/recommend', //热门/推荐币
    rank: '/market/rank', //涨跌幅榜
    newCoin: '/market/new-coin', //新币榜
    rankTurnover: '/market/rank-turnover', //成交量榜
    symbolUsdtThumb: '/market/symbol-usdt-thumb', //币种行情列表
    coincate: '/market/coincate', //币种分类列表
    coinlist: '/market/coinlist', //币种略缩接口(包含图标)
  },

  /*
      永续合约U本位
      /swap/order/current  -> /swap/order/v2/current 原接口不变 新增V2返回标准对象 ✅
      /swap/order/personal/current  接口返回修改 返回标准对象 ✅

      /swap/order/history  -> /swap/order/v2/history 原接口不变 新增V2 返回标准对象 ✅
      /swap/order/personal/history 接口返回修改 返回标准对象 ✅

      /swap/trade/history -> /swap/trade/v2/history 原接口不变 新增V2 ✅
      /swap/trade/personal/history 接口返回修改 返回标准对象 ✅

      永续合约币本位
      /coinswap/order/current  -> /coinswap/order/v2/current 原接口不变 新增V2 返回标准对象 ✅
      /coinswap/order/personal/current 接口返回修改 返回标准对象 ✅

      /coinswap/order/history -> /coinswap/order/v2/history 原接口不变 新增V2返回标准对象 ✅
      /coinswap/order/personal/history 接口返回修改 返回标准对象 ✅

      /coinswap/trade/history -> /coinswap/trade/v2/history 原接口不变 新增V2返回标准对象 ✅
      /coinswap/trade/personal/history 接口返回修改 返回标准对象 ✅

      期权合约
      /option/order/personal/current 接口返回修改 返回标准对象
      /option/order/personal/history 接口返回修改 返回标准对象

      秒合约
      /second/order/personal/current 接口返回修改 返回标准对象

      /second/order/history -> /second/order/v2/history 原接口不变 新增V2返回标准对象
      /second/order/personal/history 接口返回修改 返回标准对象

       这些接口做了调整。
      1，上个版本有的在使用的接口，为了不影响原来的版本，新增了v2版本，把返回结果改成标准返回对象。新老接口都有效。
      2，这个版本新增的接口，直接修改了返回结果，如果已经开发了的麻烦修改一下。
   */
  swap: {
    ws: '/swap/swap-ws',
    thumb: '/swap/symbol-thumb',
    plate: '/swap/exchange-plate', //主动查询的盘口信息
    platemini: '/swap/exchange-plate-mini', //获取10条数据
    platefull: '/swap/exchange-plate-full', //获取所有数据
    trade: '/swap/latest-trade', //主动查询的实时成交信息
    symbolInfo: '/swap/symbol-info', // 交易对信息
    coinInfo: '/swap/coin/coin-info', // 合约交易对信息
    contractWallet: '/swap/wallet/detail', // 用户合约钱包账户信息
    modifyLeverage: '/swap/order/modify-leverage', // 修改杠杆
    canSwitchPattern: '/swap/order/can-switch-pattern', // 是否能修改仓位模式
    switchPattern: '/swap/order/switch-pattern', // 修改仓位模式
    open: '/swap/order/open', // 开仓
    close: '/swap/order/close', // 平仓
    cancel: '/swap/order/cancel', // 撤销
    // getEntrustList: '/swap/order/current', // 当前委托表格数据
    getEntrustList: '/swap/order/personal/current', // 当前委托表格数据
    // getHistoryEntrustList: '/swap/order/history', // 历史委托表格数据
    getHistoryEntrustList: '/swap/order/personal/history', // 历史委托表格数据
    // getHistoryTradeList: '/swap/trade/history', // 成交明细表格数据
    getHistoryTradeList: '/swap/trade/personal/history', // 成交明细表格数据
  },

  coinswap: {
    ws: '/coinswap/swap-ws',
    thumb: '/coinswap/symbol-thumb',
    plate: '/coinswap/exchange-plate', //主动查询的盘口信息
    platemini: '/coinswap/exchange-plate-mini', //获取10条数据
    platefull: '/coinswap/exchange-plate-full', //获取所有数据
    trade: '/coinswap/latest-trade', //主动查询的实时成交信息
    symbolInfo: '/coinswap/symbol-info', // 交易对信息
    coinInfo: '/coinswap/coin/coin-info', // 合约交易对信息
    contractWallet: '/coinswap/wallet/detail', // 用户合约钱包账户信息
    modifyLeverage: '/coinswap/order/modify-leverage', // 修改杠杆
    canSwitchPattern: '/coinswap/order/can-switch-pattern', // 是否能修改仓位模式
    switchPattern: '/coinswap/order/switch-pattern', // 修改仓位模式
    open: '/coinswap/order/open', // 开仓
    close: '/coinswap/order/close', // 平仓
    cancel: '/coinswap/order/cancel', // 撤销
    // getEntrustList: '/coinswap/order/current', // 当前委托表格数据
    getEntrustList: '/coinswap/order/personal/current', // 当前委托表格数据
    // getHistoryEntrustList: '/coinswap/order/history', // 历史委托表格数据
    getHistoryEntrustList: '/coinswap/order/personal/history', // 历史委托表格数据
    // getHistoryTradeList: '/coinswap/trade/history' // 成交明细表格数据
    getHistoryTradeList: '/coinswap/trade/personal/history', // 成交明细表格数据
  },

  second: {
    ws: '/second/second-ws',
    thumb: '/second/symbol-thumb',
    plate: '/second/exchange-plate', //主动查询的盘口信息
    platemini: '/second/exchange-plate-mini', //获取10条数据
    platefull: '/second/exchange-plate-full', //获取所有数据
    trade: '/second/latest-trade', //主动查询的实时成交信息
    symbolInfo: '/second/symbol-info', // 交易对信息(内有交易费率)
    coinInfo: '/second/coin/coin-info', // 合约交易对信息
  },
  option: {
    ws: '/option/option-ws',
    thumb: '/option/symbol-thumb',
    thumbone: '/option/symbol-thumb-one',
  },
  exchange: {
    //币币交易委托提交与查询接口
    orderAdd: '/exchange/order/add', //提交订单接口
    current: '/exchange/order/current', //当前委托接口
    history: '/exchange/order/history', //历史委托接口
    detail: '/exchange/order/detail/', //详细订单接口
    favorFind: '/exchange/favor/find', //查询自选
    favorAdd: '/exchange/favor/add', //添加自选
    favorDelete: '/exchange/favor/delete', //删除自选
    orderCancel: '/exchange/order/cancel', //取消委托
  },
  otc: {
    coin: '/otc/coin/all', //查询支持的币种
    advertise: '/otc/advertise/page-by-unit', //获取广告
    createAd: '/uc/ad/create', //创建广告
    adDetail: '/otc/advertise/detail',
  },
  activity: {
    activity: '/activity/page-query',
  },
};
